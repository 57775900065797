export const Academico = {
  // Letra A:
  ACADEMICO: 'Acadêmico',
  ADICIONAR_DOCENTE: 'Adicionar docente',
  ANO_LETIVO: 'Ano letivo',
  AULA: 'Aula',
  AVALIACAO: 'Avaliação',
  AVISO_NAO_PODE_DESFAZER_ACAO: 'ATENÇÃO: esta ação não poderá ser desfeita!',
  AGENCIA_CONTA: 'Agência da Conta',
  AGENCIA_CONTA_PLACE: 'Digite o número da agência da conta',
  ABRIR_SEGMENTACAO: 'Abrir segmentação',
  ADITIVO_DE_CONTRATO: 'Aditivo de contrato',
  ALTERACOES: 'Alterações',
  ADITIVOS_DE_CONTRATO: 'Aditivos de contrato',
  ADICIONAR_DESCONTO: 'Adicionar desconto(s)',
  ADITIVO_DE_CONTRATO_DESCRICAO:
    'Nessa tela você pode visualizar todos os contratos que possuem aditivos e adicionar aditivos para uma turma',
  ALUNO: 'Aluno',
  ADICIONAR_PRECO: 'Adicionar preço(s)',
  ATIVAR_CONTRATO: 'Ativar contrato',
  ATIVAR_CONTRATO_SUCESSO: 'Contrato ativado com sucesso',

  // Letra B:
  BAIXAR_CONTRATO: 'Baixar contrato',
  DOWNLOAD_CONTRATO: 'Download contrato',
  BOLETIM: 'Boletins',
  BOLETIM_DESCRICAO:
    'Esta tela oferece a funcionalidade de filtrar e listar alunos, permitindo o processamento do boletim individual de cada estudante. Utilize o filtro para buscar.',
  BUSCAR_NOME_CODIGO_MATRICULA: 'Busque por nome ou código do aluno:',
  BANCOS: 'Banco',
  BAIRRO: 'Bairro',

  // Letra C:
  NOVO_CONTRATO: 'Novo contrato',
  CANCELAR_CONTRATO: 'Cancelar',
  CARGA_HORARIA: 'Carga horária',
  CADASTRAR_PERIODO: 'Cadastrar período letivo',
  COMPETENCIA: 'Competência',
  CONFIRMAR_ALTERACAO_STATUS:
    'Você confirma a alteração do status do contrato para {status}?',
  CONFIRMAR_EXCLUSAO_DE: 'Confirme a exclusão de {item}',
  CONFIRMAR_EXCLUIR_AULA: 'Tem certeza que quer excluir a aula?',
  CONFIRMAR_EXCLUIR_AVALIACAO: 'Tem certeza que quer excluir a avaliação?',
  CONFIRMAR_CANCELAMENTO_CONTRATO:
    'Tem certeza que deseja cancelar o contrato?',
  CONFIRMAR_MOTIVO_CANCELAMENTO:
    'Deseja criar motivo de cancelamento para contrato ?',
  CONFIRMAR_EXCLUIR_DISCIPLINA: 'Tem certeza que quer excluir a disciplina?',
  CONFIRMAR_ATIVAR_CONTRATO: 'Tem certeza que deseja ativar esse contrato?',
  CONTEUDO_PROGRAMATICO: 'Conteúdo programático',
  CONTRATO: 'Contrato',
  CONTRATO_CADASTRADO: 'Contrato salvo',
  CONTRATO_ADICIONE_PRECO: 'Adicione pelo menos um preço ao contrato',
  CONTRATO_MENSAGEM_EXCLUIDO: 'Contrato excluído com sucesso',
  CONTRATOS: 'Contratos',
  CONTRATOS_DESCRICAO:
    'Clique em buscar para selecionar um aluno e visualizar respectivos contratos e/ou cadastrar:',
  COMPLEMENTO: 'Complemento',
  CURSO: 'Curso',
  CURSO_CONFIRMAR_EXCLUSAO_TITULO: 'Confirme a exclusão do curso {curso}',
  CURSOS: 'Cursos',
  CURSOS_CEJ_DESCRICAO:
    'Esta é a página de cursos do CEJ. Você pode cadastrar utilizando o botão no topo a direita ou, editar/excluir clicando em "ações" na linha desejada.',
  CURSOS_CADASTRAR: 'Cadastrar curso',
  CARGOS: 'Cargo',
  CARTEIRA_PROFISSIONAL: 'Carteira profissional',
  CARTEIRA_PROFISSIONAL_PLACE: 'Digite o número da carteira profissional',
  CONTA_BANCARIA: 'Conta bancária',
  CONTA_BANCARIA_PLACE: 'Digite o número da conta bancária',
  CONTRATOS_COM_ADITIVO: 'Contratos com aditivo(s)',
  CODIGO: 'Código',
  CONTRATOS_TURMA: 'Contratos por turma',
  CONTRATO_ATIVO: 'Status alterado com sucesso',
  CAMPOS_PARA_INCLUIR_NO_RELATORIO: 'Campos para incluir no relatório',
  CELULAR: 'Telefone celular',
  CEP: 'CEP',
  CIDADE: 'Cidade',
  CPF: 'CPF',
  CONFIG_RELATORIO: 'Opções do relatório',
  CONTRATO_DESCRICAO_VISUALIZAR:
    'Nesta tela, você pode visualizar as informações do contrato, incluindo as parcelas geradas a partir dele.',
  CONTRATO_DESCRICAO_CRIAR_EDITAR:
    'Nesta tela, você pode criar ou editar contratos com base nas informações inseridas no formulário, incluindo preços e descontos. Após o preenchimento, você terá acesso ao plano de parcelamento. Preste especial atenção ao campo "Situação do contrato", onde você pode escolher se deseja ativar o contrato imediatamente após a criação.',
  CADASTRAR_TURMA: 'Cadastrar nova turma',
  CURSO_DESCRICAO:
    'Nessa tela você pode visualizar, editar e excluir todas as turmas do curso selecionado.',
  CURRICULO_ESCOLAR: 'Currículo escolar',
  // Letra D:
  DATA: 'Data',
  DATA_AVALIACAO: 'Data avaliação',
  DATA_RECUPERACAO: 'Data recuperação',
  DATA_VENCIMENTO: 'Data de vencimento',
  DATA_LANCAMENTO: 'Data de lançamento',
  DATA_DE_GERACAO: 'Data de geração',
  DATAS_INVALIDAS: 'O período fim não pode ser superior ao período início',
  DATA_GERACAO_INICIO: 'Data de geração início',
  DATA_GERACAO_FINAL: 'Data de geração final',
  DATA_INATIVACAO_INICIO: 'Data de inativação início',
  DATA_INATIVACAO_FINAL: 'Data de inativação final',
  DISCIPLINA: 'Disciplina',
  DISCIPLINA_CURRICULAR: 'Disciplina curricular',
  DISCIPLINA_CADASTRAR: 'Cadastrar disciplina',
  DISCIPLINA_EXCLUIDA: 'Disciplina excluída!',
  DISCIPLINAS: 'Disciplinas',
  DISCIPLINAS_DESCRICAO:
    'Nessa tela é possível cadastrar, editar e excluir disciplinas, para isso clique em <strong>Cadastrar</strong> ou no botão de <strong>ações</strong> na tabela.',
  DISCIPLINAS_CEJ_DESCRICAO:
    'Esta é a página de disciplinas do CEJ. Clique no botão abaixo caso queira utilizar o filtro:',
  DOCENTES: 'Docentes',
  DOCENTES_PERIODO: 'Docentes do período',
  DOCENTES_DO_PERIODO_DESCRICAO:
    'Esta tela permite vincular um professor a uma turma específica durante um período determinado.',
  DADOS_GERAIS_DO_ADITIVO: 'Dados gerais',
  DESCONTOS: 'Descontos',
  DESCRICAO_FORM_PRECOS:
    'Aqui você deve incluir os preços do aditivo, selecionar a forma de pagamento para cada um e visualizar como ficaram as parcelas',
  DETALHES_DO_MOTIVO: 'Detalhes do motivo',
  DESCRICAO_FORM_DESCONTOS:
    'Aqui você pode adicionar os descontos para esse aditivo',
  DESCRICAO_FORMULARIO_CONTRATOS_TURMA_ADITIVO:
    'Preencha o filtro para trazer os contratos da turma, depois selecione os contratos que deseja adicionar o aditivo',
  DOCUMENTO_PADRAO: 'Documento padrão',
  DOCUMENTOS_PADRAO_DESCRICAO:
    'Nessa tela você pode gerenciar documentos padrão do sistema, também pode visualizar o guia de termos.',
  DATA_EXPEDICAO: 'Data de expedição',
  DATA_MATRICULA: 'Data de matrícula',
  DATA_NASCIMENTO: 'Data de nascimento',
  DADOS_ALUNO: 'Dados do aluno',
  DADOS_CONTATO: 'Dados de contato',
  DADOS_ENDERECO: 'Dados de endereço',
  DADOS_RESPONSAVEIS: 'Dados dos responsáveis',
  DATA_CRIACAO: 'Data de criação',

  // Letra E:
  ESTRUTURA_CEJ: 'Estrutura CEJ',
  ESTRUTURA_CEJ_DESCRICAO:
    'Clique no card desejado abaixo para manejar a estrutura, adicionando, editando e/ou deletando itens:',
  ESTRUTURA_OUTRAS_INSTITUICOES: 'Estrutura de outras instituições',
  ESTRUTURA_OUTRAS_INSTITUICOES_DESCRICAO:
    'Clique no card desejado abaixo para manejar a estrutura, adicionando, editando e/ou deletando itens:',
  EMENTA: 'Ementa',
  ENCERRAR_PERIODO_LETIVO: 'Encerrar período letivo',
  EDITAR_DOCUMENTO_PADRAO: 'Editar documento padrão',
  EDITAR_DOCUMENTO_PADRAO_DESCRICAO:
    'Nessa tela é possível editar um documento padrão, para isso utilize o editor de texto para alterar o documento, também é possível visualizar o guia de termos.',
  EXIBIR_GUIA_TERMOS: 'Ver guia de termos',
  ESCONDER_GUIA_TERMOS: 'Esconder guia de termos',
  EMAIL: 'Email',
  ENDERECO: 'Endereço',
  ESTADO: 'Estado',
  ESTADO_CIVIL: 'Estado civil',
  EXPORTAR_PARA_EXCEL: 'Exportar para excel',

  GUIA_TERMOS: 'Guia de termos',
  // Letra F:
  FILTRAR_POR: 'Filtrar por',
  FILTRAR_POR_PLACEHOLDER: 'Filtrar por nome',
  FORMA_PAGAMENTO: 'Forma de pagamento',
  FECHAR_TURMA: 'Fechar turma',
  FECHAMENTO_PERIODO_LETIVO_DESCRICAO:
    'Nessa tela você pode processar um período letivo por ano letivo, abrir e fechar segmentações e encerrar o período letivo',
  FECHAR_SEGMENTACAO: 'Fechar segmentação',
  FORMULARIO_ADITIVO: 'Novo aditivo',
  FORMULARIO_ADITIVO_DESCRICAO:
    'Nessa tela você pode incluir um aditivo no contrato selecionado',
  FORMULARIO_ADITIVO_TURMA_DESCRICAO:
    'Nessa tela é possível incluir aditivo para contratos de uma turma',
  FOTO: 'Foto',
  FORMULARIO_INSTITUICAO_ENSINO:
    'Nessa tela você pode gerenciar informações sobre a instituição de ensino',
  FILTROS: 'Filtros',
  FICHA_SOCIOECONOMICA: 'Ficha socioeconômica',
  FICHA_SOCIOECONOMICA_DESCRICAO:
    'Selecione os filtros para gerar a ficha socioeconômica e etiquetas.',

  // Letra G:
  GERENCIADOR_TURMAS: 'Gerenciador de turmas',
  GERENCIADOR_TURMAS_DESCRICAO:
    'Utilize o filtro para encontrar uma turma e gerenciar adicionando ou removendo alunos dela:',
  GERENCIADOR_TURMAS_ALUNO_REMOVIDO:
    'Aluno removido! Você pode encontrar ele na tabela de alunos sem turma.',
  GRUPO_TURMA: 'Grupo de turma',
  GRUPOS_TURMA: 'Grupos de turma',
  GRUPOS_TURMA_CEJ_DESCRICAO:
    'Esta é a página de grupos turma do CEJ. Você pode cadastrar utilizando o botão no topo a direita ou, usar o filtro para exibir dados e então editar ou excluir clicando em "ações" na linha desejada.',
  GRAU_INSTRUCAO: 'Grau de instrução',
  GERAR_REMATRICULA: 'Gerar contratos de rematrícula(s)',
  GERAR: 'Gerar',

  GERAR_FICHA_SOCIOECONOMICA: 'Gerar ficha socioeconômica',
  GERAR_ETIQUETAS: 'Gerar etiquetas',
  // Letra H:
  HISTORICO: 'Histórico',
  HISTORICO_ESCOLAR: 'Histórico escolar',
  HISTORICO_ESCOLAR_DESCRICAO:
    'Nessa tela é possível filtrar a lista de alunos pelo filtro, cadastrar, editar e excluir históricos existentes, para isso clique no botão <strong> Ações </strong> na listagem de alunos.',

  // Letra I:
  IDIOMA: 'Idioma',
  INSTITUICAO_ENSINO: 'Instituição de ensino',
  INSTITUICAO_ENSINO_CEJ_DESCRICAO:
    'Esta é a página de instituições de ensino externas. Você pode cadastrar utilizando o botão no topo a direita ou, editar/excluir clicando em "ações" na linha desejada.',
  IMPRIMIR_BOLETINS: 'Imprimir boletins',
  IMPRIMIR_PARECER_DESCRITIVO: 'Imprimir parecer descritivo',
  IMPRIMIR_RELATORIO_DISCIPLINAS: 'Imprimir relatório disciplinas',
  IMPRIMIR_RELATORIO_DISCIPLINAS_DESCRICAO:
    'Nessa tela é possível gerar o relatório de disciplinas, selecione as opções no filtro e clique em gerar relatório.',
  IMPRIMIR_RELATORIO_TURMAS_PARA_ALUNO: 'Imprimir relatório turmas para aluno',
  IMPRIMIR_RELATORIO_TURMAS_PARA_ALUNO_DESCRICAO:
    'Realiza a impressão de relatório turmas para aluno',
  IMPRIMIR_RELATORIO_VALORES_PARA_CONTRATOS:
    'Imprimir relatório valores para contratos',
  IMPRIMIR_RELATORIO_ESTOQUE:
    'Imprimir relatório de estoque',
  IMPRIMIR_RELATORIO_VALORES_PARA_CONTRATOS_DESCRICAO:
    'Realiza a impressão de relatório valores para contratos',
  IMPRIMIR_RELATORIO_ESTOQUES:
    'Realiza a impressão de relatório de estoques',
  IMPRIMIR_RELATORIO_DESCONTOS_PARA_CONTRATOS:
    'Imprimir relatório descontos para contratos',
  IMPRIMIR_RELATORIO_DESCONTOS_PARA_CONTRATOS_DESCRICAO:
    'Realiza a impressão de relatório descontos para contratos',

  IDENTIFICADOR_CENSO: 'Identificador censo',
  IDENTIDADE: 'Identidade',
  INSTITUICAO_ORIGEM: 'Instituição de origem',
  IMPRIMIR_ETIQUETAS: 'Imprimir etiqueta',
  IMPRIMIR_RELATORIOS_HISTORICO_ESCOLAR:
    'Imprimir relatórios baseados em histórico escolar',
  IMPRIMIR_RELATORIOS_HISTORICO_ESCOLAR_DESCRICAO:
    'Nessa tela é possível gerar relatórios baseados em histórico escolar para alunos individualmente, para isso selecione os campos do filtro e clique em listar alunos, para gerar relatórios selecione a opção desejada no botão de ação nas linhas da tabela.',
  // Letra J:
  // Letra K:
  // Letra L:
  LISTAR_ALUNOS: 'Listar alunos',
  LIBERAR_BOLETIM: 'Liberar boletim no portal',
  LISTA_DE_PRECOS: 'Lista de preços selecionados',
  LISTA_DE_DESCONTOS: 'Lista de descontos incluídos',
  LISTA_DOCUMENTOS: 'Lista de documentos',
  LISTA_DE_CONTRATOS: 'Lista de contratos',
  ATIVAR_CONTRATOS: 'Ativar contratos(s)',
  IMPRIMIR_CONTRATOS: 'Imprimir contratos(s)',
  IMPRIMIR_FICHA_REMATRICULA: 'Imprimir ficha de rematricula',
  LISTAR: 'Listar',
  LISTAR_CONTRATOS: 'Listar contratos',
  LAYOUT_DESCONTO_CONTRATO: 'Layout desconto contrato',
  LAYOUT: 'Layout',
  // Letra M:
  MATRICULA: 'Matrícula',
  MATRICULAS: 'Matrículas',
  MOTIVO_CANCELAMENTO: 'Motivo do cancelamento',
  MOTIVO_CANCELAMENTO_CADASTRAR:
    'Motivo de cancelamento de contrato criado com sucesso.',
  MOTIVO_CANCELAMENTO_ATUALIZAR:
    'Motivo de cancelamento de contrato atualizado com sucesso.',
  // Letra N:
  NOME: 'Nome',
  NUM_RESERVISTA: 'Número de reservista',
  NUM_RESERVISTA_PLACE: 'Digite o número do reservista',
  NAO_INFORMADO: 'Não informado',
  NOVO_ADITIVO: 'Novo aditivo',
  NOVO_ADITIVO_TURMAS: 'Novo aditivo na turma',

  NOVO_DOCUMENTO_PADRAO: 'Novo documento padrão',
  NOVO_DOCUMENTO_PADRAO_DESCRICAO:
    'Nessa tela é possível criar um documento padrão, para isso utilize o editor de texto, também é possível visualizar o guia de termos.',

  NACIONALIDADE: 'Nacionalidade',
  NATURALIDADE: 'Naturalidade',
  NUMERO: 'Número',
  NOTA_RECUPERACAO: 'Nota da recuperação',
  // Letra O:
  ORIENTACAO_RELATORIO: 'Gerar relatório na horizontal',
  OBSERVACOES: 'Observações',
  ORGAO_EXPEDIDOR: 'Órgão expedidor',

  // Letra P:
  PAINEL_ACADEMICO: 'Painel acadêmico',
  PAINEL_ACADEMICO_DESCRICAO:
    'Selecione um card abaixo para utilizar as funcionalidades:',
  PAINEL_PROFESSOR: 'Painel do professor',
  PAINEL_COORDENACAO: 'Painel coordenação',
  PARCELA: 'Parcela',
  PERIODO_LETIVO: 'Período letivo',
  PERIODO_LETIVO_DESCRICAO:
    'Nessa tela você pode criar, editar e excluir períodos letivos.',
  PLANO_ENSINO: 'Plano ensino',
  PROFESSORES: 'Professores',
  PESSOA: 'Pessoa',
  PROCESSAR_BOLETIM: 'Processar boletim',
  PROCESSAR_TURMA: 'Processar turma',
  PROCESSAR_PERIODO: 'Processar período letivo',
  PRECOS: 'Preços',
  PREENCHA_OS_CAMPOS_OBRIGATORIOS:
    'Antes de enviar, verifique se os campos obrigatórios foram preenchidos.',
  PREENCHA_PRECOS_OU_DESCONTOS:
    'As abas de Preços e Descontos estão vazias, ao menos uma deve ser preenchida.',

  PAINEL_PROFESSOR_DESCRICAO:
    'Nessa tela você pode filtrar as disciplinas de cada turma e realizar operações como lançar notas, frequência, etc. Para isso Clique no botão de ação para consultar todas as opções disponíveis após filtrar.',
  // Letra Q:
  QUANTIDADE_AVALIACOES: "Quantidade de avaliações",
  QUANTIDADE_MAIOR_QUANTIDADE_TOTAL: "A quantidade não pode ser maior que a quantidade total em estoque",
  QUANTIDADE_TOTAL_SEM_ESTOQUE: "Este produto não tem nenhuma quantidade em estoque",
  // Letra R:
  RECUPERACAO_BIMESTRE: 'Recuperação bimestre?',
  REGISTRO_PROFISSIONAL: 'Registro profissional',
  REGISTRO_PROFISSIONAL_PLACE: 'Digite o número do registro profissional',
  REABRIR_TURMA: 'Reabrir turma',
  RELATORIO_FECHAMENTO: 'Relatório de fechamento',
  REMOVER: 'Remover',
  RELATORIOS: 'Relatórios',
  RELATORIOS_FECHAMENTO: 'Relatórios fechamento',
  RELATORIO_NOTAS_FALTAS: 'Notas e faltas',
  RELATORIO_QUADRO_ANALITICO: 'Quadro analítico',
  RELATORIO_QUADRO_ANALITICO_SEGMENTACAO: 'Quadro analítico segmentação',
  RELATORIO_MEDIA_FINAL_RECUPERACAO: 'Média final e recuperação',
  RELATORIO_COM_DADOS: 'Com dados lançados',
  RELATORIO_SEM_DADOS: 'Sem dados lançados',
  RELATORIO_REGISTRO_FREQUENCIA: 'Registro frequência',
  RELATORIO_REGISTRO_AVALIACAO: 'Registro avaliação',
  RELATORIO_FREQUENCIA: 'Frequência',
  RELATORIO_NOTA: 'Nota',
  RELATORIO_PONTO_ADICIONAL: 'Ponto adicional',
  RELATORIO_PARECER_DESCRITIVO: 'Parecer descritivo',
  REGISTRO_NASCIMENTO: 'Registro de nascimento',
  RELIGIAO: 'Religião',
  RESPONSAVEL_FILIACAO: 'Responsável filiação',
  RESPONSAVEL_FINANCEIRO: 'Responsável financeiro',
  RESPONSAVEL_PEDAGOGICO: 'Responsável pedagógico',
  RESIDENCIAL: 'Telefone residencial',
  RECUPERACAO_SEGMENTACAO: 'Recuperação por segmentação',
  RELATORIOS_TURMAS: 'Relatórios de turmas',
  RELATORIO_TURMAS_DESCRICAO:
    'Nessa tela você pode gerar relatórios por turmas por meio do filtro abaixo, é possível escolher quais campos serão exibidos no relatório marcando os campos correspondentes que estão abaixo do filtro. Estão disponíveis relatório em PDF, opção de exportar para Excel e relatório de Etiquetas',
  RELATORIO_IRMAOS: 'Relatório irmãos',
  RELATORIO_IRMAOS_DESCRICAO:
    'Selecione o período letivo para gerar o relatório de irmãos.',
  RELATORIO_TOTALIZADORES: 'Relatório totalizadores',
  RELATORIO_TOTALIZADORES_DESCRICAO:
    'Selecione o filtro para gerar o relatório em pdf.',
  RELATORIOS_SITUACAO_CONTRATO: 'Relatórios de situação do contrato',
  RELATORIOS_SITUACAO_CONTRATO_DESCRICAO:
    'Selecione os filtros para gerar o relatório de sistuação dos contratos em formato PDF ou CSV, os relatórios podem ser sintéticos ou analíticos e você pode escolher se quer separar as turmas em folhas.',
  // Letra S:
  SITUACAO: 'Situação',
  SITUACAO_ATIVO: 'Funcionário',
  SITUACAO_INATIVO: 'Ex-funcionário',
  PESO: 'Peso',
  SIGLA: 'Sigla',
  SEXO: 'Gênero',
  SEGMENTACAO: 'Segmentação',
  SEM_SEGMENTACAO_PARA_ANO_SELECIONADO:
    'Sem segmentação para o ano selecionado',
  SEM_CURSOS: 'Sem cursos para listar',
  STATUS_MATRICULA: 'Status da matrícula',
  SECAO_ELEITORAL: 'Seção eleitoral',
  SECAO_ELEITORAL_PLACE: 'Digite o número da seção eleitoral',
  SEM_DADOS: 'Filtro não trouxe resultados',
  SEM_DADOS_PARA_LISTAR: 'Sem dados para listar',
  SIMULACAO_PARCELAS: 'Parcelas',
  SALVAR_ADITIVO: 'Salvar aditivo',
  STATUS: 'Status',
  STATUS_CONTRATO: 'Status contrato',
  SELECIONE_CONTRATOS: 'Selecione os contratos',
  SELECIONAR_TODOS: 'Selecionar todos',
  SOMENTE_MATRICULAS_ATIVOS: 'Somente matrículas ativos',
  SOMENTE_PAGAMENTOS_EM_DIA: 'Somente pagamentos em dia',
  SEPARAR_TURMAS_FOLHAS: 'Separar turmas em folhas',
  SITUACAO_CONTRATO: 'Situação do contrato',
  SOLICITACAO: 'Solicitação',
  SALVAR_E_VOLTAR_PARA_MATRICULA: 'Salvar e voltar para matriculas',
  SOLICITANTE: 'Solicitante',

  // Letra T:
  TABELA_ALUNOS: 'Lista de alunos',
  TITULO_ELEITOR: 'Título de eleitor',
  TITULO_ELEITOR_PLACE: 'Digite o número do título de eleitor',
  TITULACOES: 'Titulações',
  DIPLOMAS: 'Diplomas',
  TIPO_AVALIACAO: 'Tipo de avaliação',
  TIPO_AVALIACAO_CEJ_DESCRICAO:
    'Esta é a página dos tipo de avaliações do CEJ. Você pode cadastrar utilizando o botão no topo a direita ou, usar o filtro para exibir dados e então editar ou excluir clicando em "ações" na linha desejada.',
  TIPO_AVALIACOES: 'Tipo de avaliações',
  TIPO_CONTRATO: 'Tipo de contrato',
  TIPO_ANALISE: 'Tipo de análise',
  TODOS_PROFESSORES: 'Ver professores em funcionários',
  TODOS_OS_PRECOS_FORAM_ADICIONADOS: 'Todos os valores foram adicionados',
  TURMA: 'Turma',
  TURMA_CADASTRAR: 'Cadastrar nova turma',
  TURMA_CADASTRADA: 'Turma criada com sucesso!',
  TURMA_CONFIRMAR_EXCLUSAO_TITULO: 'Confirme a exclusão da turma {item}',
  TURMA_CONFIRMAR_EXCLUSAO_DESCRICAO: 'Deseja excluir esta turma?',
  TURMA_EDITADA: 'Turma editada com sucesso!',
  TURMA_EXCLUIDA: 'Turma excluída com sucesso!',
  TURMAS: 'Turmas',
  TURMAS_CEJ_DESCRICAO:
    'Esta é a página de turmas do CEJ. Utilize o filtro abaixo para <strong>habilitar</strong> o botão de <strong>Cadastrar nova turma</strong> e exibir as turmas já existentes no filtro desejado:',
  TURMAS_DESCRICAO:
    'Esta é a página de turmas. Aqui é possível cadastrar uma turma, editar e excluir turmas na listagem, para isso clique em <strong>Cadastrar</strong> ou no botão de <strong>ação</strong> da listagem.',
  TITULACOES_PLACE: 'Digite as titulações obtidas',
  DIPLOMAS_PLACE: 'Digite os diplomas obtidos',
  TIPO_ADITIVO: 'Tipo de aditivo',
  TERMO: 'Termo',
  TIPO_HISTORICO: 'Tipo de histórico',
  TIPO_CERTIFICADO: 'Tipo de certificado',
  TIPO_FILTRO: 'Tipo de filtro:',
  TIPO_RELATORIO: 'Tipo de relatório',
  // Letra U:
  // Letra V:
  VINCULAR_CURSO: 'Vincular curso',
  VINCULAR_GRUPO_TURMA: 'Vincular grupo turma',
  VINCULAR_TURMA: 'Vincular turma',
  VAGAS: 'Vagas',
  VALOR: 'Valor',
  VALORES_PLANO_PARCELAMENTO: 'Valores e planos de parcelamento',
  VISUALIZAR_AULAS_CURSO: 'Ver turmas do curso',
  VISUALIZAR_ADITIVOS: 'Ver aditivos do contrato',
  VALIDAR_FECHAMENTO: 'Validar dados para fechamento',
  // Letra X:
  // Letra Y:
  // Letra Z:
};
