export const FinanceiroContasReceber = {
  // Letra A:
  ACESSO_PORTAL: 'Portal',
  ACOES: 'Ações',
  ADICIONE_UMA_CONTA:
    'Você precisa adicionar uma conta. Clique no botão "Buscar Conta" para adicionar uma conta.',
  ALUNO: 'Aluno',
  ATE: 'Até',

  // Letra B:
  BANCO: 'Banco',
  BAIXAR_TITULO: 'Baixar título',
  BAIXAR_TITULO_DESCRICAO:
    'Nessa tela é possível realizar a baixa de um título e visualizar os descontos do contrato.',
  BAIXAR_TITULOS_DESCRICAO:
    'Nessa tela é possível realizar a baixa de títulos.',
  BAIXAR_TITULOS: 'Baixar títulos',
  BAIXAR_TITULO_SUCESSO: 'Título baixado com sucesso',
  BLOQUEAR_PORTAL: 'Bloquear portal aluno',
  BUSCAR_MATRICULA: 'Buscar matrícula',

  // Letra C:
  CAIXA: 'Caixa',
  CANCELAMENTO_NAO_PERMITIDO:
    'Não e possível cancelar títulos já cancelados, liquidados ou liquidados parcialmente.',
  CANCELAR_TITULO: 'Cancelamento de título',
  CALCULAR_JUROS: 'Calcular juros',
  CALCULAR_DESCONTO: 'Calcular desconto',
  CALCULAR_DESCONTO_MANUAL: 'Calcular desconto manualmente',
  ADICIONAR_DESCONTO: 'Adicionar desconto',
  CANCELAMENTO_OBSERVACAO: 'Cancelamento observação',
  CONTRATO: 'Contrato',
  CONTA: 'Conta',
  CONTAS_RECEBER: 'Contas a receber',
  CONTAS_RECEBER_DESCRICAO_PAGE:
    'Utilize o filtro abaixo para listar os lançamentos:',

  // Letra D:
  DADOS_GERAIS: 'Dados gerais',
  DATA_FINAL: 'Data final',
  DATA_INICIAL: 'Data inicial',
  DATA_LANCAMENTO: 'Lançamento',
  DATA_PLACEHOLDER: 'dd/mm/aaaa',
  DATA_PAGAMENTO: 'Pagamento',
  DATA_VENCIMENTO: 'Vencimento',
  DESCONTO: 'Desconto',
  DESCONTOS: 'Descontos',
  DESCONTOS_DO_CONTRATO: 'Descontos de contrato',
  DESCONTO_OBSERVACAO: 'Desconto observação',
  DIGITE_OU_PROCURE: 'Digite ou selecione uma opção',
  DOCUMENTO: 'Doc.',
  DOCUMENTO_PLACEHOLDER: 'Digite o nome do documento',

  // Letra E:
  ESTORNAR_TITULO: 'Estornar título',
  ERRO_DATA_LANCAMENTO_MAIOR_QUE_VENCIMENTO:
    'A data de lançamento deve ser anterior a data de vencimento',

  // Letra F:
  // Letra G:
  GERAR_RECIBO: 'Gerar recibo de pagamento',
  GERAR_BOLETO: 'Gerar boleto(s)',
  GERAR_EXTRATO: 'Gerar extrato',

  // Letra H:
  HISTORICO: 'Histórico',

  // Letra I:
  // Letra J:
  JUROS: 'Juros',

  // Letra K:
  // Letra L:
  LIBERADO_PORTAL_ALUNO: 'Sim',
  LIBERAR_PORTAL: 'Liberar portal aluno',
  LISTAR_LANCAMENTOS: 'Listar Lançamentos',

  // Letra M:
  MATRICULA: 'Matrícula',
  MENSAGEM_REGISTRO: 'Lançamento registrado com sucesso',
  MENSAGEM_ESTORNO: 'Lançamento estornado com sucesso',

  // Letra N:
  NAO_LIBERADO_PORTAL_ALUNO: 'Não',
  NUMERO_CONTA: 'Número da conta',
  NOME_ALUNO: 'Nome aluno',
  // Letra O:
  OBSERVACOES: 'Observações',
  OBSERVACAO_CANCELAMENTO: 'Observação do cancelamento',
  OUTRAS_OPCOES_FILTRO: 'Outras opções de filtro:',

  // Letra P:
  PAGE_DESCRIPTION:
    'Aqui estão todos os lançamentos em contas à receber ao utilizar um filtro. Através do botão de ações na linha selecionada na tabela, pode: baixar, estornar ou cancelar um título; liberar ou bloquear para o portal do aluno; ou reimprimir títulos.',
  PAGE_TITLE: 'Contas à receber',
  PONTOS: 'Pontos',

  // Letra Q:
  // Letra R:
  REGISTRAR_LANCAMENTO: 'Registrar lançamento',

  // Letra S:
  SELECIONE_TIPO_PAGAMENTO: 'Selecione um tipo de pagamento',
  STATUS_PAGAMENTO: 'Status',

  // Letra T:
  TIPO_PAGAMENTO: 'Tipo de pagamento',
  TOTAL: 'Total',

  // Letra U:
  // Letra V:
  VALOR: 'Valor',
  VALORES: 'Valores',
  VALOR_DOCUMENTO: 'Valor do documento',

  // Letra X:
  // Letra Y:
  // Letra Z:
};
