export const AcademicoHome = {
  // Letra A:
  // Letra B:
  // Letra C:
  // Letra D:
  // Letra E:
  // Letra F:
  FUNCIONALIDADES: 'Features',
  FECHAMENTO_CURSO_TURMA: 'Closing courses and classes',
  FECHAMENTO_CURSO_TURMA_DESCRICAO:
    'On this screen, you have the ability to refine the search for classes, perform class closures, make report cards available on the student portal and print reports. These actions can be carried out by clicking on the corresponding buttons associated with the items in the list',

  // Letra G:
  GERENCIAR_ESTRUTURAS: 'Manage structures',

  // Letra H:
  // Letra I:
  // Letra J:
  // Letra K:
  // Letra L:
  // Letra M:
  // Letra N:
  // Letra O:
  // Letra P:
  PAINEL_ACADEMICO: 'Academic pannel',
  PAINEL_ACADEMICO_DESCRICAO: 'Select a card below to use the features:',

  // Letra Q:
  // Letra R:
  // Letra S:
  // Letra T:
  // Letra U:
  // Letra V:
  // Letra X:
  // Letra Y:
  // Letra Z:
};
