import store from "@/store/Store";
import { VERIFICAR_AUTENTICACAO } from "@/store/autenticacao.module";

export default async function verificaAutenticacao({ next, router, to }) {
  await store.dispatch(VERIFICAR_AUTENTICACAO);
  if (!store.getters.estaLogado) {
    return router.push({ name: "Login" });
  }

  if (to.name == "dashboard" || store.getters.admin) return next();

  let acessos = store.getters.acessos;
  let permissao = acessos.find((x) => x.acesso === to.meta.id);

  if (permissao)
    if (permissao.permissoes.includes("Visualizar")) {
      return next();
    }

  return router.push({ name: "dashboard" });
}
