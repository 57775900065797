export const ContabilPlanoContas = {
    // Letra A:
    ACOES: 'Actions',

    // Letra B:
    // Letra C:
    CONTABIL: 'Accounting',

    // Letra D:
    DESCRICAO: 'Description',

    // Letra E:
    ESTRUTURAL: 'Estructural',
    EXPORT: 'Export',

    // Letra F:
    // Letra G:
    // Letra H:
    // Letra I:
    IMPRIMIR_PLANO_CONTAS: 'Print account plan',

    // Letra J:
    // Letra K:
    // Letra L:
    // Letra M:
    // Letra N:
    // Letra O:
    // Letra P:
    PAGE_DESCRIPTION: 'Select the icon ▼ in "actions" column at generated table to edit or delete.',
    PAGE_TITLE: 'Account plans',
    PLANO_CONTA: 'Account plan',
    PLANO_CONTAS: 'Account plans',

    // Letra Q:
    // Letra R:
    // Letra S:
    // Letra T:
    TIPO_CONTA: 'Account type',
    TIPO_NATUREZA: 'Nature type',

    // Letra U:
    // Letra V:
    // Letra X:
    // Letra Y:
    // Letra Z:
}