export const SuprimentosLancamentosNF = {
  // Letter a:
  ADICIONAR_IMPOSTO: 'Add tax',
  ADICIONAR_ITEM: 'Add item',
  ACOES: 'Actions',

  // Letter B:
  // Letter C:
  CANCELAR_ESCONDER_FORM: 'Cancel and hide form',
  CNPJ: 'CNPJ',

  // Letter D:
  DADOS_GERAIS: 'General data',
  DADOS_PAGAMENTO: 'Payment data',
  DADOS_PARCELAMENTO: 'Installment data',
  DATA_EMISSAO: 'Date of issue',
  DATA_EMISSAO_ATE: 'Date of issue (final)',
  DATA_EMISSAO_INICIO: 'Date of issue (start)',
  DATA_VENCIMENTO: 'Due date',
  DATA_VENCIMENTO_INICIO: 'Due date (start)',
  DATA_VENCIMENTO_ATE: 'Due date (end)',
  DEPOSITO: 'Deposit',
  DESCONTOS: 'Discounts',
  DEPOSITOS: 'Deposits',

  // Letter e:
  // Letter F:
  FINANCEIRO: 'Financial',
  FORNECEDOR: 'Supplier',
  FORMA_PAGAMENTO: 'Method of payment',

  // Letter G:
  // Letter H:
  // Letter I:
  IMPOSTO: 'Tax',
  IMPOSTOS: 'Taxes',
  ITENS_PEDIDO: 'Order items',

  // Letter J:
  // Letter K:
  // Letter L:
  LANCAR_NF: 'Launch NF',

  // Letter M:
  MENSAGEM_ALERTA_CALCULAR_PARCELAS:
    'It is necessary to calculate the installments before saving',
  MENSAGEM_VALOR_PARCELAS_DIFERENTE_VALOR_TOTAL: 'The value of the installments must be equal to the total value of the Invoice',
  MENSAGEM_VALOR_VALOR_TOTAL_NF_INCORRETO: 'Incorrect Values ​​are',
  // Letter N:
  NOTA_FISCAL: 'Invoice',
  NOTA_FISCAL_PAGE_DESCRICAO:
    'Attention: once generated, an invoice cannot be edited.',
  NOTA_FISCAL_TAB_ITENS_DADOS_DESCRICAO:
    'Fill in here the issue and expiration dates of the invoice, as well as the supplier',
  NOTA_FISCAL_TAB_ITENS_PEDIDO_DESCRICAO:
    'Add the order items here. Select the ▼ icon in the "Actions" column in the table below to edit or delete an item.',
  NOTA_FISCAL_TAB_PAGAMENTO_DESCRICAO:
    'Fill in here the data related to the values of the invoice. Note that the type of invoice modifies the options for payment method and posting history.',
  NOTA_FISCAL_TAB_OBSERVACOES_DESCRICAO:
    'Fill in here any notes on the invoice.',
  NOTA_FISCAL_TAB_IMPOSTOS_DESCRICAO:
    'Add taxes and their respective values here. Select the ▼ icon in the "Actions" column in the table below to edit or delete an item.',
  NUMERO_NOTA_FISCAL: 'Invoice number',

  // Letter O:
  OBSERVACOES: 'Observations',

  // Letter P:
  PAGAMENTO: 'Payment',
  PAGE_TITLE: 'NF Entries',
  PAGE_DESCRIPTION:
    'Invoices are documents that attest to the performance of a commercial operation between two parties, being crucial for fiscal and tax control. Use the filter below to find invoices:',
  PRODUTO: 'Product',

  // Letter Q:
  QUANTIDADE: 'Quantity',

  // Letter R:
  // Letters:
  SEM_OBSERVACOES_CADASTRADAS: 'No registered observations',

  // Letter T:
  TIPO_NF: 'Type NF',
  TIPO_NOTA_FISCAL: 'Invoice type',

  // Letter U:
  // Letter V:
  VALOR_BRUTO: 'Gross value',
  VALOR_DESCONTOS: 'Amount of discounts',
  VALOR_FRETE: 'Freight value',
  VALOR_UNITARIO: 'Unitary value',
  VALOR_IMPOSTOS: 'Amount of taxes',
  VALOR_TOTAL: 'Total value',
  VALOR_TOTAL_DA_NOTA_FISCAL: 'Total value of the invoice',
  NUMERO_PARCELAS: 'Number of installments',
  VISUALIZAR: 'View',
  VISUALIZAR_NF_DESCRICAO:
    'On this screen, you can view the invoice data. Use the buttons below to view the information by category.',

  // Letter X:
  // Letter Y:
  // Letter Z:
};
