const { VUE_APP_API, VUE_APP_VERSION, NODE_ENV } = process.env;

export const API_URL = VUE_APP_API;
export const VERSION = VUE_APP_VERSION;
export const ENV = NODE_ENV;

export default {
  API_URL,
  VERSION,
  ENV
};
