// Telas do acadêmico:
import { Academico } from './academico/en'
import { AcademicoHome } from './academico/home/en'
import { AcademicoGerenciamento } from './academico-gerenciamento/en'
import { AcademicoDiarioClasse } from './academico/diario-classe/en'
import { AcademicoMatriculas } from './academico/matriculas/en'
import { Admin } from './admin/en'
import { FichaSaude } from './ficha-saude/en'

// Telas de autentificação:
import { AutentificacaoLogin } from './autentificacao/login/en'
import { AutentificacaoModalEsqueceuSenha } from './autentificacao/modal-esqueceu-senha/en'
import { CamposValidacao } from './campos-validacao/en'

// Contábil:
import { Contabil } from './contabil/en'
import { ContabilPlanoContas } from './contabil/plano-contas/en'
import { ContabilFechamento } from './contabil/fechamento-contabil/en'
import { ContabilLancamento } from './contabil/lancamentos/en'

import { CurriculoEscolar } from './curriculo-escolar/en'

// Financeiro:
import { Financeiro } from './financeiro/en'
import { FinanceiroLancamentos } from './financeiro/lancamentos/en'
import { FinanceiroContasPagar } from './financeiro/contas-pagar/en'
import { FinanceiroContasReceber } from './financeiro/contas-receber/en'

import { Home } from './home/en'
import { InstituicaoEnsino } from './curriculo-escolar/instituicao-ensino/en'
import { Geral } from './geral/en'
import { Menu } from './menu/en'
import { PainelProfessor } from './painel-professor/en'
import { Precos } from './precos/en'
import { Perfis } from './perfis/en'
import { Permissoes } from './permissoes/en'
import { Pessoas } from './pessoas/en'
import { Rh } from './rh/en'

// Suprimentos:
import { Suprimentos } from './suprimentos/en'
import { SuprimentosLancamentosNF } from './suprimentos/lancamentos-nf/en'
import { SuprimentosEstoque } from './suprimentos/estoque/en'

import { ShortcutCards } from './shortcut-cards/en'
import { Usuarios } from './usuarios/en'

export const en = {
  // Telas de admin:
  ADMIN: { ...Admin },

  // Telas do acadêmico:
  ACADEMICO: { ...Academico },
  ACADEMICO_DIARIO_CLASSE: { ...AcademicoDiarioClasse },
  ACADEMICO_HOME: { ...AcademicoHome },
  ACADEMICO_GERENCIAMENTO: { ...AcademicoGerenciamento },
  ACADEMICO_MATRICULAS: { ...AcademicoMatriculas },
  FICHA_SAUDE: { ...FichaSaude },

  // Telas de autentificação
  AUTENTIFICACAO_LOGIN: { ...AutentificacaoLogin },
  AUTENTIFICACAO_MODAL_ESQUECEU_SENHA: { ...AutentificacaoModalEsqueceuSenha },

  CURRICULO_ESCOLAR: { ...CurriculoEscolar },

  // Contábil:
  CONTABIL: { ...Contabil },
  CONTABIL_PLANO_CONTAS: { ...ContabilPlanoContas },
  CONTABIL_FECHAMENTO: { ...ContabilFechamento },
  CONTABIL_LANCAMENTO: { ...ContabilLancamento },

  // Financeiro:
  FINANCEIRO: { ...Financeiro },
  FINANCEIRO_LANCAMENTOS: { ...FinanceiroLancamentos },
  FINANCEIRO_CONTAS_PAGAR: { ...FinanceiroContasPagar },
  FINANCEIRO_CONTAS_RECEBER: { ...FinanceiroContasReceber },

  HOME: { ...Home },
  INSTITUICAO_ENSINO: { ...InstituicaoEnsino },
  USUARIOS: { ...Usuarios },
  PERMISSOES: { ...Permissoes },
  PERFIS: { ...Perfis },
  PESSOAS: { ...Pessoas },
  RH: { ...Rh },
  PRECOS: { ...Precos },
  TITULO: "Lyncas Base Portal",
  GERAL: { ...Geral },
  MENU: { ...Menu },
  CAMPOS_VALIDACAO: { ...CamposValidacao },
  PAINEL_PROFESSOR: { ...PainelProfessor },
  SHORTCUT_CARDS: { ...ShortcutCards },

  // Suprimentos:
  SUPRIMENTOS: { ...Suprimentos },
  SUPRIMENTOS_LANCAMENTOS_NF: { ...SuprimentosLancamentosNF },
  SUPRIMENTOS_ESTOQUE: { ...SuprimentosEstoque },
}