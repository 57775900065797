export const AcademicoGerenciamento = {
    // Letra A:
    // Letra B:
    BREADCRUMB: 'Painel acadêmico',
    // Letra C:
    // Letra D:
    DESCRICAO_GERENCIAMENTO: 'Nesta página cada card corresponde a uma entidade que alimenta todo o sistema, seja de itens para o CEJ ou para outras instituições. Atente para a seção pretendida e clique no card desejado para criar, editar e/ou excluir dados.',
    DESCRICAO_ESTRUTURA_CEJ: 'Nos cards abaixo estão os itens que alimentam a estrutura CEJ:',
    DESCRICAO_ESTRUTURA_OUTRAS_INSTITUICOES: 'Nos cards abaixo estão os itens que alimentam a estrutura de outras instituições:',
    // Letra E:
    // Letra F:
    FECHAMENTO_PERIODO: 'Fechamento período letivo',
    // Letra G:
    // Letra H:
    // Letra I:
    // Letra J:
    // Letra K:
    // Letra L:
    // Letra M:
    // Letra N:
    // Letra O:
    // Letra P:
    // Letra Q:
    // Letra R:
    // Letra S:
    // Letra T:
    TITULO_GERENCIAMENTO: 'Acadêmico: gerenciamento de estruturas',
    // Letra U:
    // Letra V:
    // Letra X:
    // Letra Y:
    // Letra Z:
}