export const ContabilLancamento = {
  // Letter A:
  // Letter B:
  BUSCAR_DOCUMENTO_ORIGEM: 'Search source document',
  // Letter C:
  CONFIRMAR_EXCLUIR_DOCUMENTO: 'Delete accounting entry',
  CONFIRMAR_EXCLUIR_DOCUMENTO_MENSAGEM:
    'Do you want to delete this accounting entry?',

  CONFIRMAR_EXCLUIR_DOCUMENTO_ITEM: 'Confirm deletion of linked entry',
  CONFIRMAR_EXCLUIR_DOCUMENTO_ITEM_MENSAGEM:
    'Do you want to delete this linked entry?',

  // Letter D:
  DIGITAR_CODIGO: 'Enter document code',
  // Letter E:
  // Letter F:
  // Letter G:
  // Letter H:
  // Letter I:
  // Letter J:
  // Letter K:
  // Letter L:
  // Letter M:
  // Letter N:
  // Letter O:
  // Letter P:
  PAGE_DESCRIPTION:
    'An entry is a recognition of an asset, liability, revenue, or expense at a specific time. Register entries here or use the filter to find a previously registered entry.',
  // Letter Q:
  // Letter R:
  // Letter S:
  SELECIONAR_DOCUMENTO_ORIGEM: 'Select source document',
  SELECIONAR_TIPO_CONTA: 'Select source document type'
  // Letter T:
  // Letter U:
  // Letter V:
  // Letter X:
  // Letter Y:
  // Letter Z:
};
