export const Permissoes = {
  PERFIL: "Perfil",
  TELA: "Tela",
  CAMPO: "Campo",
  PERMISSOES: "Permissões",
  VISUALIZAR: "Visualizar",
  LISTAR: "Listar",
  CRIAR: "Criar",
  EDITAR: "Editar",
  EXCLUIR: "Excluir",
};
