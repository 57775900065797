export const Usuarios = {
    // Letra A:
    ADICIONAR_PERFIS: "Adicionar perfis",

    // Letra B:
    // Letra C:
    CONFIRMAR_EXCLUIR: "Tem certeza que deseja excluir o usuário {nome}?",
    CONFIRMAR_EXCLUIR_COM_USUARIO: "Existe um usuário vinculado a essa pessoa {nome}, deseja mesmo prosseguir?",

    // Letra D:
    DADOS_ACESSO: "Dados de acesso",
    DADOS_PESSOAIS: "Dados pessoais",

    // Letra E:
    // Letra F:
    // Letra G:
    GERENCIAR_PERFIS: 'Gerenciar perfis',

    // Letra H:

    // Letra I:
    ID: "ID",

    // Letra J:
    // Letra K:

    // Letra L:
    LOGIN: "Login",

    // Letra M:
    MENSAGEM_CADASTRADO: "Usuário cadastrado com sucesso",
    MENSAGEM_EDITADO: "Usuário editado com sucesso",
    MENSAGEM_EXCLUIDO: "Usuário excluído com sucesso.",

    // Letra N:
    NOME: "Nome",
    NOVO_USUARIO: "Novo usuário",

    // Letra O:
    // Letra P:
    PAGE_USUARIOS_DESCRICAO: 'Selecione o ícone ▼ na coluna "ações" na tabela gerada para editar, adicionar perfis ou excluir um usuário.',
    PERFIL_ASSOCIADO_SUCESSO: "Perfil associado com sucesso",
    PERFIS_ASSOCIADOS: 'Perfis associados',
    PESSOA_SEM_USUARIO: "Pessoa",

    // Letra Q:
    // Letra R:
    // Letra S:
    SEM_PERFIL_ASSOCIADO: 'Sem perfil associado',

    // Letra T:
    // Letra U:
    USUARIOS: 'Usuários',

    // Letra V:
    // Letra X:
    // Letra Y:
    // Letra Z:
};  