export const Pessoas = {
  ADICIONAR_PERFIS: "Adicionar perfis",
  BAIRRO: "Bairro",
  CADASTRO_CONTATO:"Cadastrar contato",
  CADASTRAR_FILIACAO:"Cadastrar filiação",
  CONTATO_REMOVIDO: 'Contato removido!',
  CEP: "CEP",
  CODIGO_PESSOA: "Código pessoa",
  CEP_BUSCAR: "Digite para busca automática",
  COMPLEMENTO: "Complemento",
  CONFIRMAR_EXCLUIR_CONTATO:"Tem certeza que deseja excluir o registro de contato?",
  CPF: "CPF",
  CONFIRMAR_EXCLUIR: "Tem certeza que deseja excluir a pessoa {nome}?",
  CONTATO: "Contato",
  CONTATO_EXCLUIDO: "Contato excluído!",
  CONTATO_PRINCIPAL:"É o contato principal?",
  DADOS_ACESSO: "Dados de acesso",
  DATA_EXPEDICAO: "Data de expedição",
  DADOS_PESSOAIS: "Dados pessoais",
  DATA_NASCIMENTO: "Data de nascimento",
  DESCRICAO: "Descrição",
  EMAIL: "E-mail",
  ENDERECO_EXCLUIDO: "Endereço excluído com sucesso",
  ENDERECO_PRINCIPAL:"Endereço principal",
  ESTADO:"Estado",
  ESTADO_CIVIL: "Estado civil",
  ENDERECO: "Endereço",
  FILIACAO:"Filiações",
  FOTO:"Registro de foto",
  FOTO_NAO_VINCULADA: 'Nenhuma imagem encontrada',
  ID: "ID",
  GRAU_PARENTESCO:"Grau de parentesco",
  LOGRADOURO: "Logradouro",
  MENSAGEM_EXCLUIDO: "Pessoa excluída com sucesso.",
  MENSAGEM_CADASTRADO: "Pessoa cadastrada com sucesso",
  MENSAGEM_ATUALIZACAO: "Pessoa atualizada com sucesso",
  MUNICIPIO: "Município",
  NACIONALIDADE: "Nacionalidade",
  NATURALIDADE: "Naturalidade",
  NOVA_FILIACAO: "Nova filiação",
  NOVO_CONTATO: "Novo contato",
  NOVO_ENDERECO: "Novo endereço",
  NOVO_RESPONSAVEL: "Novo responsável",
  NOME: "Nome",
  NUMERO: "Número",
  ORGAO_EXPEDIDOR: "Órgão expedidor",
  PAIS:"País",
  PARENTESCO:"Pessoa parentesco",
  PESSOA_PARENTESCO:"Parentesco",
  PERFIL_ASSOCIADO_SUCESSO: "Perfil associado com sucesso",
  RG: "RG",
  REGISTRO_NASCIMENTO: "Registro de nascimento",
  RELIGIAO: "Religião",
  SOBRENOME: "Sobrenome",
  TIPO_CONTATO: "Tipo do contato",
  TIPO_CONTATO_CELULAR: "Digite seu número de celular",
  TIPO_CONTATO_COMERCIAL: "Digite seu número comercial",
  TIPO_CONTATO_EMAIL: "Digite seu e-mail",
  TIPO_CONTATO_FAX: "Digite seu fax",
  TIPO_CONTATO_RESIDENCIAL: "Digite seu número residencial",
  TIPO_CONTATO_WEBSITE: "Digite o website",
  TIPO_ENTREGA_CORRESPONDENCIA: "Tipo da entrega da correspondência",
  TIPO_ENDERECO:"Tipo do endereço",
  TELEFONE: "Telefone",
  SEXO: "Sexo",
};