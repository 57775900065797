export const InstituicaoEnsino = {
    ANOS_SERIES:"Anos / Séries",
    CNPJ:"CNPJ",
    CONTATOS:"Contatos",
    CONTATO_INSTITUICAO:"Contato instituição",
    CPF_CNPJ:"CPF / CNPJ",
    ENDERECO_INSTITUICAO:"Endereço instituição",
    ENDERECOS:"Endereços",
    DISCIPLINA:"Disciplina",
    DADOS_INSTITUICAO_ENSINO:"Dados da instituição de ensino",
    INSTITUICAO_ENSINO:"Instituição de ensino",
    FORNECEDOR:"Fornecedor",
    NOME: 'Nome',
    RAZÃO_SOCIAL: 'Razão social',
}