export const FinanceiroContasReceber = {
  // Letra A:
  ACESSO_PORTAL: 'Portal access',
  ADICIONE_UMA_CONTA:
    "You need to add an account. Click on the button 'Search Account' to add an account.",
  ALUNO: 'Student',
  ATE: 'Until',

  // Letra B:
  BANCO: 'Bank',
  BAIXAR_TITULO: 'Download deed',
  BAIXAR_TITULO_DESCRICAO:
    'On this screen, you can process the payment of a title and view the contract discounts.',
  BAIXAR_TITULOS: 'Download deeds',
  BAIXAR_TITULOS_DESCRICAO:
    'On this screen, you can process the payment of titles.',
  BAIXAR_TITULO_SUCESSO: 'Deed downloaded successfully',
  BLOQUEAR_PORTAL: 'Block student portal',
  BUSCAR_MATRICULA: 'Search enrollment',

  // Letra C:
  CAIXA: 'Cash',
  CANCELAR_TITULO: 'Title cancellation',
  CANCELAMENTO_NAO_PERMITIDO:
    "It's not possible to cancel something that have already been canceled, settled or partially settled.",
  CALCULAR_JUROS: 'Calculate fees',
  CALCULAR_DESCONTO_MANUAL: 'Calculate discount manually',
  CALCULAR_DESCONTO: 'Calculate discount',
  ADICIONAR_DESCONTO: 'Add discount',
  CONTAS_RECEBER: 'Bills to receive',
  CONTRATO: 'Contract',
  CONTA: 'Account',

  // Letra D:
  DADOS_GERAIS: 'General data',
  DATA_FINAL: 'Final date',
  DATA_INICIAL: 'Initial date',
  DATA_LANCAMENTO: 'Data do Lançamento',
  DATA_PLACEHOLDER: 'dd/mm/aaaa',
  DATA_PAGAMENTO: 'Payment date',
  DATA_VENCIMENTO: 'Expiration date',
  DESCONTO: 'Discount',
  DESCONTOS: 'Discounts',
  DESCONTOS_DO_CONTRATO: 'Contract discounts',
  DESCONTO_OBSERVACAO: 'Discount observation',
  DIGITE_OU_PROCURE: 'Type or select an option',
  DOCUMENTO: 'Document',
  DOCUMENTO_PLACEHOLDER: 'Type the name of the document',

  // Letra E:
  ERRO_DATA_LANCAMENTO_MAIOR_QUE_VENCIMENTO:
    'The entry date must be before the due date',
  ESTORNAR_TITULO: 'Reverse Title',

  // Letra F:
  FILTRAR_LANCAMENTO: 'Filter release',

  // Letra G:
  GERAR_RECIBO: 'Generate payment receipt',
  GERAR_BOLETO: 'Generate invoices',
  GERAR_EXTRATO: 'Generate statement',

  // Letra H:
  HISTORICO: 'History',

  // Letra I:
  // Letra J:
  // Letra K:
  // Letra L:
  LIBERAR_PORTAL: 'Release student portal',
  LISTAR_LANCAMENTOS: 'List entries',

  // Letra M:
  MENSAGEM_REGISTRO: 'Entry registered successfully',
  MENSAGEM_ESTORNO: 'Entry reversed seccesfully',

  // Letra N:
  NUMERO_CONTA: 'Account number',

  // Letra O:
  OBSERVACOES: 'Notes',
  OBSERVACAO_CANCELAMENTO: 'Cancellation note',
  OUTRAS_OPCOES_FILTRO: 'Other filter options:',

  // Letra P:
  PAGE_DESCRIPTION:
    'Here you can see all postings on accounts payable by using a filter. Through the action button on the selected line in the table, you can: download or reverse a title, generate a payment receipt, and even adjust payment information.',
  PAGE_TITLE: 'Accounts to receive',

  // Letra Q:
  // Letra R:
  REGISTRAR_LANCAMENTO: 'Register entry',

  // Letra S:
  SELECIONE_TIPO_PAGAMENTO: 'Select a type of payment',
  STATUS_PAGAMENTO: 'Payment status',

  // Letra T:
  TIPO_PAGAMENTO: 'Payment type',

  // Letra U:
  VALORES: 'Values',
  // Letra V:
  // Letra X:
  // Letra Y:
  // Letra Z:
};
