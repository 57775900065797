export const Suprimentos = {
  // Letra A:
  ACOES: 'Ações',

  // Letra B:
  BUSCAR_PEDIDO: 'Buscar pedido',

  // Letra C:
  CANCELAR_ESCONDER_FORM: 'Cancelar e esconder form',
  CNPJ: 'CNPJ',
  CADASTRAR_DEPOSITO: 'Cadastrar depósito',
  CADASTRAR_PRODUTO: 'Cadastrar produto',

  // Letra D:
  DATA: 'Data',
  DATA_CADASTRO: 'Data do cadastro',
  DATA_ENTREGA: 'Data da entrega',
  DATA_EMISSAO: 'Data da emissão',
  DATA_FIM: 'Data final',
  DATA_INICIO: 'Data inicial',
  DATA_LANCAMENTO: 'Data do lançamento',
  DADOS_PRODUTO: 'Dados do produto',
  DATA_VENCIMENTO: 'Data do vencimento',
  DETALHES_MOVIMENTO: 'Detalhes do movimento',
  DEPOSITO: 'Depósito',
  DEPOSITOS: 'Depósitos',
  DEPOSITO_DESCRICAO:
    "Esta tela permite que você registre um novo depósito. Preencha as informações necessárias, incluindo a descrição do depósito e selecione o histórico de lançamento correspondente. Clique em 'Salvar' para concluir o registro do depósito. Você também pode visualizar e gerenciar os depósitos existentes nesta tela.",
  DESCRICAO: 'Descrição',
  DESCRICAO_TELA:
    'Nessa tela você pode realizar retiradas de produtos do estoque, realizar movimentações de produtos entre depósitos, também pode visualizar uma listagem das movimentações de um produto por filtro de produto e depósito.',

  DESCRICAO_MOVIMENTO_RETIRANTE: 'Descrição do movimento/retirante',

  DIGITE_AQUI: 'Digite aqui',
  TRANSFERENCIA_REALIZADA: 'Transferência concluída',
  // Letra E:
  EFETUAR_SAIDA_MANUAL: 'Efetuar saída manual',
  ESTQOUES: 'Estoques',
  ESTOQUE: 'Estoque',

  // Letra F:
  FAMILIAS: 'Famílias',
  FINANCEIRO: 'Financeiro',
  FORMA_PAGAMENTO: 'Forma de pagamento',
  FORNECEDOR: 'Fornecedor',
  FORNECEDORES: 'Fornecedores',

  // Letra G:
  // Letra H:
  HISTORICO: 'Histórico',

  // Letra I:
  ITEM: 'Item',
  ITENS_PEDIDO: 'Itens do pedido',
  IMPOSTO: 'Imposto',
  IMPOSTOS: 'Impostos',
  IMPOSTO_PIS_COFINS_CSLL: 'PIS/CONFINS/CSLL',
  IMPOSTO_INSS: 'INSS',
  IMPOSTO_ISS: 'ISS',
  IMPOSTO_IR: 'IR',

  // Letra J:
  // Letra K:
  // Letra L:
  LANCAMENTOS_NF: 'Lançamentos de NF',
  LISTAR_ESTOQUE: 'Listar estoque',

  // Letra M:
  // Letra N:
  NENHUM_DEPOSITO_CADASTRADO:
    'Nenhum depósito cadastrado foi encontrado. Deseja cadastrar um depósito?',
  NENHUM_PRODUTO_CADASTRADO:
    'Nenhum produto cadastrado. Deseja cadastrar um produto?',
  NOME: 'Nome',
  NOME_FANTASIA: 'Nome fantasia',
  NOTA_FISCAL: 'Nota fiscal',
  NUMERO_NF: 'Número da nota fiscal',

  // Letra O:
  OBSERVACOES: 'Observações',

  // Letra P:
  PARCELAMENTO: 'Parcelamento',
  PEDIDO: 'Pedido',
  PLANO_PARCELAMENTO: 'Plano de parcelamento',
  PRODUTO: 'Produto',
  PRODUTOS: 'Produtos',
  PRODUTOS_DESCRICAO:
    'Nessa tela é possível listar, criar, editar e excluir produtos',

  // Letra Q:
  QUANTIDADE: 'Quantidade',
  QUANTIDADE_TOTAL: 'Qtd. total',
  QUANTIDADE_MINIMA: 'Quantidade mínima',

  // Letra R:
  RAZAO_SOCIAL: 'Razão social',

  // Letra S:
  SELECIONAR_DEPOSITO: 'Selecionar depósito',
  SELECIONAR_DEPOSITO_PLACEHOLDER: 'Digite ou selecione',
  SELECIONAR_PRODUTO: 'Selecionar produto',
  SELECIONAR_PRODUTO_PLACEHOLDER: 'Digite ou selecione',
  STATUS: 'Status',
  SUPRIMENTOS: 'Suprimentos',

  SELECIONE_DEPOSITO_PARA_TRANSFERENCIA: 'Depósito para transferência:',
  SELECIONE_PRODUTO_PARA_RETIRADA: 'Produto para retirada:',
  SELECIONE_PRODUTO_PARA_TRANSFERENCIA: 'Produto para transferência:',
  SELECIONE_DEPOSITO_PARA_RETIRADA: 'Depósito atual do produto:',
  SELECIONE_DEPOSITO_ANTIGO: 'Depósito atual',
  SELECIONE_DEPOSITO_NOVO: 'Novo depósito',
  SAIDA_MANUAL_PRODUTO: 'Saída manual de estoque',

  // Letra T:
  TIPO: 'Tipo',
  TIPO_MOVIMENTO: 'Tipo de movimento',
  TIPO_NF: 'Tipo de nota fiscal',
  TIPO_IMPOSTO: 'Tipo de imposto',
  TRANSFERENCIA_DEPOSITOS: 'Transferência entre depósitos',

  // Letra U:UNIDADE_MEDIDA
  UNIDADE_MEDIDA: 'Unidade de medida',

  // Letra V:
  VALOR: 'Valor',
  VALOR_UNITARIO: 'Valor unitário',
  VALOR_IMPOSTO: 'Valor imposto',
  VALOR_BRUTO: 'Valor bruto',
  VALOR_IMPOSTOS: 'Valor impostos',
  VALOR_TOTAL: 'Valor total',
  VISUALIZAR: 'Visualizar',
  VINCULAR_FORNECEDORES_AO_PEDIDO: 'Vincular fornecedores ao pedido',

  // Letra X:
  // Letra Y:
  // Letra Z:
};
