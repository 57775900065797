export const Contabil = {
  // Letra A:
  ACOES: 'Ações',
  ADICIONAR_LANCAMENTO: 'Adicionar lançamento',
  ANO_CONTABIL: 'Ano contábil',
  
  // Letra B:
  // Letra C:
  CADATRAR_ANO_CONTABIL: 'Cadastrar ano contábil',
  CADASTRAR_LANCAMENTO_CONTABIL: 'Cadastrar lançamento contábil',
  CADASTRAR_SALDO: 'Cadastrar saldo',
  CATEGORIA: 'Categoria',
  CRIAR_PERIODO_CONTABIL: 'Criar período contábil',
  CONTA: 'Conta',
  CONTA_CONTABIL: 'Conta contábil',
  CONTABIL: 'Contábil',
  CREDITOS: 'Créditos',

  // Letra D:
  DADOS_DOCUMENTO: 'Dados do documento',
  DADOS_PERIODO: 'Dados do período',
  DATA: 'Data',
  DATA_INICIO: 'Data início',
  DATA_FIM: 'Data fim',
  DATA_MOVIMENTO: 'Data do movimento',
  DATA_DOCUMENTO: 'Data do documento',
  DEBITOS: 'Débitos',
  DESCRICAO: 'Descrição',
  DESCRICAO_LANCAMENTO: 'Descrição do lançamento',
  DOCUMENTO: 'Documento',
  DOCUMENTO_ORIGEM: 'Documento de origem',
  DETALHAR: 'Detalhar', 
  
  // Letra E:
  ENCERRAR_EXERCICIO: 'Encerrar exercício',
  EXPORTAR_LANCAMENTOS_CONTABEIS: 'Exportar lançamentos contábeis',
  // Letra F:
  FECHAMENTO_CONTABIL: 'Fechamento contábil',

  // Letra G:
  // Letra H:
  HISTORICO_COMPLEMENTO: 'Histórico/Complemento',
  // Letra I:
  ITEM: 'Item',
  IDENTIFICADORCONTABIL: 'Identificador contábil',
  // Letra J:
  // Letra K:
  // Letra L:
  LANCAMENTOS_CONTABEIS: 'Lançamentos contábeis',
  LANCAMENTOS_CONTABEIS_DESCRICAO_PAGE:
  'A partir da seleção de um documento na tabela abaixo, é possível ver, adicionar ou remover lançamentos contábeis vinculados ao mesmo.',
  LISTA_LANCAMENTOS_VINCULADOS: 'Lista de lançamentos vinculados',
  // Letra M:
  MARCADO_ELIMINACAO: 'Marcado para eliminação',

  // Letra N:
  NATUREZA: 'Natureza',
  NUMERO_DOCUMENTO: 'Número do documento',

  // Letra O:
  OBJETO_REFERENCIA: 'Objeto de referência',
  OUTRAS_INFORMACOES: 'Demais dados',
  OCULTAR_ENCERRAMENTO: 'Ocultar encerramento de exercício',

  // Letra P:
  PAGE_DESCRIPTION:
    'Fundamental para cuidar do patrimônio e promover o crescimento, a área contábil fornece informações relevantes para a tomada de decisões no CEJ.',
  PERIODO: 'Período',
  PERIODO_CONTABIL: 'Período contábil',
  PERIODOS_CONTABEIS: 'Períodos contábeis',
  PERIODOS_CONTABEIS_DESCRICAO_PAGE:
  'Selecione o ícone ▼ na coluna "ações" na tabela gerada para editar ou excluir. Utilize o filtro abaixo para ter melhores resultados em sua busca:',
  PLANO_CONTA: 'Plano conta',
  PLANO_CONTA_CODIGO: 'Plano conta código',
  PROCESSAR_FECHAMENTO: 'Processar fechamento de período',
  PROCESSAR_REABERTURA: 'Processar reabertura de período',
  // Letra Q:
  // Letra R:
  REABRIR_EXERCICIO: 'Reabrir exercício',
  RELATORIOS: 'Relatórios',
  RELATORIO_BALANCETE: 'Relatório balancete',
  RELATORIO_BALANCETE_DESCRICAO:
    'Nessa tela você pode visualizar o relatório de balancete bem como gerar sua versão em pdf, para isso selecione os filtros e clique em <strong>Filtrar</strong> ou <strong>Gerar relatório</strong>.',
  RELATORIO_DIARIO: 'Relatório diário',
  RELATORIO_DIARIO_DESCRICAO:
    'Selecione as opções no filtro e clique em <strong>Filtrar</strong> para listar e clique em <strong> Gerar relatório </strong> para gerar o relatório em pdf.',
  RELATORIO_RAZAO: 'Relatório razão',
  RELATORIO_RAZAO_DESCRICAO:
  'Selecione as opções no filtro e clique em <strong>Filtrar</strong> para listar e clique em <strong> Gerar relatório </strong> para gerar o relatório em pdf.',
  RELATORIO_LANCAMENTOS_POR_PERIODO: 'Relatório lançamentos por período',
  RELATORIO_LANCAMENTOS_POR_PERIODO_DESCRICAO:
  'Selecione as opções no filtro para listar os lançamentos por período, clique em <strong> Gerar relatório </strong> para gerar o relatório em pdf.',
  // Letra S:
  SALDO: 'Saldo',
  SALDO_INICIAL: 'Saldo inicial',
  SALDO_FINAL: 'Saldo final',
  SALDO_ANTERIOR: 'Saldo anterior',
  SALDO_TOTAL: 'Saldo total',
  SALDO_ATUAL: 'Saldo atual',
  SALDOS: 'Saldos',
  STATUS: 'Status',
  SELECIONE_TIPO_DOCUMENTO:
    'Selecione tipo de documento origem para prosseguir nos filtros',

  // Letra T:
  TIPO: 'Tipo',
  TIPO_MOVIMENTO: 'Tipo de movimento',
  TIPO_SALDO: 'Tipo de saldo',

  // Letra U:
  // Letra V:
  VALOR: 'Valor',
  VALOR_BRL: 'Valor (R$)',

  // Letra X:
  // Letra Y:
  // Letra Z:
};
