export const Financeiro = {
  // Letra A:
  ABERTURA_FECHAMENTO_PERIODO_FINANCEIRO: 'Opening and closing of the financial period',
  ACOES: 'Actions',
  AGENCIA: 'Agency',
  ALTERAR_CONTA: 'Change account',
  ALTERAR_FORNECEDOR: 'Change supplier',
  ALTERAR_PERIODO: 'Change period',
  AJUSTAR_DADOS_PAGAMENTO: 'Adjust payment information',
  APLICAR_DESCONTO_TAXA: 'Apply discount rate?',
  ATE: 'Until',
  ACOES_E_RELATORIOS: 'Actions and reports:',

  // Letra B:
  BANCO: 'Bank',
  BANCOS: 'Banks',
  BAIXAR_TITULO: 'Download title',
  BANCOS_DESCRICAO:
    'On this screen, you can register, edit, and delete banks. To do so, click on the <strong>Register</strong> button or on <strong>Actions</strong> in the table.',
  BUSCAR_NOME_CODIGO_MATRICULA: 'Search by name or enrollment code',
  BTN_LIBERAR_PORTAL_EXPLICACAO:
    'This option releases the selected account(s) on the student portal.',
  BTN_BLOQUEAR_PORTAL_EXPLICACAO:
    'This option blocks the selected account(s) on the student portal.',

  // Letra C:
  COBRANCAS: 'Charges',
  CARTA_COBRANCAS: 'Charges',
  CARTA_COBRANCAS_DESCRICAO:
    'On this screen, you can generate a collection letter and the delinquent report. To do so, use the filter and the <strong>Print Collection Letter</strong> and <strong>View Delinquents</strong> buttons.',
  CRIAR_FLUXO_CAIXA: 'New posting',
  CADASTRAR_BANCO: 'Register bank',
  CADASTRAR_CONTA: 'Register account',
  CADASTRAR_CONVENIO: 'Register agreement',
  CADASTRAR_DESCONTO: 'Register discount',
  CADASTRAR_FORMA_PAGAMENTO: 'Register payment method',
  CADASTRAR_TAXA: 'Register fee',
  CADASTRAR_PRECO: 'Register price',
  CAIXA: 'Cash',
  CANCELAR_TITULO: 'Cancel title',
  CODIGO: 'Code',
  CONFIGURACOES: 'Settings',
  CONTA: 'Account',
  CONTAS_DESCRICAO:
    'On this screen, you can create, edit, and delete accounts. To do so, use the <strong>Create</strong> button or <strong>Actions</strong> in the table.',
  CONTA_CAIXA: 'Account/Cash',
  CONTA_CAIXA_NF: 'Account/Cash / NF',
  CONTA_CAIXA_PLACEHOLDER: 'Select an account/cash',
  CONTAS: 'Accounts',
  CONTA_VINCULADA: 'Linked account',
  CONTAS_VINCULADAS: 'Linked account(s)',
  CONTAS_RECEBER: 'Accounts receivable',
  CONTRATOS_DESTA_MATRICULA: 'Contracts of this enrollment',
  CONVENIADOS: 'Affiliates',
  CNPJ: 'CNPJ',
  CRIAR_PERIODO_FINANCEIRO: 'Create all the financial periods of the year',

  // Letra D:
  DATAS: 'Dates',
  DATA_ABERTURA: 'Opening date',
  DATA_INICIO: 'Start date',
  DATA_FIM: 'End date',
  DATA_LANCAMENTO: 'Posting date',
  DATA_MOVIMENTO: 'Transaction date',
  DATA_PAGAMENTO: 'Payment date',
  DATA_VENCIMENTO: 'Due date',
  DATA_VENCIMENTO_FINAL: 'Due end date',
  DESCONTO_CONFIMAR_EXCLUSAO:
    'Once confirmed, this action cannot be reversed. Confirm discount deletion?',
  DESCONTO_CRIADO: 'Discount created!',
  DESCONTO_EDITADO: 'Discount edited!',
  DESCONTO_EXCLUIDO: 'Discount deleted!',
  DOCUMENTO: 'Document',
  DOCUMENTO_PLACEHOLDER: 'Type here the document name',
  DESCONTO: 'Discount',
  DESCONTOS: 'Discounts',
  DESCONTOS_DESCRICAO:
    'On this screen, you can list by filter, create, edit, and delete discounts. To do so, use the <strong>filter,</strong> the <strong>Create</strong> button, or the <strong>Actions</strong> button in the table items.',
  DESCRICAO: 'Description',
  DIA_UTIL_CORRIDO: 'Business/Calendar day',
  DATA_RETROATIVA: 'Retroactive date',
  DATA_INICIAL: 'Start date',
  DATA_FINAL: 'End date',
  DATA_VENCIMENTO_AJUSTADA: 'Adjusted due date!',

  // Letra E:
  EVENTO: 'Event',
  ESTORNAR_TITULO: 'Reverse title',
  EXCLUIR_LANCAMENTO: 'Delete launch',
  EDITAR_LANCAMENTO: 'Edit release',
  EMPRESAS: 'Companies',

  // Letra F:
  FINANCEIRO: 'Financial',
  FINANCEIRO_DESCRICAO: 'Select a card below to use the features:',
  FLUXO_CAIXA: 'Cash flow',
  FLUXO_CAIXA_DESCRICAO:
    'This is the cash flow page. Use the filter below to display data. Select the ▼ icon in the "actions" column in the generated table to generate receipts or delete launches.',
  FORNECEDOR: 'Supplier',
  FORNECEDOR_DOCUMENTO: 'Supplier/Doc.',
  FORNECEDOR_PLACEHOLDER: 'Select a supplier',
  FORNECEDORES: 'Suppliers',
  FORMAS_PAGAMENTO: 'Payment methods',
  FORMAS_PAGAMENTO_DESCRICAO:
    'On this screen you can filter payment methods using the filter fields. To create, edit and delete, use the "Register" button and the "Actions" button on the table.',
  FORMA_PAGAMENTO_PLACEHOLDER: 'Select payment method',
  FORMA_PAGAMENTO: 'Payment method',
  FORMA_PAGAMENTO_CONFIRMAR_EXCLUSAO:
    'Do you really want to delete this payment method?',
  FORMA_PAGAMENTO_CRIADA: 'Payment method created!',
  FORMA_PAGAMENTO_EDITADA: 'Payment method edited!',
  FORMA_PAGAMENTO_DELETADA: 'Payment method deleted!',
  FORMAS_PAGAMENTO_OBRIGATORIO:
    'Add at least one payment method to this price.',

  // Letra G:
  GERAR_RECIBO_PAGAMENTO: 'Generate payment receipt',
  GERAR_CONTAS_PREVISTAS: 'Generate forecast accounts',
  GERAR_LANCAMENTOS: 'Generate Releases',

  // Letra H:
  HISTORICO: 'History',
  HISTORICO_FINANCEIRO: 'Financial history',
  HISTORICO_LANCAMENTO: 'Launch history',
  HISTORICO_LANCAMENTOS: 'Launch histories',
  HISTORICO_LANCAMENTOS_DESCRICAO:
    'Create or delete launch histories using the filter below:',
  HISTORICO_PLACEHOLDER: 'Select a history',
  HISTORICO_LANCAMENTO_APLICAR_DESCONTO: 'Discount applic. posting history',

  // Letra I:
  INCLUIR_HISTORICO_ESTOQUE: 'Select the posting history',
  INSIRA_DATA_VENCIMENTO: 'Enter a due date',
  IMPRIMIR_CARTA_COBRANCA: 'Print collection letter',
  IMPRESSAO_DOCUMENTOS: 'Document printing',
  INTEGRACAO_FINANCEIRA_CONTABIL: 'Financial/Accounting integration',
  INTEGRACAO_FINANCEIRA_CONTABIL_DESCRICAO:
    'Here you have access to accounting integration using the filter below.',
  INTEGRACAO_PARAMETRO_FINANCEIRO_CONTABIL: 'Financial accounting parameter',
  INSCRICAO_ESTADUAL: 'State registration',
  IMPOSTOS: 'Taxes',
  IMPOSTOS_DESCRICAO:
    'On this screen, you can view, create, edit, and delete taxes.',

  // Letra J:
  // Letra K:
  // Letra L:
  LANCAMENTOS: 'Launches',
  LANCAMENTO_VENCIMENTO: 'Launche / Maturity',
  LANCAMENTOS_CONTAS_PAGAR: 'Launches: Accounts payable',
  LANCAMENTOS_CONTAS_RECEBER: 'Launches of accounts receivable',
  LISTAR_LANCAMENTOS: 'List launches',

  // Letra M:
  MATRICULA_CONTRATO: 'Enrollment/Contract',
  MES_REFERENCIA: 'Reference month',

  // Letra N:
  NOME: 'Name',
  NOME_CNPJ: 'Search by name or CNPJ',
  NOME_CNPJ_PLACEHOLDER: 'Name or CNPJ without punctuation',
  NOME_FANTASIA: 'Trading name',
  NUMERO_CONTA: 'Account number',

  // Letra O:
  OBSERVACOES: 'Observations',

  // Letra P:
  PLANO_CONTA: 'Account plan',
  PERCENTUAL_RATEIO: 'Percentage of allocation',
  PERIODO_INICIAL: 'Initial period',
  PERIODO_FECHAMENTO: 'Closing period',
  PERIODO_FINAL: 'Final period',
  PERIODO_FINANCEIRO: 'Financial period',
  PERIODO_FINANCEIRO_COMO_USAR_PAGINA:
    'Use the filter below to list the financial periods of the desired account. Select the ▼ icon in the "actions" column in the table to edit or delete the period.',
  PERIODO_FINANCEIRO_CONTA_VINCULADA_OBRIGATORIA:
    'ATTENTION: Linking an account is mandatory to create a financial period. Click "Linked accounts" in the navigation bar in this modal, to add an account to this financial period.',
  PERIODO_FINANCEIRO_CRIADO: 'Financial period created!',
  PERIODO_FINANCEIRO_EDITADO: 'Financial period edited!',
  PERIODO_FINANCEIRO_EXCLUIDO: 'Financial period deleted!',
  PERIODO_FINANCEIRO_ABERTURA: 'Open financial period!',
  PERIODO_FINANCEIRO_REABERTURA: 'Financial period reopened!',
  PERIODO_FINANCEIRO_FECHADO: 'Closed financial period!',
  PERIODOS: 'Periods',
  PRECO: 'Price',
  PRECOS: 'Prices',
  PRECOS_DESCRICAO:
    'On this screen, you can filter prices using the filter, create, edit, and delete prices. To do so, use the <strong>Create</strong> button or <strong>Actions</strong>.',
  PRECO_CONFIRMAR_EXCLUSAO:
    'This action cannot be undone. Confirm price deletion?',
  PRECO_CRIADO: 'Price created!',
  PRECO_EDITADO: 'Price edited!',
  PRECO_EXCLUIDO: 'Price deleted!',
  PRIMEIRA_PARCELA: 'First installment',
  PROCESSAR_ABERTURA_PERIODO: 'Process period opening',
  PROCESSAR_FECHAMENTO_PERIODO: 'Process period closing',
  PROCESSAR_REABERTURA_PERIODO: 'Process period reopening',
  PROCESSAR_PERIODO_FINANCEIRO: 'Edit financial period',
  PARCELA_RESIDUAL: 'Residual installment',

  // Letra Q:
  QUANTIDADE_PARCELAS: 'Number of installments',

  // Letra R:
  RAZAO_SOCIAL: 'Company name',
  RECIBO: 'Receipt',
  RECIBO_NF: 'Receipt/NF',
  REFERENCIA: 'Reference',
  RETORNAR: 'Return',
  REIMPRESSAO: 'Reprint',
  RELATORIOS: 'Reports',
  RELATORIO_ANALISE_RETROATIVA_CONTAS_RECEBER:
    'Accounts receivable retroactive analysis report',
  RELATORIO_ANALISE_RETROATIVA_CONTAS_RECEBER_DESCRICAO:
    'Select the dates in the filter and click the print button to generate the Accounts Receivable Retroactive Analysis Report',
  RELATORIO_DECLARACAO_IMPOSTO_RENDA: 'Income tax return report',
  RELATORIO_DECLARACAO_IMPOSTO_RENDA_DESCRICAO:
    'On this screen it is possible to generate an income tax report in pdf format for one or more students according to the filter selected.',
  RELATORIO_FATURAMENTO: 'Billing report',
  RELATORIO_FATURAMENTO_DESCRICAO:
    'Select the period to generate the billing report',
  // Letra S:
  SALDO_INICIAL: 'Initial balance',
  SALDO_FINAL: 'Final balance',
  SELECIONAR_CONTA: 'Select account',
  SELECIONAR_FORNECEDOR: 'Select supplier',
  SELECIONAR_FORMA_PAGAMENTO: 'Select payment method',
  SELECIONAR_HISTORICO_LANCAMENTO: 'Select launch history',
  SELECIONAR_PERIODO: 'Select period',
  SELECIONE_PARA_HABILITAR_BOTOES:
    'Select at least one item to enable action buttons',
  STATUS: 'Status',
  STATUS_PAGAMENTO: 'Payment status',
  STATUS_PLACEHOLDER: 'Select a status',

  // Letra T:
  TAXA: 'Rate',
  TAXAS: 'Rates',
  TIPO: 'Type',
  TIPO_CONTA: 'Account type',
  TIPO_HISTORICO: 'Historical type',
  TIPO_FLUXO_FINANCEIRO: 'Financial flow type',
  TIPO_VALOR: 'Value type',
  TOTAL: 'Total',
  TRANSFERENCIA: 'Transfer',

  // Letra U:
  // Letra V:
  VALOR: 'Value',
  VALIDADE: 'Validity',
  VALOR_PORCENTAGEM: 'Value (%)',
  VARIAÇÃO: 'Variation',
  VER_INADIMPLENTES: 'View delinquents',

  // Letra X:
  // Letra Y:
  // Letra Z:
};
