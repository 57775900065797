export const FinanceiroLancamentos = {
    // Letra A:
    // Letra B:
    // Letra C:
    CONTAS_PAGAR: 'Bills to pay',
    CONTAS_RECEBER: 'Bills to receive',

    // Letra D:
    // Letra E:
    // Letra F:
    FINANCEIRO: 'Financial',

    // Letra G:
    // Letra H:
    HISTORICO_LANCAMENTOS: 'Financial launches history',

    // Letra I:
    INTEGRACAO_FINANCEIRA_CONTABIL: 'Financial/Accounting Integration',

    // Letra J:
    // Letra K:
    // Letra L:
    // Letra M:
    // Letra N:
    // Letra O:
    // Letra P:
    PAGE_DESCRIPTION: '',
    PAGE_TITLE: 'Financial launches',

    // Letra Q:
    // Letra R:
    // Letra S:
    // Letra T:
    // Letra U:
    // Letra V:
    // Letra X:
    // Letra Y:
    // Letra Z:
}