export const Geral = {
  // Letra A:
  ACAO_AVISO: 'This field is mandatory',
  ACAO_CONFIRMADA: 'Action confirmed successfully',
  ACAO_ERRO: 'Error executing action',
  ACOES: 'Actions',
  ADICIONAR: 'Add',
  AGENCIA: 'Agency',
  ALTERAR_SENHA: 'Change password',
  ANO: 'Year',
  ANO_LETIVO: 'School year',
  ATIVAR: 'Activate',
  ATUALIZAR: 'Update',
  AVISO: 'Notice',
  ATENCAO: 'Attention',
  ADICIONAR_FOTO: 'Add photo',


  // Letra B:
  BANCO: 'Bank',
  BANCO_EDITADO: 'Bank edited!',
  BANCO_EXCLUIDO: 'Bank deleted!',
  BUSCAR: 'Search',
  BUSCA_AVANCADA: 'Advanced search',
  BUSCAR_CONTA: 'Search account',
  BTN_VOLTAR_EXPLICACAO: 'Return to previous page',
  BAIXAR: 'Download',

  // Letra C:
  CADASTRAR: 'Register',
  CANCELAR: 'Cancel',
  CARGA_HORARIA: 'Workload',
  CARREGANDO_DADOS: 'Loading data...',
  CODIGO: 'Code',
  CONFIRMAR: 'Confirm',
  CONFIRMAR_ESTORNO: 'Confirm reversal',
  CONFIRMAR_ESTORNO_ITEM:
    'ATTENTION: This action cannot be undone. Confirm reversal of {item}?',
  CONFIRMAR_EXCLUSAO: 'Confirm deletion',
  CONFIRMAR_EXCLUSAO_ITEM:
    'ATTENTION: This action cannot be undone. Confirm deletion of {item}?',
  CONFIRMACAO_SENHA: 'Password confirmation',
  CONFIRMAR_NOVA_SENHA: 'Confirm new password',
  CONFIRMACAO_SAIR:
    'Are you sure you want to exit? Unsaved changes will be lost',
  CONTA: 'Account',
  CONTA_CAIXA: 'Account/Cash',
  CONTAS: 'Accounts',
  CONFIGURACOES: 'Settings',
  CONTEUDO: 'Content',
  CNPJ: 'CNPJ',
  CURSO: 'Course',
  CURSOS: 'Courses',
  CONFIRMACAO_SENHA_PARA_SALVAR:
    'To save editing changes, please provide your current password',
  CONFIRMAR_ALTERACOES: 'Confirm changes',
  COPIAR: 'Copy',
  TEXTO_COPIADO: 'Copied text',

  // Letra D:
  DADOS_DO_USUARIO: 'User data',
  DADOS_GERAIS: 'General information',
  DATA: 'Date',
  DATAS: 'Dates',
  DATA_DOCUMENTO: 'Document date',
  DATA_INICIO: 'Start date',
  DATA_FIM: 'End date',
  DATA_LANCAMENTO: 'Entry date',
  DATA_MOVIMENTO: 'Movement date',
  DATA_PAGAMENTO: 'Payment date',
  DATA_VENCIMENTO: 'Due date',
  DESCRICAO: 'Description',
  DESCONTO: 'Discount',
  DIGITE_OU_PROCURE: 'Type or search',
  DIGITE_OU_SELECIONE: 'Type or select',
  DISCIPLINA: 'Discipline',
  DOCUMENTO: 'Document',
  DATAS_INVALIDAS_INICIAL_FINAL: 'Start date cannot be longer than end date',
  DESLIGAR: 'Disconnect',
  // Letra E:
  EDITAR: 'Edit',
  EMAIL: 'Email',
  EMAIL_ENVIADO: 'Email sent successfully!',
  ERRO: 'Error',
  ESTRUTURA: 'Structure',
  ESTORNAR: 'Reverse',
  EXIBIR_FILTRO: 'Show filter',
  EXIBIR_RESULTADO: 'Show result',
  EXCLUIR: 'Delete',
  EXIBIR: 'Show',
  EXIBIR_FORMULARIO: 'Show form',
  ESCONDER_FORMULARIO: 'Hide form',


  // Letra F:
  FECHAR: 'Close',
  FILTRAR: 'Filter',
  FILTRAR_POR: 'Filter by',
  FILTRAR_POR_NOME: 'Filter by name',
  FILTRAR_POR_NOME_CPNJ: 'Filter by name or CNPJ',
  FINANCEIRO: 'Financial',
  FORMAS_PAGAMENTO: 'Payment methods',
  FUNCOES: 'Functions',
  FILTRO_TABELAS: 'Table filter',

  // Letra G:
  GENERO: 'Gender',
  GERAR_PERIODOS_FINANCEIROS: 'Generate financial periods',
  GERENCIAMENTO: 'Management',
  GERENCIAR_PERMISSOES: 'Manage permissions',
  GRUPO_TURMA: 'Group class',
  GRUPO_TURMAS: 'Group classes',
  GERAR_RELATORIO: 'Generate report',
  // Letra H:
  HISTORICO: 'History',
  HISTORICO_LANCAMENTO: 'History',
  HOME: 'Home',

  // Letra I:
  IDIOMA: 'Language',
  INSCRICAO_MUNICIPAL: 'Municipal registration',
  INGLES: 'English',
  INFORMACOES_SALVAS: 'Information saved!',
  INATIVAR: 'Deactivate',
  IMPRIMIR: 'Print',
  ITEM_CRIADO: '{item} created successfully!',
  ITEM_EDITADO: '{item} edited successfully!',
  ITEM_ESTORNADO: '{item} reversed successfully!',
  ITEM_DELETADO: '{item} deleted successfully!',
  IMPRIMIR_RELATORIO: 'Print report',

  // Letra J:
  JUROS: 'Interest',

  // Letra K:
  // Letra L:
  LIMPAR_FILTRO: 'Clear filter',
  LIMPAR: 'Clear',
  LIMPAR_PESQUISA: 'Clear search',
  LISTA_VAZIA: 'Empty list',
  LISTAR: 'List',
  LOGIN: 'Login',
  LOREM_IPSULUM: '',

  // Letra M:
  MENSAGEM_CONFIRMAR_ACAO: 'Are you sure you want to perform this action?',
  MARCAR_TODOS: 'Select all',
  MARCAR: 'Select',

  // Letra N:
  NAO: 'No',
  NENHUM_REGISTRO: 'No records',
  NOME: 'Name',
  NOME_FANTASIA: 'Trade name',
  NOME_DO_USUARIO: 'User name',
  NOVA_SENHA: 'New password',
  NUMERO_CONTA: 'Account number',

  // Letra O:
  OBSERVACAO: 'Observation',
  OBSERVACOES: 'Observations',
  OCULTAR_FILTRO: 'Hide filter',
  OK: 'Ok',
  OCORREU_UM_ERRO_INTERNO: 'An internal error has occurred',

  // Letra P:
  PASSO_ANTERIOR: 'Previous step',
  PERIODO: 'Period',
  PESQUISAR: 'Search',
  PORTUGUES: 'Portuguese',
  PROCURAR: 'Search',
  PROXIMO_PASSO: 'Next step',

  // Letra Q:
  // Letra R:
  RAZAO_SOCIAL: 'Company name',
  REDEFINIR_SENHA: 'Reset password',
  RETORNAR: 'Return',
  REGISTROS_POR_PAGINA: 'records per page',
  RESPONDIDO: 'Answered',

  // Letra S:
  SAIR: 'Exit',
  SALVAR: 'Save',
  SELECIONAR_ACAO: 'Select action:',
  SEM_CONTRATOS_CADASTRADOS: 'No registered contracts',
  SEM_DESCRICAO_CADASTRADA: 'No description registered',
  SENHA: 'Password',
  SENHA_CRITERIOS:
    'The password must have at least 6 characters and one lowercase letter!',
  SENHA_ALTERADA: 'Password changed successfully',
  SELECIONAR: 'Select',
  SELECIONAR_TODOS: 'Select all',
  SELECIONAR_TODOS_AVISO:
    'ATTENTION: By clicking any button below with this option selected, the actions will be executed for all the data in the table displayed only on this page',
  SELECIONE: 'Select',
  SELECIONE_MATRICULA_PARA_HABILITAR:
    'Select a registration to enable the field',
  SELECIONE_PARA_EXIBIR_DADOS: 'Select to display data',
  SELECIONE_UMA_OPCAO: 'Select an option',
  SELECIONADO: 'Selected',
  SIM: 'Yes',
  SIGLA: 'Acronym',
  SOMENTE_NUMEROS: 'Enter only numbers',
  STATUS: 'Status',
  SUCESSO: 'Success',
  SEM_DADOS_PARA_O_FILTRO: 'No data for the selected filter',
  SEM_ANEXO: 'No attachment',


  // Letra T:
  TIPO: 'Type',
  TIPO_CONTRATO: 'Contract type',
  TODOS: 'All',
  TOTAL_REGISTROS: 'Total of {count} records',
  TURMAS: 'Classes',
  TURMA: 'Class',

  // Letra U:
  // Letra V:
  VALIDADE: 'Validity',
  VALOR_BRL: 'Value (R$)',
  VALOR_EFETIVO: 'Effective value',
  VALOR_PORCENTAGEM: 'Value (%)',
  VISUALIZAR: 'View',
  VOLTAR: 'Back',
  VOLTAR_LOGIN: 'Back to login',

  // Letra X:
  // Letra Y:
  // Letra Z:
};
