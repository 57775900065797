export const FinanceiroContasPagar = {
    // Letra A:
    // Letra B:
    // Letra C:
    CONTAS_PAGAR: 'Contas à pagar',
    // Letra D:
    DATA_FINAL: 'Data final',
    DATA_INICIAL: 'Data inicial',
    DATA_LANCAMENTO: 'Data do lançamento',
    DATA_PLACEHOLDER: 'dd/mm/aaaa',
    DATA_PAGAMENTO: 'Data do pagamento',
    DATA_VENCIMENTO: 'Data do vencimento',
    DOCUMENTO: 'Documento',
    DOCUMENTO_PLACEHOLDER: 'Digite o nome do documento',

    // Letra E:
    // Letra F:
    // Letra G:
    // Letra H:
    // Letra I:
    // Letra J:
    // Letra K:
    // Letra L:
    // Letra M:
    // Letra N:
    // Letra O:
    OUTRAS_OPCOES_FILTRO: 'Outras opções de filtro:',

    // Letra P:
    PAGE_DESCRIPTION: 'Aqui você pode ver todos os lançamentos em contas à pagar ao utilizar um filtro. Através do botão de ações na linha selecionada na tabela, pode: baixar ou estornar um título, gerar recibo de um pagamento e até mesmo ajustar os dados do pagamento.',
    PAGE_TITLE: 'Contas à pagar',

    // Letra Q:
    // Letra R:
    // Letra S:
    STATUS: 'Status',
    STATUS_PLACEHOLDER: 'Digite ou selecione um status',

    // Letra T:
    // Letra U:
    // Letra V:
    // Letra X:
    // Letra Y:
    // Letra Z:
}