export const Usuarios = {
    // Letra A:
    ADICIONAR_PERFIS: 'Add profiles',

    // Letra B:
    // Letra C:
    CONFIRMAR_EXCLUIR: 'Are you sure you want to delete the user {nome}?',
    CONFIRMAR_EXCLUIR_COM_USUARIO: 'There is a user linked to this person {nome}, do you really want to proceed?',

    // Letra D:
    DADOS_ACESSO: 'Access data',
    DADOS_PESSOAIS: 'Personal data',

    // Letra E:
    // Letra F:
    // Letra G:
    GERENCIAR_PERFIS: 'Manage profiles',

    // Letra H:
    // Letra I:
    ID: 'ID',
    // Letra J:
    // Letra K:
    // Letra L:
    LOGIN: 'Login',

    // Letra M:
    MENSAGEM_CADASTRADO: 'User successfully registered',
    MENSAGEM_EDITADO: 'User successfully edited ',
    MENSAGEM_EXCLUIDO: 'User deleted successfully.',

    // Letra N:
    NOME: 'Name',
    NOVO_USUARIO: 'New user',

    // Letra O:
    // Letra P:
    PAGE_USUARIOS_DESCRICAO: 'Select the icon ▼ in the "actions" column at the generated table to edit, add profiles or delete an user.',
    PESSOA_SEM_USUARIO: 'Profile',
    PERFIS_ASSOCIADOS: 'Associated profiles',
    PERFIL_ASSOCIADO_SUCESSO: 'Profile successfully associated',

    // Letra Q:
    // Letra R:
    // Letra S:
    SEM_PERFIL_ASSOCIADO: 'Without associated profile',

    // Letra T:
    // Letra U:
    USUARIOS: 'Users',

    // Letra V:
    // Letra X:
    // Letra Y:
    // Letra Z:
}