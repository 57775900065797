export const Pessoas = {
  ADICIONAR_PERFIS: "Add profiles",
  BAIRRO: "Neighborhood",
  CADASTRO_CONTATO: "Register contact",
  CADASTRAR_FILIACAO: "Register affiliation",
  CONTATO_REMOVIDO: 'Contact removed!',
  CEP: "Zip Code",
  CODIGO_PESSOA: "Person code",
  CEP_BUSCAR: "Type here for automatic search",
  COMPLEMENTO: "Complement",
  CONTATO: "Contact",
  CONTATO_EXCLUIDO: 'Contact deleted!',
  CONTATO_PRINCIPAL: "Is this the main contact?",
  CONFIRMAR_EXCLUIR: "Are you sure you want to delete the person {nome}?",
  DADOS_ACESSO: "Access data",  
  DATA_NASCIMENTO: "Birth date",
  DADOS_PESSOAIS: "Personal data",
  DATA_EXPEDICAO: "Issued",
  DESCRICAO: "Description",
  EMAIL: "Email",
  ENDERECO: "Address",
  ENDERECO_EXCLUIDO: "Address deleted successfully",
  ENDERECO_PRINCIPAL: "Main address",
  ESTADO: "State",
  ESTADO_CIVIL: "Marital status",
  FILIACAO: "Filiation",
  FOTO: "Photo registration",
  ID: "ID",
  GRAU_PARENTESCO: "Degree of kinship",
  LOGRADOURO: "Address",
  MENSAGEM_EXCLUIDO: "Person deleted successfully.",
  MENSAGEM_CADASTRADO: "Person successfully registered",
  MUNICIPIO: "County",
  NACIONALIDADE: "Nationality",
  NATURALIDADE: "Place of birth",
  NOVA_FILIACAO: "New filiation",
  NOVO_CONTATO: "New contact",
  NOVO_ENDERECO: "New address",
  NOVO_RESPONSAVEL: "New responsible",
  NOME: "Name",
  NUMERO: "Number",
  ORGAO_EXPEDIDOR: "Issuing Agency",
  PAIS: "Country",
  PARENTESCO: "Kinship",
  PESSOA_PARENTESCO:"Kinship",
  PERFIL_ASSOCIADO_SUCESSO: "Profile successfully associated",
  RG: "RG",
  REGISTRO_NASCIMENTO: "Birth registration",
  RELIGIAO: "Religion",
  SOBRENOME: "Last name",
  TIPO_CONTATO: "Contact type",
  TIPO_CONTATO_CELULAR: "Type your mobile number",
  TIPO_CONTATO_COMERCIAL: "Type your comercial number",
  TIPO_CONTATO_EMAIL: "Type your email",
  TIPO_CONTATO_FAX: "Type your fax",
  TIPO_CONTATO_RESIDENCIAL: "Type your residential number",
  TIPO_CONTATO_WEBSITE: "Type the website",
  TIPO_ENTREGA_CORRESPONDENCIA: "Type of correspondence delivery",
  TIPO_ENDERECO: "Type of address",
  TELEFONE: "Phone number",
  SEXO: "Gender",
};
