export const ContabilPlanoContas = {
    // Letra A:
    ACOES: 'Ações',

    // Letra B:
    // Letra C:
    CONTABIL: 'Contábil',

    // Letra D:
    DESCRICAO: 'Descrição',

    // Letra E:
    ESTRUTURAL: 'Estrutural',
    EXPORT: 'Export',

    // Letra F:
    // Letra G:
    // Letra H:
    // Letra I:
    IMPRIMIR_PLANO_CONTAS: 'Imprimir plano de contas',
    IDENTIFICADOR:'Id',
    // Letra J:
    // Letra K:
    // Letra L:
    // Letra M:
    // Letra N:
    // Letra O:
    // Letra P:
    PAGE_DESCRIPTION: 'Selecione o ícone ▼ na coluna "ações" na tabela gerada para editar ou excluir.',
    PAGE_TITLE: 'Plano contas',
    PLANO_CONTA: 'Plano conta',
    PLANO_CONTAS: 'Plano contas',
    PLANO_CONTAS_DESCRICAO: 'Esta é a página de plano contas.',

    // Letra Q:
    // Letra R:
    // Letra S:
    // Letra T:
    TIPO_CONTA: 'Tipo de conta',
    TIPO_NATUREZA: 'Tipo de natureza',

    // Letra U:
    // Letra V:
    // Letra X:
    // Letra Y:
    // Letra Z:
}