export const AcademicoMatriculas = {
  // Letter A:
  ACADEMICO: 'Academic',
  ACOES: 'Actions',
  ADICIONAR_DESCONTO: 'Add discount',
  ADICIONAR_PESSOA_TABELA: 'Add person to table',
  ALUNO: 'Student',
  ATENCAO: 'Attention!',

  // Letter B:
  BAIXAR_CERTIFICADO_ENSINO_FUNDAMENTAL: 'Download Elementary School Certificate',
  BAIXAR_CERTIFICADO_ENSINO_MEDIO: 'Download High School Certificate',

  // Letter C:
  CODIGO_MATRICULA: 'Enrollment Code',
  CONFIRMAR_ATIVACAO: 'Are you sure you want to activate {person}\'s enrollment?',
  CONFIRMAR_INATIVACAO: 'Are you sure you want to deactivate {person}\'s enrollment?',
  CONTRATOS: 'Contracts',
  CURSO_TURMA: 'Course/Class',

  // Letter D:
  DADOS_ALUNO: 'Student information',
  DADOS_CONTATO: 'Contact information',
  DADOS_ENDERECO: 'Address information',
  DADOS_RESPONSAVEIS: 'Guardian information',
  DESCONTO: 'Discount',
  DESCONTOS: 'Discounts',
  DIGITE_OU_SELECIONE: 'Type or select',
  DIGITE_PARA_FILTRAR: 'Type to filter',
  DIGITE_MATRICULA_ALUNO: 'Enter student\'s enrollment',

  // Letter E:
  ENDERECOS: 'Addresses',
  EDITAR_MATRICULA: 'Edit enrollment',
  EDITAR_MATRICULA_PAGE_DESCRICAO: 'On this page, you can update enrollment details',
  
  // Letter F:
  FILTRO_LABEL: 'Filter by',
  FILTRO_PLACEHOLDER: 'enter enrollment code or student name',

  // Letter G:
  GERAR_HISTORICO_ESCOLAR: 'Generate school history',
  GERAR_BOLETIM: 'Generate bulletin',

  // Letter H:
  // Letter I:
  IDIOMA: 'Language',
  IMPRIMIR_ATESTADO_FREQUENCIA: 'Print attendance certificate',
  IMPRIMIR_BOLETIM_NOTAS_PARCIAIS: 'Print partial report card',
  IMPRIMIR_ATESTATO_DE_FREQUENCIA_E_MATRICULA: 'Print attendance and anrollment certificate',
  IMPRIMIR_HISTORICO_ESCOLAR: 'Print school history',

  // Letter J:
  // Letter K:
  // Letter L:
  LISTAR_CONTRATOS: 'List contracts',

  // Letter M:
  MATRICULA: 'Enrollment',
  MATRICULA_ACADEMICO_EXPLICACAO: 'If any button is disabled, it indicates that you need to select a contract in the table first. For example, to print a report card, you need to click on the desired contract first.',
  MENSAGEM_CADASTRADO: 'Enrollment successfully registered',
  MATRICULA_PAGE_DESCRICAO_NOVO: 'Creating an enrollment is simple! Fill out the form below and then click the "save" button at the end:',
  MATRICULA_SEM_VINCULO: 'You cannot register an enrollment without a link',
  MATRICULAS: 'Enrollments',
  MATRICULA_ATIVADA: 'Enrollment successfully activated!',
  MATRICULAS_DESCRICAO_PAGE: 'Use the filter below for better results. Click the ▼ icon in the "actions" column in the generated table to activate, deactivate, or edit an enrollment.',
  MATRICULA_INATIVADA: 'Enrollment successfully deactivated!',
  MATRICULA_CONTRATO_EXPLICACAO: 'On this page, you can view student contracts and generate reports by clicking "actions" on the desired row.',
  
  // Letter N:
  NENHUM_MATRICULA_REGISTRADA: 'No enrollments registered.',
  NENHUM_CONTRATO_REGISTRADO: 'No contracts registered',
  NOME: 'Name',
  NOVO_ENDERECO: 'New address',
  NOVA_MATRICULA: 'New enrollment',
  NOVA_PESSOA: 'New person',

  // Letter O:
  OBSERVACAO: 'Observation',
  OBSERVACOES: 'Observations',
  OBSERVACOES_LABEL: 'Enter observations related to this student below:',

  // Letter P:
  PERCENTUAL: 'Percentage',
  PERIODO_LETIVO: 'School term',
  PERIODO_LETIVO_DESCRICAO: 'On this screen you can create, edit and delete school terms.',
  PESSOA: 'Person',

  // Letter Q:
  // Letter R:
  REMOVER_DESCONTO: 'Remove discount',
  RESPONSAVEL_POR: 'Responsible for',
  REMATRICULAS: 'Re-enrollments',
  REMATRICULAS_DESCRICAO_PAGE: 'On this page, you can perform re-enrollments for a student or a class. Use the filter to list contracts and perform re-enrollments. Use the buttons to print contracts, re-enrollment forms, and activate listed contracts.',

  // Letter S:
  SELECIONAR_DESCONTO: 'Select a discount',
  SELECIONE_PESSOA: 'Select a person',
  STATUS: 'Status',
  STATUS_CONTRATO: 'Status of the contract',
  // Letter T:
  TURMAS: 'Classes',

  // Letter U:
  // Letter V:
  VINCULO_COM_ALUNO: 'Student link',
  VINCULOS_COMPONENTE_DESCRICAO: 'Add at least one educational and financial guardian (who may or may not have a parental link to the student) for the enrollment to be valid.',
  VALOR: 'Value',

  // Letter X:
  // Letter Y:
  // Letter Z:
};
