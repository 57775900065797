export const FichaSaude = {
    ACEITAR_TERMOS: 'Aceitar termos',
    ALERGIAS: 'Alergias',
    ALERGIA_CONFIRMACAO: 'Tem alergia?',
    ALERGIA_QUAIS: 'Em caso afirmativo, qual(is) tipo(s) de alergia?',
    ATUALIZAR_FICHA: 'Atualizar Ficha de Saúde',
    DECLARAR_VERDADEIRO: 'Declaro serem verdadeiras as informações acima e, em caso de emergência autorizo encaminharem meu(minha) filho(a) ao Pronto Socorro. No caso de possuir laudo clínico que interfere na aprendizagem, me comprometo a apresentar uma cópia para a escola',
    DEFICIENCIAS: 'Deficiências',
    DEFICIENCIAS_CONFIRMACAO: 'Tem alguma deficiência?',
    DEFICIENCIAS_DESCRICAO_CID: 'Descrição ou CID',
    DEFICIENCIAS_ESPECIFIQUE: 'Em caso afirmativo, especifique o tipo de deficiência ou informe o CID: ',
    DEFICIENCIAS_SELECIONE: 'Em caso afirmativo, selecione o(s) tipo(s):',
    DEFICIENCIA_AUDITIVA: 'Auditiva',
    DEFICIENCIA_FISICA: 'Física',
    DEFICIENCIA_INTELECTUAL: 'Intelectual',
    DEFICIENCIA_MULTIPLA: 'Múltipla',
    DEFICIENCIA_PSICOSSOCIAL: 'Psicossocial',
    DEFICIENCIA_VISUAL: 'Visual',
    FICHA_SAUDE: 'Ficha de saúde',
    INTOLERANCIAS: 'Intolerâncias',
    INTOLERANCIAS_GLUTEN: 'Tem intolerância à glúten?',
    INTOLERANCIAS_LACTOSE: 'Tem intolerância à lactose?',
    MEDICACOES: 'Medicações',
    MEDICACOES_CONFIRMACAO: 'Usa medicamento contínuo?',
    MEDICACOES_QUAIS: 'Em caso afirmativo, qual(is)?',
    MEDICACOES_MOTIVO: 'Por que toma esse remédio?',
    OBSERVACOES: 'Observações',
    OBSERVACOES_OUTRAS: 'Outras observações sobre a saúde e/ou desenvolvimento do(a) aluno(a):',
    PLANO_SAUDE: 'Plano de Saúde',
    PLANO_SAUDE_NUMERO_CARTEIRINHA: 'Número da carteirinha',
    PLANO_SAUDE_PLACEHOLDER: 'Digite o nome do seu plano de saúde',
    PLANO_SAUDE_NUMERO_CARTEIRINHA_PLACEHOLDER: 'Digite o número da carteirinha',
    REFLUXO: 'Tem refluxo?',
    SOBRE: 'Neste questionário solicitamos informações sobre a saúde e desenvolvimento do aluno(a). Esta ficha fará parte dos documentos escolares de cada aluno e ressaltamos que é muito importante serem preenchidas todas as informações solicitadas, visando um atendimento mais eficiente por parte da escola ou, em caso de necessidade, pelos socorristas/hospital.',
    VISUALIZAR_FICHA: 'Visualizar ficha de saúde',
}