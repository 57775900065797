import AutenticacaoService from '@/common/services/autenticacao/autenticacao.service';
import PessoaService from '@/common/services/pessoa/pessoa.service';
import JwtService from '@/common/utils/jwt';
import helpers from '@/common/utils/helpers';

export const VERIFICAR_AUTENTICACAO = 'verificarAunteticacao';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const LIMPAR_DADOS_AUTENTICACAO = 'limparDadosAutenticacao';
export const SALVAR_DADOS_AUTENTICACAO = 'salvarDadosAuntenticacao';
export const SALVAR_FOTO_USUARIO = 'SalvarFotoUsuario';
export const SALVAR_ACESSOS = 'autenticarPermissao';
export const DEFINIR_PERFIL = 'definirPerfil';

const state = {
  erros: null,
  user: {},
  estaLogado: !!JwtService.buscarToken(),
  acessos: [],
  admin: false,
};

const getters = {
  usuarioLogado(state) {
    return state.usuario;
  },
  estaLogado(state) {
    return state.estaLogado;
  },
  acessos(state) {
    return state.acessos;
  },
  admin(state) {
    return state.admin;
  },
};

const actions = {
  async [LOGIN](context, form) {
    let {
      data: { data },
    } = await AutenticacaoService.logar(form);

    if (data) {
      context.commit(SALVAR_DADOS_AUTENTICACAO, data);
    }

    try {
      let {
        data: { documento },
      } = await PessoaService.getFoto(data.pessoaId);

      if (documento) {
        context.commit(
          SALVAR_FOTO_USUARIO,
          'data:image/jpeg;base64,' + documento
        );
      }
    } catch (error) {
      console.log(error);
    }

    return data;
  },
  async [LOGOUT](context) {
    const _token = JwtService.buscarToken();
    return await AutenticacaoService.invalidarToken(_token).then(
      async (result) => {
        if (result.data.success) {
          context.commit(LIMPAR_DADOS_AUTENTICACAO);
        }
      }
    );
  },
  async [VERIFICAR_AUTENTICACAO](context) {
    const _token = JwtService.buscarToken();

    if (_token === null || _token === undefined) {
      context.commit(LIMPAR_DADOS_AUTENTICACAO);
      return;
    }
    return await AutenticacaoService.validarToken(_token)
      .then(async (result) => {
        if (!result.data.data.valido) {
          context.commit(LIMPAR_DADOS_AUTENTICACAO);
        } else {
          context.commit(DEFINIR_PERFIL, helpers.decodificarJwt(_token));
          context.commit(SALVAR_ACESSOS, result.data.data.permissoes);
        }
      })
      .catch((err) => {
        if (!err.response) {
          context.commit(LIMPAR_DADOS_AUTENTICACAO);
          return;
        }
        if (err.response.status == 401)
          context.commit(LIMPAR_DADOS_AUTENTICACAO);
      });
  },
};

const mutations = {
  [SALVAR_DADOS_AUTENTICACAO](state, usuario) {
    state.estaLogado = true;
    state.usuario = usuario;
    state.errors = {};
    if (usuario.accessToken) JwtService.salvarToken(usuario.accessToken);
    if (usuario.nome) JwtService.salvarNome(usuario.nome);
    if (usuario.sobreNome) JwtService.salvarSobrenome(usuario.sobreNome);
    if (usuario.email) JwtService.salvarEmail(usuario.email);
  },
  [LIMPAR_DADOS_AUTENTICACAO](state) {
    state.estaLogado = false;
    state.usuario = {};
    state.errors = {};
    JwtService.excluirToken();
    JwtService.excluirNome();
    JwtService.excluirSobrenome();
    JwtService.excluirEmail();
    JwtService.excluirFoto();
  },
  [SALVAR_ACESSOS](state, acessos) {
    state.acessos = acessos;
  },
  [DEFINIR_PERFIL](state, token) {
    state.admin = token.role == 'admin';
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
