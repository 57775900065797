export const ShortcutCards = {
    // Letra A:
    ACADEMICO: 'Acadêmico',
    AULAS: 'aulas',
    AVALIACOES: 'avaliações',
    ADITIVOS_CONTRATO: 'Aditivos de contrato',

    // Letra B:
    BANCOS: 'bancos',
    BOLETINS: 'boletins',

    // Letra C:
    CAIXA: 'caixa',
    COBRANCAS: 'cobranças',
    CONFIGURACOES_SISTEMA: 'configurações sistema',
    CONTABIL: 'contábil',
    CONTAS: 'contas',
    CONTRATOS: 'contratos',
    CONVENIADOS: 'conveniados',
    COORDENACAO: 'coordenação',
    CURSOS: 'cursos',
    CURSOS_TURMAS: 'cursos e turmas',

    // Letra D:
    DIARIO_CLASSE: 'diário de classe',
    DISCIPLINAS: 'disciplinas',
    DESCONTOS: 'descontos',
    DEPOSITOS: 'depósitos',
    DO_CEJ: 'do CEJ',
    DOCENTES_DO_PERIODO: 'Docentes do período',
    DOCUMENTO_PADRAO: 'Documento padrão',
    // Letra E:
    ESTOQUE: 'estoque',
    ESTUDANTES: 'estudantes',

    // Letra F:
    FATURAMENTO: 'faturamento',
    FECHAMENTO_CONTABIL: 'fechamento contábil',
    FREQUENCIAS: 'frequências',
    FINANCEIRO: 'financeiro',
    FORMA_PAGAMENTO: 'formas de pagamento',
    FORNECEDORES: 'fornecedores',
    FUNCIONALIDADES_ALUNOS: 'Funcionalidades relativas aos alunos',
    FUNCIONALIDADES_GERAIS: 'Funcionalidades gerais',
    FUNCIONALIDADES_TURMAS: 'Funcionalidades relativas as turmas',
    FUNCIONARIOS: 'funcionários',

    // Letra G:
    GERENCIAR: 'gerenciar',
    GERENCIAR_ESTRUTURAS: 'gerenciar estruturas',
    GERENCIAR_ESTRUTURAS_DE: 'gerenciar estruturas de',
    GERENCIADOR_TURMAS: 'gerenciador de turmas',
    GRUPOS_TURMA: 'grupos de turma',

    // Letra H:
    HISTORICO_ESCOLAR: 'histórico escolar',
    HISTORICOS: 'históricos',

    // Letra I:
    INSTITUICOES_DE_ENSINO: 'instituições de ensino',
    IR_PARA: 'ir para',
    IR_PARA_FECHAMENTO: 'ir para fechamento',
    IR_PARA_TODAS: 'ir para todas as',

    // Letra J:
    // Letra K:
    // Letra L:
    LANCAMENTOS: 'lançamentos',
    LANCAMENTOS_CONTABEIS: 'lançamentos contábeis',
    LANCAMENTOS_NF: 'lançamentos NF',

    // Letra M:
    MATRICULAS: 'matrículas',

    // Letra N:
    NOTAS: 'notas',

    // Letra O:
    OUTRAS_INSTITUICOES: 'outras instituições',

    // Letra P:
    PAINEL_PROFESSOR: 'painel do professor',
    PERFIS: 'Perfis',
    PESSOAS: 'pessoas',
    PERIODOS_CONTABEIS: 'períodos contábeis',
    PERIODO_LETIVO: 'período letivo',
    PLANO_CONTAS: 'plano de contas',
    PRECOS: 'preços',
    PRODUTOS: 'produtos',
    PROFESSORES: 'professores',

    // Letra Q:
    // Letra R:
    RELATORIO_BALANCETE:'relatório balancete',
    RELATORIO_DIARIO:'relatório diário',
    RELATORIOS: 'relatórios',
    RH: 'RH',
    REMATRICULAS: 'Rematrículas',

    // Letra S:
    SUPRIMENTOS: 'suprimentos',

    // Letra T:
    TAXAS: 'taxas',
    TIPOS_AVALIACAO: 'tipos de avaliação',
    TURMAS: 'turmas',

    // Letra U:
    USUARIOS: 'Usuários',

    // Letra V:
    // Letra X:
    // Letra Y:
    // Letra Z:
}