export const Rh = {
  // Letra A:
  ACOES: 'Ações',
  ANEXO: 'Anexo',

  // Letra B:
  BAIXAR_ANEXO: 'Baixar anexo',
  // Letra C:
  CADASTRAR_FUNCIONARIO: 'Cadastrar funcionário',
  CADASTRAR_MATRICULA: 'Cadastrar matrícula',
  CADASTRAR_PESSOA: 'Cadastrar pessoa',
  CADASTRAR_PROFESSOR: 'Cadastrar professor',
  CONFIGURACOES: 'Configurações',

  // Letra D:
  DOCUMENTOS: 'Documentos',
  DATA_PROTOCOLO: 'Data de criação',
  // Letra E:
  // Letra F:
  FUNCIONARIOS: 'Funcionários',
  FUNCIONARIO_DESCRICAO:
    'Nessa tela você pode gerenciar os funcionário, criar, editar e trazer a lista por filtro.',

  // Letra G:
  // Letra H:
  // Letra I:
  INFORMACOES_PESSOAIS: 'Informações pessoais',
  INFORMACOES_BANCARIAS: 'Informações bancárias',
  // Letra J:
  // Letra K:
  // Letra L:
  // Letra M:
  MATRICULA: 'Matrícula',
  // Letra N:
  NOME: 'Nome',
  NENHUM_REGISTRO: 'Nenhum registro',
  // Letra O:
  // Letra P:
  PESSOAS: 'Pessoas',
  PESSOAS_DESCRICAO:
    'Nessa tela você pode buscar, criar, editar e cadastrar pessoas. Para isso utilize o botão do filtro, o botão <strong> Cadastrar </strong> ou o botão de <strong> Ações </strong> nas linhas da tabela.',
  PROFESSORES: 'Professores',
  PROTOCOLOS: 'Protocolos',
  PROTOCOLOS_DESCRICAO:
    'Nessa tela você pode visualizar os protocolos abertos e responde-los.',

  // Letra Q:
  // Letra R:
  RECURSOS_HUMANOS: 'Recursos humanos',
  RESPOSTA: 'Resposta',
  RESPONDER_PROTOCOLO: 'Responder',

  // Letra S:
  SALVAR_CADASTRAR_MATRICULA: 'Salvar e cadastrar matrícula',
  SITUACAO: 'Situação',
  SOLICITANTE: 'Solicitante',

  // Letra T:
  TITULO_PROTOCOLO: 'Título',
  TIPO_FUNCIONARIO: 'Tipo de funcionário',
  TITULACOES: 'Titulações',
  // Letra U:
  // Letra V:
  VISUALIZAR_MATRICULA: 'Visualizar matrícula',

  // Letra X:
  // Letra Y:
  // Letra Z:
};
