// action types
export const ACRESCENTAR_BREADCRUM = 'acrescentarBreadcrumb';

// mutation types
export const DEFINIR_BREADCRUMB = 'definirBreadcrumb';
export const ADICIONAR_BREADCRUMB = 'adicionarBreadcrumb';

export default {
  state: {
    breadcrumbs: [],
  },
  getters: {
    /**
     * Get list of breadcrumbs for current page
     * @param state
     * @returns {*}
     */
    breadcrumbs(state) {
      return state.breadcrumbs;
    },

    /**
     * Get the page title
     * @param state
     * @returns {*}
     */
    tituloPagina(state) {
      let last = state.breadcrumbs[1];
      if (last && last.titulo) {
        return last.titulo;
      }
    },
  },
  actions: {
    /**
     * Set the breadcrumbs list
     * @param state
     * @param payload
     */
    [DEFINIR_BREADCRUMB](state, payload) {
      state.commit(DEFINIR_BREADCRUMB, payload);
    },

    /**
     * Add breadcrumb
     * @param state
     * @param payload
     */
    [ADICIONAR_BREADCRUMB](state, payload) {
      if (typeof payload === 'object') {
        payload.forEach((item) => state.commit(ACRESCENTAR_BREADCRUM, item));
      } else {
        state.commit(ACRESCENTAR_BREADCRUM, payload);
      }
    },
  },
  mutations: {
    [ACRESCENTAR_BREADCRUM](state, payload) {
      state.breadcrumbs = [...state.breadcrumbs, payload];
    },
    [DEFINIR_BREADCRUMB](state, payload) {
      state.breadcrumbs = payload;
    },
  },
};
