export const AutentificacaoModalEsqueceuSenha = {
    // Letra A:
    ALTERAR_SENHA: "Change your password",

    // Letra B:
    // Letra C:
    // Letra D:
    // Letra E:
    EMAIL: 'Email',
    ESQUECEU_SENHA: "Did you forgot your password?",
    EXPLICACAO: 'Don\'t worry! Insert your email in the field down below and you\'ll receive a link to recover your account:',

    // Letra F:
    // Letra G:
    // Letra H:
    // Letra I:
    // Letra J:
    // Letra K:
    // Letra L:
    // Letra M:
    // Letra N:
    // Letra O:
    // Letra P:
    // Letra Q:
    // Letra R:
    // Letra S:
    // Letra T:
    // Letra U:
    // Letra V:
    // Letra X:
    // Letra Y:
    // Letra Z:
}