export const Contabil = {
  // Letter A:
  ACOES: 'Actions',
  ADICIONAR_LANCAMENTO: 'Add entry',
  ANO_CONTABIL: 'Accounting year',

  // Letter B:
  // Letter C:
  CADATRAR_ANO_CONTABIL: 'Register accounting year',
  CADASTRAR_LANCAMENTO_CONTABIL: 'Create accounting entry',
  CADASTRAR_SALDO: 'Create balance',
  CATEGORIA: 'Category',
  CRIAR_PERIODO_CONTABIL: 'Create accounting period',
  CONTA: 'Account',
  CONTA_CONTABIL: 'Accounting account',
  CONTABIL: 'Accounting',

  // Letter D:
  DADOS_DOCUMENTO: 'Document data',
  DADOS_PERIODO: 'Period data',
  DATA_INICIO: 'Start date',
  DATA_FIM: 'End date',
  DATA_MOVIMENTO: 'Movement date',
  DATA_DOCUMENTO: 'Document date',
  DESCRICAO: 'Description',
  DESCRICAO_LANCAMENTO: 'Entry description',
  DOCUMENTO: 'Document',
  DOCUMENTO_ORIGEM: 'Source document',
  DETALHAR: 'Details',
  
  // Letter E:
  EXPORTAR_LANCAMENTOS_CONTABEIS: 'Exportar lançamentos contábeis',
  // Letter F:
  FECHAMENTO_CONTABIL: 'Accounting closure',

  // Letter G:
  // Letter H:
  // Letter I:
  ITEM: 'Item',
  // Letter J:
  // Letter K:
  // Letter L:
  LANCAMENTOS_CONTABEIS: 'Accounting entries',
  LANCAMENTOS_CONTABEIS_DESCRICAO_PAGE:
    'From the selection of a document in the table below, it is possible to view, add, or remove accounting entries linked to it.',
  LISTA_LANCAMENTOS_VINCULADOS: 'List of linked entries',

  // Letter M:
  MARCADO_ELIMINACAO: 'Marked for deletion',

  // Letter N:
  NATUREZA: 'Nature',
  NUMERO_DOCUMENTO: 'Document number',

  // Letter O:
  OBJETO_REFERENCIA: 'Reference object',
  OUTRAS_INFORMACOES: 'Other information',
  OCULTAR_ENCERRAMENTO: 'Hide Year-End Closing',

  // Letter P:
  PAGE_DESCRIPTION:
    'Fundamental to take care of assets and promote growth, the accounting area provides relevant information for decision-making at CEJ.',
  PERIODO: 'Period',
  PERIODO_CONTABIL: 'Accounting period',
  PERIODOS_CONTABEIS: 'Accounting periods',
  PERIODOS_CONTABEIS_DESCRICAO_PAGE:
    'Select the ▼ icon in the "actions" column in the generated table to edit or delete. Use the filter below for better search results:',
  PLANO_CONTA: 'Chart of accounts',
  PROCESSAR_FECHAMENTO: 'Processar fechamento de período',
  PROCESSAR_REABERTURA: 'Processar reabertura de período',

  // Letter Q:
  // Letter R:
  REABRIR_EXERCICIO: 'Reopen fiscal year',
  RELATORIOS: 'Reports',
  RELATORIO_LANCAMENTOS_POR_PERIODO: 'Postings by period report',
  RELATORIO_LANCAMENTOS_POR_PERIODO_DESCRICAO:
    'Select options in the filter to list transactions by period, click on <strong>Generate Report</strong> to generate the PDF report.',
  RELATORIO_BALANCETE_DESCRICAO:
    'On this screen, you can view the balance sheet report and generate its PDF version. To do so, select the filters and click <strong>Filter</strong> or <strong>Generate Report</strong>.',
  RELATORIO_DIARIO_DESCRICAO:
    'Select options in the filter and click <strong>Filter</strong> to list, and click <strong>Generate Report</strong> to generate the PDF report.',
  RELATORIO_RAZAO: 'Ledger Report',
  RELATORIO_RAZAO_DESCRICAO:
    'Select options in the filter and click <strong>Filter</strong> to list, and click <strong>Generate Report</strong> to generate the PDF ledger report.',

  // Letter S:
  SALDO: 'Balance',
  SALDO_INICIAL: 'Initial balance',
  SALDO_FINAL: 'Final balance',
  SALDOS: 'Balances',
  STATUS: 'Status',
  SELECIONE_TIPO_DOCUMENTO:
    'Select the source document type to proceed with the filters',

  // Letter T:
  TIPO: 'Type',
  TIPO_MOVIMENTO: 'Movement type',
  TIPO_SALDO: 'Balance type',

  // Letter U:
  // Letter V:
  VALOR: 'Value',
  VALOR_BRL: 'Value (R$)',

  // Letter X:
  // Letter Y:
  // Letter Z:
};
