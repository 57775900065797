export const FinanceiroContasPagar = {
    // Letra A:
    // Letra B:
    // Letra C:
    CONTAS_PAGAR: 'Bills to pay',

    // Letra D:
    
    DATA_FINAL: 'Final date',
    DATA_INICIAL: 'Initial date',
    DATA_LANCAMENTO: 'Data do lançamento',
    DATA_PLACEHOLDER: 'dd/mm/aaaa',
    DATA_PAGAMENTO: 'Payment date',
    DATA_VENCIMENTO: 'Expiration date',
    DOCUMENTO: 'Document',
    DOCUMENTO_PLACEHOLDER: 'Type the name of the document',

    // Letra E:
    // Letra F:
    // Letra G:
    // Letra H:
    // Letra I:
    // Letra J:
    // Letra K:
    // Letra L:
    // Letra M:
    // Letra N:
    // Letra O:
    OUTRAS_OPCOES_FILTRO: "Other filter options:",

    // Letra P:
    PAGE_DESCRIPTION: 'Here you can see all postings on accounts payable by using a filter. Through the action button on the selected line in the table, you can: download or reverse a title, generate a payment receipt, and even adjust payment information.',
    PAGE_TITLE: 'Accounts payable',

    // Letra Q:
    // Letra R:
    // Letra S:
    STATUS: 'Status',
    STATUS_PLACEHOLDER: 'Type or select a status',

    // Letra T:
    // Letra U:
    // Letra V:
    // Letra X:
    // Letra Y:
    // Letra Z:
}