export const Financeiro = {
  // Letra A:
  ABERTURA_FECHAMENTO_PERIODO_FINANCEIRO: 'Abertura e fechamento de período financeiro',
  ACOES: 'Ações',
  ADICIONAR_CONTATO: 'Adicionar contato',
  ADICIONAR_ENDERECO: 'Adicionar endereço',
  AGENCIA: 'Agência',
  ALTERAR_CONTA: 'Alterar conta',
  ALTERAR_FORNECEDOR: 'Alterar fornecedor',
  ALTERAR_PERIODO: 'Alterar período',
  AJUSTAR_DADOS_PAGAMENTO: 'Ajustar dados de pagamento',
  APLICAR_DESCONTO_TAXA: 'Aplicar desconto taxa?',
  ATE: 'Até',
  ACOES_E_RELATORIOS: 'Ações e relatórios:',

  // Letra B:
  BANCO: 'Banco',
  BANCOS: 'Bancos',
  BANCOS_DESCRICAO:
    'Nessa tela é possível cadastrar, editar e excluir bancos, para isso clique no botão <strong>Cadastrar</strong> ou em <strong>Ações</strong> na tabela.',
  BAIXAR_TITULO: 'Baixar título',
  BUSCAR_NOME_CODIGO_MATRICULA: 'Buscar por nome ou código do aluno',
  BTN_LIBERAR_PORTAL_EXPLICACAO:
    'Esta opção libera a(s) conta(s) selecionada(s) no portal do aluno.',
  BTN_BLOQUEAR_PORTAL_EXPLICACAO:
    'Esta opção bloqueia a(s) conta(s) selecionada(s) no portal do aluno.',

  // Letra C:
  COBRANCA: 'Cobrança',
  COBRANCAS: 'Cobranças',
  CARTA_COBRANCAS: 'Carta de cobrança',
  CARTA_COBRANCAS_DESCRICAO:
    'Nessa tela é possível gerar carta de cobrança e o relatório de inadimplentes, para isso utilize o filtro e os botões <strong> Imprimir carta de cobrança </strong> e <strong> Ver inadimplentes. </strong>',
  CRIAR_FLUXO_CAIXA_SUCESSO: 'Fluxo de caixa criado com sucesso.',
  CADASTRAR_BANCO: 'Cadastrar banco',
  CADASTRAR_CONTA: 'Cadastrar conta',
  CADASTRAR_CONVENIO: 'Cadastrar convênio',
  CADASTRAR_DESCONTO: 'Cadastrar desconto',
  CADASTRAR_FORMA_PAGAMENTO: 'Cadastrar forma de pagamento',
  CADASTRAR_TAXA: 'Cadastrar taxa',
  CADASTRAR_PRECO: 'Cadastrar preço',
  CAIXA: 'Caixa',
  CANCELAR_TITULO: 'Cancelar título',
  CEP: 'CEP',
  CODIGO: 'Código',
  CONFIGURACOES: 'Configurações',
  CONFIRMAR_EXCLUIR_CONTATO:
    'Tem certeza que deseja excluir o registro de contato?',
  CONFIRMAR_EXCLUIR_ENDERECO:
    'Tem certeza que deseja excluir o registro de endereço?',
  CONFIRMAR_EXCLUIR_PERIODO_CONTABIL:
    'Tem certeza que deseja excluir o período contábil?',
  CONTA: 'Conta',
  CONTA_CAIXA: 'Conta/Caixa',
  CONTA_CAIXA_NF: 'Conta/Caixa / NF',
  CONTA_CAIXA_PLACEHOLDER: 'Selecione uma conta/caixa',
  CONTA_VINCULADA: 'Conta vinculada',
  CONTAS_VINCULADAS: 'Conta(s) vinculada(s)',
  CONTAS: 'Contas',
  CONTAS_DESCRICAO:
    'Nessa tela é possível criar, editar e excluir contas, para isso utilize os botões <strong>Cadastrar</strong> ou <strong>Ações</strong> na tabela.',
  CONTAS_RECEBER: 'Contas à receber',
  CONTATO: 'Contato',
  CONTATO_PLACEHOLDER: 'ex: (41) 1111-1111',
  CONTRATOS_DESTA_MATRICULA: 'Contratos desta matrícula',
  CONVENIADOS: 'Conveniados',
  CONVENIADOS_DESCRICAO_PAGE:
    'A lista abaixo contém todos os conveniados registrados. Utilize o botão azul no topo a direita para criar um novo conveniado.',
  CNPJ: 'CNPJ',
  CRIAR_PERIODO_FINANCEIRO: 'Criar todos os períodos financeiros do ano',
  // Letra D:
  DADOS: 'Dados',
  DATAS: 'Datas',
  DATA_ABERTURA: 'Data abertura',
  DATA_INICIO: 'Data início',
  DATA_FIM: 'Data fim',
  DATA_LANCAMENTO: 'Data lançamento',
  DATA_MOVIMENTO: 'Data movimento',
  DATA_PAGAMENTO: 'Data pagamento',
  DATA_VENCIMENTO: 'Data vencimento',
  DATA_VENCIMENTO_FINAL: 'Data vencimento final',
  DESCONTO_CONFIMAR_EXCLUSAO:
    'Uma vez confirmado não será possível reverter esta ação. Confirma a exclusão do desconto?',
  DESCONTO_CRIADO: 'Desconto criado!',
  DESCONTO_EDITADO: 'Desconto editado!',
  DESCONTO_EXCLUIDO: 'Desconto excluído!',
  DESCONTO: 'Desconto',
  DESCONTOS: 'Descontos',
  DESCONTOS_DESCRICAO:
    'Nessa tela é possível listar por filtro, cadastrar, editar e deletar descontos. Para isso utilize o <strong> filtro, </strong> o botão de <strong> Cadastrar </strong> ou o botão de <strong>Ações</strong> dos itens da tabela.',
  DESCRICAO: 'Descrição',
  DIA_UTIL_CORRIDO: 'Dia útil/corrido',
  DATA_RETROATIVA: 'Data retroativa',
  DATA_INICIAL: 'Data inicial',
  DATA_FINAL: 'Data final',
  DATA_VENCIMENTO_AJUSTADA: 'Data de vencimento ajustada!',

  // Letra E:
  ENDERECO: 'Endereço',
  ENDERECO_BAIRRO: 'Bairro',
  ENDERECO_COMPLEMENTO: 'Complemento',
  ENDERECO_ESTADO: 'Estado',
  ENDERECO_LOGRADOURO: 'Logradouro',
  ENDERECO_MUNICIPIO: 'Municipio',
  ENDERECO_NUMERO: 'Número',
  ENDERECO_PAIS: 'País',
  EVENTO: 'Evento',
  ESTORNAR_TITULO: 'Estornar título',
  EXCLUIR_LANCAMENTO: 'Excluir lançamento',
  EXCLUIR_PERIODO_CONTABIL_SUCESSO: 'Período contábil excluído com sucesso.',
  EDITAR_LANCAMENTO: 'Editar lançamento',
  EDITAR_FLUXO_CAIXA_SUCESSO: 'Fluxo de caixa editado com sucesso.',
  EMPRESAS: 'Empresas',
  EXCLUIR_CONTATO: 'Excluir contato',
  EXCLUIR_ENDERECO: 'Excluir endereço',

  // Letra F:
  FINANCEIRO_FECHAMENTO: 'Fechar período financeiro',
  FINANCEIRO_ABERTURA: 'Abrir período financeiro',
  FINANCEIRO_REABRIR: 'Reabrir período financeiro',
  FINANCEIRO: 'Financeiro',
  FINANCEIRO_DESCRICAO:
    'Selecione um card abaixo para utilizar as funcionalidades:',
  FLUXO_CAIXA: 'Fluxo de caixa',
  FLUXO_CAIXA_DESCRICAO:
    'Esta é a página do fluxo de caixa. Utilize o filtro abaixo para exibir dados. Selecione o ícone ▼ na coluna "ações" na tabela gerada para gerar recibos ou excluir lançamentos.',
  FORNECEDOR: 'Fornecedor',
  FORNECEDOR_DOCUMENTO: 'Fornecedor/Doc.',
  FORNECEDORES_DESCRICAO_PAGE:
    'A lista abaixo contém todos os fornecedores registrados. Utilize o botão azul no topo a direita para criar um novo fornecedor.',
  FORNECEDOR_PLACEHOLDER: 'Selecione um fornecedor',
  FORNECEDORES: 'Fornecedores',
  FORMAS_PAGAMENTO: 'Formas de pagamento',
  FORMAS_PAGAMENTO_DESCRICAO:
    'Nessa tela é possível filtrar formas de pagamento, para isso utilize os campos de filtro. Para criar, editar e excluir utilize o botão "Cadastrar" e o botão de "Ações" na tabela.',
  FORMA_PAGAMENTO: 'Forma de pagamento',
  FORMA_PAGAMENTO_PLACEHOLDER: 'Selecionar forma de pagamento',
  FORMA_PAGAMENTO_CONFIRMAR_EXCLUSAO:
    'Você quer mesmo deletar essa forma de pagamento?',
  FORMA_PAGAMENTO_CRIADA: 'Forma de pagamento criada!',
  FORMA_PAGAMENTO_EDITADA: 'Forma de pagamento editada!',
  FORMA_PAGAMENTO_DELETADA: 'Forma de pagamento deletada!',
  FORMAS_PAGAMENTO_OBRIGATORIO:
    'Adicione pelo menos uma forma de pagamento a este preço.',

  // Letra G:
  GERAR_RECIBO_PAGAMENTO: 'Gerar recibo do pagamento',
  GERAR_CONTAS_PREVISTAS: 'Gerar contas previstas',
  GERAR_EXTRATO: 'Gerar Extrato',
  GERAR_LANCAMENTOS: 'Gerar Lançamentos',
  // Letra H:
  HISTORICO: 'Histórico',
  HISTORICO_FINANCEIRO: 'Histórico financeiro',
  HISTORICO_LANCAMENTO: 'Histórico de lançamento',
  HISTORICO_LANCAMENTOS: 'Histórico de lançamentos',
  HISTORICO_LANCAMENTOS_DESCRICAO:
    'Crie ou exclua os históricos de lançamentos utilizando o filtro abaixo:',
  HISTORICO_PLACEHOLDER: 'Selecione um histórico',
  HISTORICO_LANCAMENTO_APLICAR_DESCONTO: 'Histórico lançamento aplic. desconto',
  // Letra I:
  INCLUIR_HISTORICO_ESTOQUE: 'Selecione o histórico de lançamento',
  INSIRA_DATA_VENCIMENTO: 'Insira uma data de vencimento',
  IMPRIMIR_CARTA_COBRANCA: 'Imprimir carta de cobrança',
  IMPRESSAO_DOCUMENTOS: 'Impressão de documentos',
  INTEGRACAO_FINANCEIRA_CONTABIL: 'Integração financeira/contábil',
  INTEGRACAO_FINANCEIRA_CONTABIL_DESCRICAO:
    'Aqui você tem acesso a integração contábil utlizando o filtro abaixo.',
  INTEGRACAO_PARAMETRO_FINANCEIRO_CONTABIL: 'Parâmetro financeiro contábil',
  INSCRICAO_ESTADUAL: 'Inscrição estadual',
  INSCRICAO_MUNICIPAL: 'Inscrição municipal',
  IMPOSTOS: 'Impostos',
  IMPOSTOS_DESCRICAO:
    'Nessa tela você pode visualizar, criar, editar e excluir impostos.',

  // Letra J:
  // Letra K:
  // Letra L:
  LANCAMENTOS: 'Lançamentos',
  LANCAMENTO_VENCIMENTO: 'Lançamento / Vencimento',
  LANCAMENTOS_CONTAS_PAGAR: 'Lançamentos: Contas à pagar',
  LANCAMENTOS_CONTAS_RECEBER: 'Lançamentos do contas à receber',
  LISTAR_LANCAMENTOS: 'Listar lançamentos',

  // Letra M:
  MATRICULA_CONTRATO: 'Matrícula/Contrato',
  MES_REFERENCIA: 'Mês referência',

  // Letra N:
  NOME: 'Nome',
  NOME_CNPJ: 'Buscar por nome ou CNPJ',
  NOME_CNPJ_PLACEHOLDER: 'Nome ou CNPJ sem pontuação',
  NOME_CONTATO: 'Nome do contato',
  NOME_FANTASIA: 'Nome fantasia',
  NUMERO_CONTA: 'Número da conta',
  NOVA_DATA_VENCIMENTO: 'Nova data de vencimento',

  // Letra O:
  OBSERVACOES: 'Observações',

  // Letra P:
  PAGADOR: 'Pagador',
  PLANO_CONTA: 'Plano conta',
  PERCENTUAL_RATEIO: 'Percentual de rateio',
  PERIODO_INICIAL: 'Período inicial',
  PERIODO_FECHAMENTO: 'Período fechamento',
  PERIODO_FINAL: 'Período final',
  PERIODO_FINANCEIRO: 'Período financeiro',
  PERIODO_FINANCEIRO_COMO_USAR_PAGINA:
    'Utilize o filtro abaixo para listar os períodos financeiros da conta desejada. Selecione o ícone ▼ na coluna "ações" na tabela para editar ou excluir o período.',
  PERIODO_FINANCEIRO_CONTA_VINCULADA_OBRIGATORIA:
    'ATENÇÃO: Vincular uma conta é obrigatório para a criação de um período financeiro. Clique em "Contas vinculadas" na barra de navegação neste modal, para adicionar uma conta a este período financeiro.',
  PERIODO_FINANCEIRO_CRIADO: 'Período financeiro criado!',
  PERIODO_FINANCEIRO_EDITADO: 'Período financeiro editado!',
  PERIODO_FINANCEIRO_EXCLUIDO: 'Período financeiro excluído!',
  PERIODO_FINANCEIRO_ABERTURA: 'Período financeiro aberto!',
  PERIODO_FINANCEIRO_REABERTURA: 'Período financeiro reaberto!',
  PERIODO_FINANCEIRO_FECHADO: 'Período financeiro fechado!',
  PERIODOS: 'Períodos',
  PRECO: 'Preço',
  PRECOS: 'Preços',
  PRECOS_DESCRICAO:
    'Nessa tela é possível filtrar preços por meio do filtro, criar, editar e excluir preços, para isso utilize o botão <strong>Cadastrar</strong> ou <strong>Ações</strong> na tabela.',
  PRECO_CONFIRMAR_EXCLUSAO:
    'Esta ação não poderá ser desfeita. Confirmar exclusão de preço?',
  PRECO_CRIADO: 'Preço criado!',
  PRECO_EDITADO: 'Preço editado!',
  PRECO_EXCLUIDO: 'Preço excluído!',
  PRIMEIRA_PARCELA: 'Primeira parcela',
  PROCESSAR_ABERTURA_PERIODO: 'Processar abertura de período',
  PROCESSAR_FECHAMENTO_PERIODO: 'Processar fechamento de período',
  PROCESSAR_REABERTURA_PERIODO: 'Processar reabertura de período',
  PROCESSAR_PERIODO_FINANCEIRO: 'Processar período financeiro',
  PARCELA_RESIDUAL: 'Parcela residual',

  // Letra Q:
  QUANTIDADE_PARCELAS: 'Quantidade de parcelas',

  // Letra R:
  RAZAO_SOCIAL: 'Razão social',
  RECIBO: 'Recibo',
  RECIBO_NF: 'Recibo/NF',
  REFERENCIA: 'Referência',
  RETORNAR: 'Retornar',
  REIMPRESSAO: 'Reimpressão',
  RELATORIOS: 'Relatórios',
  RELATORIO_ANALISE_RETROATIVA_CONTAS_RECEBER:
    'Relatório de análises retroativas de contas a receber',
  RELATORIO_ANALISE_RETROATIVA_CONTAS_RECEBER_DESCRICAO:
    'Selecione as datas no filtro e clique no botão imprimir para gerar o relatório de análises retroativas de contas a receber',
  RELATORIO_DECLARACAO_IMPOSTO_RENDA: 'Relatório declaração imposto de renda',
  RELATORIO_DECLARACAO_IMPOSTO_RENDA_DESCRICAO:
    'Nessa tela é possível gerar relatório de imposto de renda em formato pdf para um ou mais alunos de acordo com o filtro selecionado.',
  RELATORIO_FATURAMENTO: 'Relatório de faturamento',
  RELATORIO_FATURAMENTO_DESCRICAO:
    'Selecione o período para gerar o relatório de faturamento',
  RELATORIO_ANALISE_RETROATIVA_CONTAS_PAGAR:
    'Relatório de análises retroativas de contas a pagar',
  RELATORIO_ANALISE_RETROATIVA_CONTAS_PAGAR_DESCRICAO:
    'Selecione as datas no filtro e clique no botão imprimir para gerar o relatório de análises retroativas de contas a pagar',

  // Letra S:
  SALDO_INICIAL: 'Saldo inicial',
  SALDO_FINAL: 'Saldo final',
  SELECIONAR: 'Selecionar',
  SELECIONAR_CONTA: 'Selecionar conta',
  SELECIONAR_FORNECEDOR: 'Selecionar fornecedor',
  SELECIONAR_FORMA_PAGAMENTO: 'Selecionar forma de pagamento',
  SELECIONAR_HISTORICO_LANCAMENTO: 'Selecionar um histórico lançamento',
  SELECIONAR_PERIODO: 'Selecionar período',
  SELECIONE_PARA_HABILITAR_BOTOES:
    'Selecione ao menos um item para habilitar os botões de ação',
  STATUS_PAGAMENTO: 'Status de pagamento',
  STATUS: 'Status',

  // Letra T:
  TAXA: 'Taxa',
  TAXAS: 'Taxas',
  TIPO: 'Tipo',
  TIPO_CONTA: 'Tipo conta',
  TIPO_CONTATO: 'Tipo contato',
  TIPO_HISTORICO: 'Tipo histórico',
  TIPO_FLUXO_FINANCEIRO: 'Tipo fluxo financeiro',
  TIPO_VALOR: 'Tipo valor',
  TOTAL: 'Total',
  TRANSFERENCIA: 'Transferência',
  // Letra U:
  // Letra V:
  VALOR: 'Valor',
  VALOR_PARCELA: 'Valor da parcela',
  VALIDADE: 'Validade',
  VALOR_PORCENTAGEM: 'Valor (%)',
  VARIAÇÃO: 'Variação',
  VER_INADIMPLENTES: 'Ver inadimplentes',

  // Letra X:
  // Letra Y:
  // Letra Z:
};
