export const AcademicoDiarioClasse = {
  // Letra A:
  ACADEMICO: 'Acadêmico',
  AULAS: 'Aulas',
  AULAS_DESCRICAO:
    'Nessa tela é possível filtrar aula pelos filtros abaixo, além de poder editar e excluir as aulas listadas.',
  AVALIACOES: 'Avaliações',
  AVALIACOES_DESCRICOES:
    'Nessa tela você pode filtrar as avaliações utilizando o filtro abaixo, além disso é possível criar novas avaliações, editar e excluir existentes.',

  // Letra B:
  // Letra C:
  CADASTRAR_AULA: 'Cadastrar aula',
  // Letra D:
  DIARIO_CLASSE: 'Diário de classe',

  // Letra E:
  // Letra F:
  FREQUENCIAS: 'Frequências',
  FREQUENCIAS_DESCRICAO:
    'Na tela de frequência dos alunos, você pode gerenciar e visualizar frequências filtradas, além de editar tanto as frequências quanto suas descrições associadas.',

  // Letra G:
  // Letra H:
  // Letra I:
  // Letra J:
  // Letra K:
  // Letra L:
  // Letra M:
  // Letra N:
  NOTAS_DESCRICAO:
    'Na tela de notas dos alunos, você pode gerenciar e visualizar notas filtradas, além de editar tanto as notas quanto suas descrições associadas.',
  NOTAS: 'Notas',

  // Letra O:
  // Letra P:
  // Letra Q:
  // Letra R:
  // Letra S:
  // Letra T:
  // Letra U:
  // Letra V:
  // Letra X:
  // Letra Y:
  // Letra Z:
};
