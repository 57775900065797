export const Rh = {
  // Letter A:
  ACOES: 'Actions',
  ANEXO: 'Attachment',

  // Letter B:
  BAIXAR_ANEXO: 'Download attachment',
  // Letter C:
  CADASTRAR_FUNCIONARIO: 'Register employee',
  CADASTRAR_MATRICULA: 'Register enrollment',
  CADASTRAR_PESSOA: 'Register person',
  CADASTRAR_PROFESSOR: 'Register teacher',
  CONFIGURACOES: 'Settings',

  // Letter D:
  DOCUMENTOS: 'Documents',
  DATA_PROTOCOLO: 'Creation date',
  // Letter E:
  // Letter F:
  FUNCIONARIOS: 'Employees',
  FUNCIONARIO_DESCRICAO:
    'On this screen you can manage employees, create, edit and bring up the list by filter.',

  // Letter G:
  // Letter H:
  // Letter I:
  INFORMACOES_PESSOAIS: 'Personal information',
  INFORMACOES_BANCARIAS: 'Banking information',
  // Letter J:
  // Letter K:
  // Letter L:
  // Letter M:
  MATRICULA: 'Enrollment',
  // Letter N:
  NOME: 'Name',
  NENHUM_REGISTRO: 'No records',
  // Letter O:
  // Letter P:
  PESSOAS: 'People',
  PESSOAS_DESCRICAO:
    'In this screen, you can search, create, edit, and register people. Use the filter button, the <strong> Register </strong> button, or the <strong> Actions </strong> button on the table rows.',
  PROFESSORES: 'Teachers',
  PROTOCOLOS: 'Protocols',
  PROTOCOLOS_DESCRICAO:
    'In this screen, you can view and respond to open protocols.',

  // Letter Q:
  // Letter R:
  RECURSOS_HUMANOS: 'Human Resources',
  RESPOSTA: 'Response',

  // Letter S:
  SALVAR_CADASTRAR_MATRICULA: 'Save and register enrollment',
  SITUACAO: 'Situation',
  SOLICITANTE: 'Applicant',

  // Letter T:
  TITULO_PROTOCOLO: 'Title',
  TIPO_FUNCIONARIO: 'Employee type',
  TITULACOES: 'Titles',
  // Letter U:
  // Letter V:
  VISUALIZAR_MATRICULA: 'View enrollment',

  // Letter X:
  // Letter Y:
  // Letter Z:
};
