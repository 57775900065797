const USUARIO_TOKEN = 'cej_admin_usuario_token';
const buscarToken = () => window.localStorage.getItem(USUARIO_TOKEN);
const salvarToken = (token) =>
  window.localStorage.setItem(USUARIO_TOKEN, token);
const excluirToken = () => window.localStorage.removeItem(USUARIO_TOKEN);

const USUARIO_FOTO = 'cej_admin_usuario_foto';
const buscarFoto = () => window.localStorage.getItem(USUARIO_FOTO);
const salvarFoto = (foto) => window.localStorage.setItem(USUARIO_FOTO, foto);
const excluirFoto = () => window.localStorage.removeItem(USUARIO_FOTO);

const USUARIO_NOME = 'cej_admin_usuario_nome';
const buscarNome = () => window.localStorage.getItem(USUARIO_NOME);
const salvarNome = (nome) => window.localStorage.setItem(USUARIO_NOME, nome);
const excluirNome = () => window.localStorage.removeItem(USUARIO_NOME);

const USUARIO_SOBRENOME = 'cej_admin_usuario_sobrenome';
const buscarSobrenome = () => window.localStorage.getItem(USUARIO_SOBRENOME);
const salvarSobrenome = (sobrenome) =>
  window.localStorage.setItem(USUARIO_SOBRENOME, sobrenome);
const excluirSobrenome = () =>
  window.localStorage.removeItem(USUARIO_SOBRENOME);

const USUARIO_EMAIL = 'cej_admin_usuario_email';
const buscarEmail = () => window.localStorage.getItem(USUARIO_EMAIL);
const salvarEmail = (email) =>
  window.localStorage.setItem(USUARIO_EMAIL, email);
const excluirEmail = () => window.localStorage.removeItem(USUARIO_EMAIL);

export default {
  buscarToken,
  salvarToken,
  excluirToken,
  buscarNome,
  salvarNome,
  excluirNome,
  buscarEmail,
  salvarEmail,
  excluirEmail,
  buscarSobrenome,
  salvarSobrenome,
  excluirSobrenome,
  buscarFoto,
  salvarFoto,
  excluirFoto,
};
