// TODO: gerar tb para o aquivo em inglês
export const FinanceiroLancamentos = {
    // Letra A:
    // Letra B:
    // Letra C:
    CONTA: 'Conta',
    CONTAS_PAGAR: 'Contas à pagar',
    CONTAS_RECEBER: 'Contas à receber',

    // Letra D:
    DATA_LANCAMENTO: 'Data de lançamento',
    DATA_DO_MOVIMENTO: 'Data do movimento',
    DATA_INVALIDA:'A Data de Lançamento não pode ser superior à Data de Vencimento.',
    DESCONTO: 'Desconto (R$)',
    // Letra E:
    // Letra F:
    FINANCEIRO: 'Financeiro',

    // Letra G:
    // Letra H:
    HISTORICO_LANCAMENTOS: 'Histórico de lançamentos',

    // Letra I:
    INTEGRACAO_FINANCEIRA_CONTABIL: 'Integração financeira/contábil',

    // Letra J:
    JUROS: 'Juros (R$)',
    // Letra K:
    // Letra L:
    // Letra M:
    // Letra N:
    // Letra O:
    OBSERVACAO: 'Observação',
    // Letra P:
    PAGE_DESCRIPTION: '',
    PAGE_TITLE: 'Lançamentos financeiros',
    PAGADOR: 'Pagador',
    // Letra Q:
    // Letra R:
    REFERENCIA: 'Referência',
    // Letra S:
    SELECIONE_CONTA_CREDITO: 'Selecione a conta de crédito',
    SELECIONE_CONTA_DEBITO: 'Selecione a conta de débito',
    // Letra T:
    TARIFA: 'Tarifa',
    TIPO_LANCAMENTO: 'Tipo de lançamento',
    TIPO_OBJETO_REFERENCIA: 'Tipo de objeto referência',
    TITULO_REFERENCIA: 'Título de referência',
    TRANSFERENCIA_ENTRE_CONTAS: 'Transferência entre contas',
    // Letra U:
    // Letra V:
    VALOR_DO_DOCUMENTO: 'Valor do documento',
    VALOR_TOTAL: 'Valor total',
    VALOR: 'Valor',
    VALOR_NEGATIVO:'Não é possível adicionar um valor negativo',
    // Letra X:
    // Letra Y:
    // Letra Z:
};
