import moment from "moment";
import CryptoJS from "crypto-js";

const formatarDataBr = (data, hora = false) => {
  if (!data) return;
  let formato = !hora ? "DD/MM/YYYY" : "DD/MM/YYYY HH:mm";
  return moment(data).format(formato);
};

const adicionarTempoEmData = (data) => {
  if (!data) return;
  return `${data}T00:00`
}

const formatarDataEn = (data, hora = false) => {
  if (!data) return;
  let formato = !hora ? "yyyy-MM-DD" : "yyyy-MM-DD HH:mm";
  return moment(data).format(formato);
};

const formatarDataUtc = (data, hora = false) => {
  if (!data) return;
  let formato = !hora ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm";
  return moment(data, formato)
    .utc()
    .format();
};

const formatarReal = (valor) => {
  return valor.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
};

const retornarHora = (data) => {
  if (!data) return;
  return moment(data).format("HH:mm");
};

const removerMascara = (str) => {
  if (str == null || str == undefined || str == "") return "";
  return str.replace(/[^\d]+/g, "");
};

const validarEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validarCpfCnpj = (cpfCnpj) => {
  cpfCnpj = removerMascara(cpfCnpj);
  return cpfCnpj.length == 11 ? validarCPF(cpfCnpj) : validarCNPJ(cpfCnpj);
};

const validarCPF = (cpf) => {
  cpf = removerMascara(cpf);
  if (cpf == "") return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length != 11 ||
    cpf == "00000000000" ||
    cpf == "11111111111" ||
    cpf == "22222222222" ||
    cpf == "33333333333" ||
    cpf == "44444444444" ||
    cpf == "55555555555" ||
    cpf == "66666666666" ||
    cpf == "77777777777" ||
    cpf == "88888888888" ||
    cpf == "99999999999"
  )
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(10))) return false;
  return true;
};

const validarCNPJ = (cnpj) => {
  cnpj = removerMascara(cnpj);

  if (cnpj == "") return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999"
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
};

const formatacaoCasaDecimal = (valor) => valor.toFixed(2);

const validarFormulario = (campos, arrayNaoValidar = []) => {
  let arrayValidacao = [];

  for (let [key, value] of Object.entries(campos)) {
    if (Array.isArray(value)) {
      for (let [key2, value2] of Object.entries(value)) {
        if (!arrayNaoValidar.find((element) => element == key2)) {
          value2.isField ? arrayValidacao.push(value2.valid()) : {}
        }
      }
    } else {
      if (!arrayNaoValidar.find((element) => element == key)) {
        value ? arrayValidacao.push(value.valid()) : {}
      }
    }

  }
  return arrayValidacao.filter((elem) => elem == false).length == 0;
};
const resetarValidacao = (campos) => {
  Object.values(campos).forEach(ref => {
    ref.validarFormulario = false
    ref.validForm = false
  })
};

const validarAlteracoesEmCampos = (campos) => {
  let arrayValidacao = [];
  for (let [key, value] of Object.entries(campos)) {
    if (value)
      if (value.isField) {
        key;
        arrayValidacao.push(value.wasChanged);
      }
  }
  return arrayValidacao.filter((elem) => elem == true).length != 0;
};

const montarMenu = (menus, acessos) => {
  let arrayMenus = [];
  menus.forEach((element) => {
    let verificacao = acessos.find((x) => x.acesso === element.id);
    if (element.id == "Dashboard") arrayMenus.push(element);
    if (verificacao)
      if (verificacao.permissoes.includes("Visualizar"))
        arrayMenus.push(element);
  });
  return arrayMenus;
};

const decodificarJwt = (token) => {
  let base64Payload = token.split(".")[1];
  let payload = Buffer.from(base64Payload, "base64");
  return JSON.parse(payload.toString());
};

const formatacaoCamelCase = (string) =>
  String(string[0].toLowerCase() + string.slice(1));

const formatacaoParaLabel = (string) => {
  let arrayString = [];
  const newString = string.replace(/[-]/gi, "_").split("_");

  for (let i = 0; newString.length > i; i++) {
    if (i !== 0 && i !== 1) {
      arrayString.push(newString[i]);
    }
  }

  return arrayString.join("_");
};

const removeAcento = (text) => {
  text = text.toLowerCase();
  text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
  text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
  text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
  text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
  text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
  text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
  return text;
};

const formatacaoDeStringBase64 = (url, tipo) =>
  url.replace(`data:${tipo};base64,`, "");

const formatacaoMoedaBRL = (valor) =>
  valor.toLocaleString("pt-br", { style: "currency", currency: "BRL" });

const formatarBooleanSimNao = (valor) => valor === true ? "Sim" : "Não";

const formatarBooleanFechadoAberto = (valor) => valor === true ? "Fechado" : "Aberto";

const arrayIsEmpty = (array) => {
  if (!Array.isArray(array)) { return false }
  if (array.length == 0) { return true }
  return false
}
const objectIsEmpty = (obj) => { return Object.keys(obj).length === 0 }

const stringIsEmpty = (string) => {
  // if (!typeof string === 'string') { return false }
  if (string.length == 0) { return true }
  return false
}

const Encriptar = (dado) => {
  const chave = CryptoJS.enc.Utf8.parse("5A19F263B56D2E07E6E975B73E732839");
  const iv = CryptoJS.lib.WordArray.random(16);

  const encrypted = CryptoJS.AES.encrypt(dado, chave, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  const combined = iv.concat(encrypted.ciphertext);

  return combined.toString(CryptoJS.enc.Base64);
}

export default {
  arrayIsEmpty,
  stringIsEmpty,
  objectIsEmpty,
  formatarDataBr,
  formatarBooleanSimNao,
  formatarDataEn,
  adicionarTempoEmData,
  formatarDataUtc,
  formatarReal,
  formatacaoCamelCase,
  formatacaoParaLabel,
  retornarHora,
  removerMascara,
  validarEmail,
  validarCpfCnpj,
  validarCPF,
  validarCNPJ,
  validarFormulario,
  validarAlteracoesEmCampos,
  montarMenu,
  decodificarJwt,
  formatacaoDeStringBase64,
  formatacaoMoedaBRL,
  formatacaoCasaDecimal,
  removeAcento,
  formatarBooleanFechadoAberto,
  Encriptar,
  resetarValidacao
};
