export const PainelProfessor = {
    AVALIACAO_CRIADA: 'Avaliação criada!',
    AULA_CRIADA: 'Aula criada!',
    AULA_EDITADA: 'Aula editada!',
    AULA_EXCLUIDA: 'Aula deletada!',
    DATA_RECUPERACAO: 'Data de recuperação',
    NOVA_AULA: 'Nova aula',
    PAINEL_PROFESSOR: 'Painel do professor',
    REGISTRAR_FREQUENCIAS: 'Registrar frequências',
    REGISTRAR_NOTAS: 'Registrar notas',
    CAMPO_PARECER_OBRIGATORIO: 'Campo parecer é obrigatório',
    DATA_RECUPERACAO_INVALIDA: 'A data da recuperação não pode ser superior à data da avaliação!'
}