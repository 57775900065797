export const PainelProfessor = {
    AVALIACAO_CRIADA: 'Test created!',
    AULA_CRIADA: 'Class created!',
    AULA_EDITADA: 'Class edited!',
    AULA_EXCLUIDA: 'Class deleted!',
    DATA_RECUPERACAO: 'Recovery date',
    NOVA_AULA: 'New class',
    PAINEL_PROFESSOR: 'Teacher dashboard',
    REGISTRAR_FREQUENCIAS: 'Register frequency',
    REGISTRAR_NOTAS: 'Register grades',
    CAMPO_PARECER_OBRIGATORIO: 'The opinion field is required',

}