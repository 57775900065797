export const Geral = {
  // Letra A:
  ACAO_AVISO: 'Este campo é obrigatório ',
  ACAO_CONFIRMADA: 'Ação confirmada com sucesso',
  ACAO_ERRO: 'Erro ao executar ação',
  ACOES: 'Ações',
  ADICIONAR: 'Adicionar',
  AGENCIA: 'Agência',
  ALTERAR_SENHA: 'Alterar senha',
  ANO: 'Ano',
  ANO_LETIVO: 'Ano letivo',
  ATIVAR: 'Ativar',
  ATUALIZAR: 'Atualizar',
  AVISO: 'Aviso',
  ATENCAO: 'Atenção',
  ADICIONAR_FOTO: 'Adicionar foto',

  // Letra B:
  BANCO: 'Banco',
  BANCO_EDITADO: 'Banco editado!',
  BANCO_EXCLUIDO: 'Banco excluído!',
  BUSCAR: 'Buscar',
  BUSCA_AVANCADA: 'Busca avançada',
  BUSCAR_CONTA: 'Buscar conta',
  BTN_VOLTAR_EXPLICACAO: 'Retornar a página anterior',
  BAIXAR: 'Baixar',

  // Letra C:
  CADASTRAR: 'Cadastrar',
  CANCELAR: 'Cancelar',
  CARGA_HORARIA: 'Carga horária',
  CARREGANDO_DADOS: 'Carregando dados...',
  CODIGO: 'Código',
  CONFIRMAR: 'Confirmar',
  CONFIRMAR_ESTORNO: 'Confirmar estorno',
  CONFIRMAR_ESTORNO_ITEM:
    'ATENÇÃO: Esta ação não poderá ser desfeita. Confirmar estorno de {item}?',
  CONFIRMAR_EXCLUSAO: 'Confirmar exclusão',
  CONFIRMAR_EXCLUSAO_ITEM:
    'ATENÇÃO: Esta ação não poderá ser desfeita. Confirmar exclusão de {item}?',
  CONFIRMACAO_SENHA: 'Confirmação de senha',
  CONFIRMAR_NOVA_SENHA: 'Confirmar nova senha',
  CONFIRMACAO_SAIR:
    'Tem certeza que deseja sair? As alterações não salvas serão perdidas',
  CONTA: 'Conta',
  CONTA_CAIXA: 'Conta/Caixa',
  CONTAS: 'Contas',
  CONFIGURACOES: 'Configurações',
  CONTEUDO: 'Conteúdo',
  CNPJ: 'CNPJ',
  CURSO: 'Curso',
  CURSOS: 'Cursos',
  CONFIRMACAO_SENHA_PARA_SALVAR:
    'Para salvar alterações de edição, informe sua senha atual',
  CONFIRMAR_ALTERACOES: 'Confirmar alterações',
  COPIAR: 'Copiar',
  // Letra D:
  DADOS_GERAIS: 'Dados gerais',
  DATA: 'Data',
  DATAS: 'Datas',
  DATA_DOCUMENTO: 'Data do documento',
  DATA_INICIO: 'Data início',
  DATA_FIM: 'Data fim',
  DATA_LANCAMENTO: 'Data do lançamento',
  DATA_MOVIMENTO: 'Data do movimento',
  DATA_PAGAMENTO: 'Data do pagamento',
  DATA_VENCIMENTO: 'Data do vencimento',
  DATA_INICIO_MENOR: 'A data fim não pode ser superior à data início',
  DESCRICAO: 'Descrição',
  DESCONTO: 'Desconto',
  DIGITE_OU_PROCURE: 'Digite ou procure',
  DIGITE_OU_SELECIONE: 'Digite ou selecione',
  DISCIPLINA: 'Disciplina',
  DOCUMENTO: 'Documento',
  DADOS_DO_USUARIO: 'Dados do usuário',
  DATAS_INVALIDAS_INICIAL_FINAL:
    'Data inicial não pode ser maior que data final',
  DESLIGAR: 'Desligar',

  // Letra E:
  EDITAR: 'Editar',
  EMAIL: 'E-mail',
  EMAIL_ENVIADO: 'E-mail enviado com sucesso!',
  ERRO: 'Erro',
  ESTRUTURA: 'Estrutura',
  ESTORNAR: 'Estornar',
  EXIBIR_FILTRO: 'Exibir filtro',
  EXIBIR_RESULTADO: 'Exibir resultado',
  EXCLUIR: 'Excluir',
  EXIBIR: 'Exibir',
  EXIBIR_FORMULARIO: 'Exibir formulário',
  ESCONDER_FORMULARIO: 'Esconder formulário',

  // Letra F:
  FECHAR: 'Fechar',
  FILTRAR: 'Filtrar',
  FILTRAR_POR: 'Filtrar por',
  FILTRAR_POR_NOME: 'Filtrar por nome',
  FILTRAR_POR_NOME_CPNJ: 'Filtrar por nome ou CNPJ',
  FINANCEIRO: 'Financeiro',
  FORMAS_PAGAMENTO: 'Formas de pagamento',
  FUNCOES: 'Funções',
  FILTRO_TABELAS: 'Filtro da tabela',

  // Letra G:
  GENERO: 'Gênero',
  GERAR_PERIODOS_FINANCEIROS: 'Gerar períodos financeiros',
  GERENCIAMENTO: 'Gerenciamento',
  GERENCIAR_PERMISSOES: 'Gerenciar permissões',
  GRUPO_TURMA: 'Grupo turma',
  GRUPO_TURMAS: 'Grupo turmas',
  GERAR_RELATORIO: 'Gerar relatório',

  // Letra H:
  HISTORICO: 'Histórico',
  HISTORICO_LANCAMENTO: 'Histórico',
  HOME: 'Home',

  // Letra I:
  IDIOMA: 'Idioma',
  INSCRICAO_MUNICIPAL: 'Inscrição municipal',
  INGLES: 'Inglês',
  INFORMACOES_SALVAS: 'Informações salvas!',
  INATIVAR: 'Inativar',
  IMPRIMIR: 'Imprimir',
  ITEM_CRIADO: '{item} criado com sucesso!',
  ITEM_EDITADO: '{item} editado com sucesso!',
  ITEM_ESTORNADO: '{item} estornado com sucesso!',
  ITEM_DELETADO: '{item} excluído com sucesso!',
  IMPRIMIR_RELATORIO: 'Imprimir relatório',
  // Letra J:
  JUROS: 'Juros',

  // Letra K:
  // Letra L:
  LIMPAR_FILTRO: 'Limpar filtro',
  LIMPAR: 'Limpar',
  LIMPAR_PESQUISA: 'Limpar pesquisa',
  LISTA_VAZIA: 'Lista vazia',
  LISTAR: 'Listar',
  LOGIN: 'Login',
  LOREM_IPSULUM: '',

  // Letra M:
  MENSAGEM_CONFIRMAR_ACAO: 'Tem certeza que deseja executar esta ação?',
  MARCAR_TODOS: 'Marcar todos',
  MARCAR: 'Marcar',

  // Letra N:
  NAO: 'Não',
  NENHUM_REGISTRO: 'Nenhum registro',
  NOME: 'Nome',
  NOME_FANTASIA: 'Nome fantasia',
  NOME_DO_USUARIO: 'Nome do usuário',
  NOVA_SENHA: 'Nova senha',
  NUMERO_CONTA: 'Número da conta',

  // Letra O:
  OBSERVACAO: 'Observação',
  OBSERVACOES: 'Observações',
  OCULTAR_FILTRO: 'Ocultar filtro',
  OK: 'Ok',
  OCORREU_UM_ERRO_INTERNO: 'Ocorreu um erro interno',

  // Letra P:
  PASSO_ANTERIOR: 'Passo anterior',
  PESSOA: 'Pessoa',
  PERIODO: 'Período',
  PESQUISAR: 'Pesquisar',
  PORTUGUES: 'Português',
  PROCURAR: 'Procurar',
  PROXIMO_PASSO: 'Próximo passo',

  // Letra Q:
  // Letra R:
  RAZAO_SOCIAL: 'Razão social',
  REDEFINIR_SENHA: 'Redefinir senha',
  RETORNAR: 'Retornar',
  REMOVER: 'Remover',
  REGISTROS_POR_PAGINA: 'registros por página',
  REQUISITOS_SENHA: 'Requisitos da Senha:',
  RESPONDIDO: 'Respondido',

  // Letra S:
  SAIR: 'Sair',
  SALVAR: 'Salvar',
  SELECIONAR_ACAO: 'Selecionar ação:',
  SEM_CONTRATOS_CADASTRADOS: 'Sem contratos cadastrados',
  SEM_DESCRICAO_CADASTRADA: 'Sem descrição cadastrada',
  SENHA: 'Senha',
  SENHA_CRITERIOS:
    'A senha deve ter pelo menos 6 caracteres e uma letra minúscula!',
  SENHA_ALTERADA: 'Senha Alterada com Sucesso',
  SENHA_ADMINISTRADOR: 'Senha do administrador',
  SELECIONAR: 'Selecionar',
  SELECIONAR_TODOS: 'Selecionar todos',
  SELECIONAR_TODOS_AVISO:
    'ATENÇÃO: ao clicar em qualquer botão abaixo com esta opção selecionada, as ações serão executadas para todos os dados da tabela exibida unicamente nesta página',
  SELECIONE: 'Selecione',
  SELECIONE_MATRICULA_PARA_HABILITAR:
    'Selecione uma matrícula para habilitar campo',
  SELECIONE_PARA_EXIBIR_DADOS: 'Selecione para exibir dados',
  SELECIONE_UMA_OPCAO: 'Selecione uma opção',
  SELECIONADO: 'Selecionado',
  SIM: 'Sim',
  SIGLA: 'Sigla',
  SOMENTE_NUMEROS: 'Digite somente números',
  STATUS: 'Status',
  SUCESSO: 'Sucesso',
  SEM_DADOS_PARA_O_FILTRO: 'Sem dados para o filtro selecionado',
  SEM_ANEXO: 'Sem anexo',

  // Letra T:
  TIPO: 'Tipo',
  TIPO_CONTRATO: 'Tipo de contrato',
  TODOS: 'Todos',
  TOTAL_REGISTROS: 'Total de {count} registros',
  TURMAS: 'Turmas',
  TURMA: 'Turma',
  TEXTO_COPIADO: 'Texto copiado!',

  // Letra U:
  // Letra V:
  VALIDADE: 'Validade',
  VALOR_BRL: 'Valor(R$)',
  VALOR_EFETIVO: 'Valor efetivo',
  VALOR_PORCENTAGEM: 'Valor(%)',
  VISUALIZAR: 'Visualizar',
  VOLTAR: 'Voltar',
  VOLTAR_LOGIN: 'Voltar ao login',

  // Letra X:
  // Letra Y:
  // Letra Z:
};
