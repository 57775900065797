export const CamposValidacao = {
    CAMPO_INVALIDO: "Campo inválido",
    CEP_INEXISTENTE: "Não foi possível localizar CEP",
    CAMPO_REQUERIDO: "Campo requerido",
    CELULAR_INVALIDO: "Celular inválido",
    CEP_INVALIDO: "CEP inválido",
    COMPRIMENTO_MINIMO: "Comprimento mínimo do campo de {length} caracteres",
    COMPRIMENTO_MAXIMO: "Comprimento máximo do campo de {length} caracteres",
    EMAIL_INVALIDO: "Email inválido",
    INVALIDO: "O campo {name} é inválido",
    FAX_INVALIDO: "Fax inválido",
    LOGIN_INVALIDO: "Login inválido",
    NAO_ENCONTRADO: "O usuário {name} não foi encontrado",
    NUMERO_RESIDENCIAL_INVALIDO: 'Número residencial inválido',
    NUMERO_COMERCIAL_INVALIDO: 'Número comercial inválido',
    REQUERIDO: "Por favor informe o campo {name}",
    SENHA_E_CONFIRMACAO: "As senhas digitadas não são iguais",
    TAMANHO: "O campo {name} precisa possuir {length} caracteres",
    TAMANHO_MINIMO: "O campo {name} precisa possuir no mínimo {min} caracteres",
    TERMOS_CONDICOES: "É necessário aceitar os termos e condições",
    VALOR_MAXIMO: "Valor máximo do campo de é {max}",
    VALOR_MINIMO: "Valor mínimo do campo de é {min}",
}