export const Menu = {
  // Letter A:
  ACADEMICO: 'Academic',
  ADITIVOS_CONTRATO: 'Contract addendums',
  AULAS: 'Classes',
  AVALIACOES: 'Assessments',
  // Letter B:
  BANCOS: 'Banks',
  BOLETINS: 'Bulletins',
  // Letter C:
  CONFIGURACOES: 'Settings',
  CONTABIL: 'Accounting',
  CONTRATOS: 'Contracts',
  CURSOS: 'Courses',
  CURRICULO_ESCOLAR: 'School curriculum',
  CONTAS: 'Accounts',
  CONVENIADOS: 'Affiliates',
  CARTA_COBRANCA: 'Collection letter',
  // Letter D:
  DESCONTOS: 'Discounts',
  DISCIPLINAS: 'Disciplines',
  DOCENTES_PERIODO: 'Period teachers',
  DOCUMENTO_PADRAO: 'Standard document',

  // Letter E:
  // Letter F:
  FINANCEIRO: 'Financial',
  FECHAR: 'Close',
  FECHAMENTO_PERIODO_LETIVO: 'End of academic period',
  FECHAMENTO_CURSOS_TURMAS: 'Courses and classes closure',
  FREQUENCIAS: 'Attendance',
  FORMAS_PAGAMENTO: 'Payment methods',
  FLUXO_CAIXA: 'Cash flow',
  FICHA_SOCIOECONOMICA: 'Socio-economic reports',
  FUNCIONARIOS: 'Employees',
  FORNECEDORES: 'Suppliers',

  // Letter G:
  GERENCIADOR_TURMAS: 'Class manager',
  GRUPOS_TURMA: 'Class groups',
  // Letter H:
  HOME: 'Home',
  HISTORICO_ESCOLAR: 'School history',
  HISTORICO_LANCAMENTOS: 'Transaction history',
  // Letter I:
  INSTITUICAO_ENSINO: 'Educational institution',
  INSTITUICOES_ENSINO: 'Educational institutions',
  INTEGRACAO_FINANCEIRA_CONTABIL: 'Financial/Accounting integration',
  IMPOSTOS: 'Taxes',
  // Letter J:
  // Letter K:
  // Letter L:
  LANCAMENTO_CONTAS_PAGAR: 'Accounts payable entry',
  LANCAMENTO_CONTAS_RECEBER: 'Accounts receivable entry',
  // Letter M:
  MATRICULAS: 'Enrollments',
  // Letter N:
  NOTAS: 'Grades',
  // Letter O:
  // Letter P:
  PAINEL_PROFESSOR: 'Teacher panel',
  PAINEL_COORDENACAO: 'Coordinator panel',
  PESSOAS: 'People',
  PERFIS: 'Profiles',
  PERIODO_LETIVO: 'Academic period',
  PRECOS: 'Prices',
  PERIODO_FINANCEIRO: 'Financial period',
  PROTOCOLOS: 'Protocols',
  // Letter Q:
  // Letter R:
  RELATORIO_DESCONTOS_PARA_CONTRATOS: 'Report discounts for contracts',
  RELATORIOS_HISTORICO_ESCOLAR: 'Reports based on school history',
  RELATORIO_DISCIPLINA: 'Discipline report',
  RELATORIO_TURMAS_PARA_ALUNO: 'Class report for student',
  RECURSOS_HUMANOS: 'Human resources',
  RELATORIO_DIARIO: 'Daily report',
  RELATORIO_ESTOQUE: 'Stock report',
  RELATORIO_RAZAO: 'Ledger report',
  REMATRICULAS: 'Reenrollments',
  RELATORIOS_TURMAS: 'Class reports',
  RELATORIO_IRMAOS: 'Sibling report',
  RELATORIO_VALORES_PARA_CONTRATOS: 'Contract values report',
  RELATORIO_TOTALIZADORES: 'Totalizers report',
  RELATORIO_DECLARACAO_IMPOSTO_RENDA: "Income tax return report",
  RELATORIO_FATURAMENTO: 'Billing report',
  RELATORIO_SITUACAO_CONTRATO: 'Contract status reports',
  RELATORIO_LANCAMENTOS_POR_PERIODO: "Postings by period report",
  IMPRIMIR_RELATORIO_ANALISE_RETROATIVA_CONTAS_RECEBER:
    'Accounts receivable retroactive analysis report',
  // Letter S:
  SUPRIMENTOS: 'Supplies',
  // Letter T:
  TURMAS: 'Classes',
  TIPO_DE_AVALIACAO: 'Type of evaluations',

  // Letter U:
  USUARIOS: 'Users',
  // Letter V:
  // Letter X:
  // Letter Y:
  // Letter Z:
};
