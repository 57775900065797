import store from "@/store/Store";
import { VERIFICAR_AUTENTICACAO } from "@/store/autenticacao.module";

export default function redirecionaSeAutenticado({ next, router }) {
    if ((store.getters.estaLogado) && (store.dispatch(VERIFICAR_AUTENTICACAO))) {
      return router.push({ name: "dashboard" });
    }
  
    return next();
  }
  