import Vue from 'vue';
import Router from 'vue-router';
import verificaAutenticacao from '@/common/middleware/verificaAutenticacao.js';
import redirecionaSeAutenticado from '@/common/middleware/redirecionaSeAutenticado.js';
import i18n from '../i18n/i18n';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      meta: {
        middleware: verificaAutenticacao,
        id: 'Dashboard',
      },
      component: () => import('@/layouts/full-layout/FullLayout'),
      children: [
        /*
                    Acadêmico - Estrutura CEJ
                */
        {
          path: '/academico/cej',
          component: () => import('@/layouts/blank-layout/Index'),
          children: [
            {
              name: 'academico-estrutura-cej-cursos',
              path: '/academico/estrutura/cursos',
              meta: {
                title: i18n.t('MENU.CURSOS'),
                middleware: verificaAutenticacao,
                id: 'Cursos',
              },
              component: () =>
                import('@/views/academico/cej/estrutura/cursos/Cursos'),
            },
            {
              name: 'academico-estrutura-cej-disciplinas',
              path: '/academico/cej/disciplinas',
              meta: {
                title: i18n.t('MENU.DISCIPLINAS'),
                middleware: verificaAutenticacao,
                id: 'Disciplinas',
              },
              component: () =>
                import(
                  '@/views/academico/cej/estrutura/disciplinas/Disciplinas'
                ),
            },
            {
              name: 'academico-estrutura-cej-grupos-turma',
              path: '/academico/cej/grupos-turma',
              meta: {
                title: i18n.t('MENU.GRUPOS_TURMA'),
                middleware: verificaAutenticacao,
                id: 'AcademicoGruposTurma',
              },
              component: () =>
                import(
                  '@/views/academico/cej/estrutura/grupos-turma/GruposTurma'
                ),
            },
            {
              name: 'academico-estrutura-cej-tipo-avaliacao',
              path: '/academico/cej/tipo-avaliacao',
              meta: {
                title: i18n.t('MENU.TIPO_DE_AVALIACAO'),
                middleware: verificaAutenticacao,
                id: 'AcademicoTipoAvaliacao',
              },
              component: () =>
                import(
                  '@/views/academico/cej/estrutura/tipo-avaliacao/TipoAvaliacao'
                ),
            },
            {
              name: 'academico-estrutura-cej-turmas',
              path: '/academico/cej/turmas',
              meta: {
                title: i18n.t('MENU.TURMAS'),
                middleware: verificaAutenticacao,
                id: 'Turmas',
              },
              component: () =>
                import('@/views/academico/cej/estrutura/turmas/Turmas'),
            },
            {
              name: 'academico-estrutura-cej-documento-padrao',
              path: '/academico/cej/documento-padrao',
              meta: {
                title: i18n.t('MENU.DOCUMENTO_PADRAO'),
                middleware: verificaAutenticacao,
                id: 'DocumentoPadrao',
              },
              component: () =>
                import(
                  '@/views/academico/cej/estrutura/documentos-padrao/Index'
                ),
            },
            {
              name: 'academico-estrutura-cej-formulario-documento-padrao',
              path: '/academico/cej/formulario-documento-padrao/:id?',
              meta: {
                title: i18n.t('MENU.DOCUMENTO_PADRAO'),
                middleware: verificaAutenticacao,
                id: 'DocumentoPadrao',
              },
              component: () =>
                import(
                  '@/views/academico/cej/estrutura/documentos-padrao/formulario-documento-padrao/Index'
                ),
            },
            {
              name: 'academico-estrutura-cej-documento-padrao',
              path: '/academico/cej/documento-padrao',
              meta: {
                title: i18n.t('MENU.DOCUMENTO_PADRAO'),
                middleware: verificaAutenticacao,
                id: 'DocumentoPadrao',
              },
              component: () =>
                import(
                  '@/views/academico/cej/estrutura/documentos-padrao/Index'
                ),
            },
            {
              name: 'academico-estrutura-cej-formulario-documento-padrao',
              path: '/academico/cej/formulario-documento-padrao/:id?',
              meta: {
                title: i18n.t('MENU.DOCUMENTO_PADRAO'),
                middleware: verificaAutenticacao,
                id: 'DocumentoPadrao',
              },
              component: () =>
                import(
                  '@/views/academico/cej/estrutura/documentos-padrao/formulario-documento-padrao/Index'
                ),
            },
          ],
        },
        /*
                    Acadêmico - Estrutura Geral
                */
        // TODO: Remover
        // {
        //   path: '/academico/estruturas',
        //   component: () => import('@/layouts/blank-layout/Index'),
        //   children: [
        //     {
        //       name: 'academico-estrutura',
        //       path: '/academico/estrutura',
        //       meta: {
        //         middleware: verificaAutenticacao,
        //         id: 'AcademicoEstrutura',
        //       },
        //       component: () => import('@/views/academico/estrutura/Index'),
        //     },
        //   ],
        // },
        /*
                    Acadêmico - Estrutura Outras Instituições
                */
        {
          path: '/academico/outras-instituicoes',
          component: () => import('@/layouts/blank-layout/Index'),
          children: [
            {
              name: 'instituicao-disciplinas',
              path: '/instituicao-ensino/disciplinas',
              meta: {
                title: i18n.t('MENU.DISCIPLINAS'),
                middleware: verificaAutenticacao,
                id: 'DisciplinaCurriculo',
              },
              component: () =>
                import(
                  '@/views/academico/outras-instituicoes/disciplinas/Index'
                ),
            },
            {
              name: 'instituicao-ensino',
              path: '/instituicao-ensino',
              meta: {
                title: i18n.t('MENU.INSTITUICAO_ENSINO'),
                middleware: verificaAutenticacao,
                id: 'InstituicaoEnsino',
              },
              component: () =>
                import(
                  '@/views/academico/outras-instituicoes/instituicao-ensino/Index'
                ),
            },
            {
              name: 'criar-instituicao-ensino',
              path: '/instituicao-ensino/instituicao-ensino',
              meta: {
                title: i18n.t('MENU.INSTITUICAO_ENSINO'),
                middleware: verificaAutenticacao,
                id: 'InstituicaoEnsinoCriar',
              },
              component: () =>
                import(
                  '@/views/academico/outras-instituicoes/instituicao-ensino/Formulario'
                ),
            },
            {
              name: 'editar-instituicao-ensino',
              path: '/instituicao-ensino/instituicao-ensino/:id?',
              meta: {
                title: i18n.t('MENU.INSTITUICAO_ENSINO'),
                middleware: verificaAutenticacao,
                id: 'InstituicaoEnsinoEditar',
              },
              component: () =>
                import(
                  '@/views/academico/outras-instituicoes/instituicao-ensino/Formulario'
                ),
            },
            {
              name: 'historico-escolar',
              path: '/instituicao-ensino/historico-escolar',
              meta: {
                title: i18n.t('MENU.HISTORICO_ESCOLAR'),
                middleware: verificaAutenticacao,
                id: 'HistoricoEscolar',
              },
              component: () =>
                import(
                  '@/views/academico/outras-instituicoes/historico-escolar/Index'
                ),
            },
            {
              name: 'instituicao-turmas',
              path: '/instituicao-ensino/turmas',
              meta: {
                title: i18n.t('MENU.TURMAS'),
                middleware: verificaAutenticacao,
                id: 'InstituicaoTurma',
              },
              component: () =>
                import('@/views/academico/outras-instituicoes/turmas/Index'),
            },
          ],
        },
        /*
                    Acadêmico: gerenciamento
                */
        {
          path: 'academico',
          component: () => import('@/layouts/blank-layout/Index'),
          children: [
            {
              name: 'diario-classe',
              path: '/academico/diario-classe',
              component: () => import('@/layouts/blank-layout/Index'),
              children: [
                {
                  path: 'academico',
                  component: () => import('@/layouts/blank-layout/Index'),
                  children: [
                    {
                      name: 'diario-classe',
                      path: '/academico/diario-classe',
                      component: () => import('@/layouts/blank-layout/Index'),
                      children: [
                        {
                          name: 'diario-classe-aulas',
                          path: 'aulas',
                          meta: {
                            title: i18n.t('MENU.AULAS'),
                            middleware: verificaAutenticacao,
                            id: 'DiarioClasseAulas',
                          },
                          component: () =>
                            import('@/views/academico/diario-classe/Aulas'),
                        },
                        {
                          name: 'diario-classe-avaliacoes',
                          path: 'avaliacoes',
                          meta: {
                            title: i18n.t('MENU.AVALIACOES'),
                            middleware: verificaAutenticacao,
                            id: 'DiarioClasseAvaliacoes',
                          },
                          component: () =>
                            import(
                              '@/views/academico/diario-classe/Avaliacoes'
                            ),
                        },
                        {
                          name: 'diario-classe-frequencias',
                          path: 'frequencias',
                          meta: {
                            title: i18n.t('MENU.FREQUENCIAS'),
                            middleware: verificaAutenticacao,
                            id: 'DiarioClasseFrequencias',
                          },
                          component: () =>
                            import(
                              '@/views/academico/diario-classe/Frequencias'
                            ),
                        },
                        {
                          name: 'diario-classe-notas',
                          path: 'notas',
                          meta: {
                            title: i18n.t('MENU.NOTAS'),
                            middleware: verificaAutenticacao,
                            id: 'DiarioClasseNotas',
                          },
                          component: () =>
                            import('@/views/academico/diario-classe/Notas'),
                        },
                      ],
                    },
                    {
                      name: 'docentes-periodo',
                      path: '/academico/docentes-periodo',
                      meta: {
                        title: i18n.t('MENU.DOCENTES_PERIODO'),
                        middleware: verificaAutenticacao,
                        id: 'AcademicoDocentesPeriodo',
                      },
                      component: () =>
                        import('@/views/academico/docentes-periodo/Index'),
                    },
                    {
                      name: 'academico-curso-x',
                      path: '/academico/cursos/:id?',
                      meta: {
                        title: i18n.t('MENU.CURSOS'),
                        middleware: verificaAutenticacao,
                        id: 'Curso',
                      },
                      component: () => import('@/views/academico/cursos/Curso'),
                    },
                    {
                      name: 'matricula',
                      path: '/academico/matriculas',
                      component: () => import('@/layouts/blank-layout/Index'),
                      children: [
                        {
                          name: 'matriculas',
                          path: '/',
                          meta: {
                            title: i18n.t('MENU.MATRICULAS'),
                            middleware: verificaAutenticacao,
                            id: 'Matriculas',
                          },
                          component: () =>
                            import('@/views/academico/matriculas/Index'),
                        },
                        {
                          name: 'nova-matricula',
                          path: 'nova-matricula/:id?', // pessoaId
                          meta: {
                            title: i18n.t('MENU.MATRICULAS'),
                            middleware: verificaAutenticacao,
                            id: 'Matriculas',
                            permissoesNecessarias: ['C', 'E'],
                          },
                          component: () =>
                            import('@/views/academico/matriculas/Cadastrar'),
                        },
                        {
                          name: 'editar-matricula',
                          path: ':id?', // matriculaId
                          meta: {
                            title: i18n.t('MENU.MATRICULAS'),
                            middleware: verificaAutenticacao,
                            id: 'Matriculas',
                            permissoesNecessarias: ['C', 'E'],
                          },
                          component: () =>
                            import(
                              '@/views/academico/matriculas/EditarMatricula'
                            ),
                        },
                      ],
                    },
                    {
                      name: 'rematriculas',
                      path: '/academico/rematriculas',
                      component: () => import('@/layouts/blank-layout/Index'),
                      children: [
                        {
                          name: 'rematriculas',
                          path: '/',
                          meta: {
                            title: i18n.t('MENU.REMATRICULAS'),
                            middleware: verificaAutenticacao,
                            id: 'Contratos',
                            permissoesNecessarias: ['C', 'E', 'V'],
                          },
                          component: () =>
                            import('@/views/academico/rematriculas/Index'),
                        },
                      ],
                    },
                    {
                      path: '/turmas-turmas',
                      component: () => import('@/layouts/blank-layout/Index'),
                      children: [
                        {
                          path: '/academico/turmas/turma-x',
                          component: () =>
                            import('@/layouts/blank-layout/Index'),
                          children: [
                            {
                              name: 'academico-turma-x',
                              path: '/academico/turmas/:id?',
                              meta: {
                                title: i18n.t('MENU.TURMAS'),
                                middleware: verificaAutenticacao,
                                id: 'Turma',
                              },
                              component: () =>
                                import('@/views/academico/turmas/Turma'),
                            },
                            {
                              name: 'academico-turma-x-disciplina',
                              path: '/academico/turmas/turma-x/disciplina',
                              meta: {
                                title: i18n.t('MENU.TURMAS'),
                                middleware: verificaAutenticacao,
                                id: 'TurmaDisciplina',
                              },
                              component: () =>
                                import(
                                  '@/views/academico/turmas/TurmaDisciplinas'
                                ),
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  name: 'diario-classe-aulas',
                  path: 'aulas',
                  meta: {
                    middleware: verificaAutenticacao,
                    id: 'DiarioClasseAulas',
                  },
                  component: () =>
                    import('@/views/academico/diario-classe/Aulas'),
                },
                {
                  name: 'diario-classe-avaliacoes',
                  path: 'avaliacoes',
                  meta: {
                    title: i18n.t('MENU.AVALIACOES'),
                    middleware: verificaAutenticacao,
                    id: 'DiarioClasseAvaliacoes',
                  },
                  component: () =>
                    import('@/views/academico/diario-classe/Avaliacoes'),
                },
                {
                  name: 'diario-classe-frequencias',
                  path: 'frequencias',
                  meta: {
                    title: i18n.t('MENU.FREQUENCIAS'),
                    middleware: verificaAutenticacao,
                    id: 'DiarioClasseFrequencias',
                  },
                  component: () =>
                    import('@/views/academico/diario-classe/Frequencias'),
                },
                {
                  name: 'diario-classe-notas',
                  path: 'notas',
                  meta: {
                    title: i18n.t('MENU.NOTAS'),
                    middleware: verificaAutenticacao,
                    id: 'DiarioClasseNotas',
                  },
                  component: () =>
                    import('@/views/academico/diario-classe/Notas'),
                },
              ],
            },
            {
              name: 'docentes-periodo',
              path: '/academico/docentes-periodo',
              meta: {
                title: i18n.t('MENU.DOCENTES_PERIODO'),
                middleware: verificaAutenticacao,
                id: 'AcademicoDocentesPeriodo',
              },
              component: () =>
                import('@/views/academico/docentes-periodo/Index'),
            },
            {
              name: 'academico-curso-x',
              path: '/academico/cursos/:id?',
              meta: {
                title: i18n.t('MENU.CURSOS'),
                middleware: verificaAutenticacao,
                id: 'Curso',
              },
              component: () => import('@/views/academico/cursos/Curso'),
            },
            {
              name: 'matricula',
              path: '/academico/matriculas',
              component: () => import('@/layouts/blank-layout/Index'),
              children: [
                {
                  name: 'matriculas',
                  path: '/',
                  meta: {
                    title: i18n.t('MENU.MATRICULAS'),
                    middleware: verificaAutenticacao,
                    id: 'Matriculas',
                  },
                  component: () => import('@/views/academico/matriculas/Index'),
                },

                {
                  name: 'nova-matricula',
                  path: 'nova-matricula/:id?', // pessoaId
                  meta: {
                    title: i18n.t('MENU.MATRICULAS'),
                    middleware: verificaAutenticacao,
                    id: 'Matriculas',
                    permissoesNecessarias: ['C', 'E'],
                  },
                  component: () =>
                    import('@/views/academico/matriculas/Cadastrar'),
                },
                {
                  name: 'editar-matricula',
                  path: 'editar-matricula/:id?', // matriculaId
                  meta: {
                    title: i18n.t('MENU.MATRICULAS'),
                    middleware: verificaAutenticacao,
                    id: 'Matriculas',
                    permissoesNecessarias: ['C', 'E'],
                  },
                  component: () =>
                    import('@/views/academico/matriculas/EditarMatricula'),
                },
                {
                  name: 'visualizar-matricula',
                  path: 'visualizar-matricula/:id?', // matriculaId
                  meta: {
                    title: i18n.t('MENU.MATRICULAS'),
                    middleware: verificaAutenticacao,
                    id: 'Matriculas',
                    permissoesNecessarias: ['V'],
                  },
                  component: () =>
                    import('@/views/academico/matriculas/VisualizarMatricula'),
                },
              ],
            },
          ],
        },
        /*
            Acadêmico: gerenciador de turmas
        */
        {
          path: 'gerenciador-turmas',
          component: () => import('@/layouts/blank-layout/Index'),
          children: [
            {
              name: 'gerenciador-turmas',
              path: '/gerenciador-turmas',
              meta: {
                title: i18n.t('MENU.GERENCIADOR_TURMAS'),
                middleware: verificaAutenticacao,
                id: 'GerenciadorTurmas',
              },
              component: () =>
                import('@/views/academico/gerenciador-turmas/Index'),
            },
          ],
        },
        /*
            Acadêmico: boletins
        */
        {
          path: 'boletins',
          component: () => import('@/layouts/blank-layout/Index'),
          children: [
            {
              name: 'academico-boletins',
              path: '/academico/boletins',
              meta: {
                title: i18n.t('MENU.BOLETINS'),
                middleware: verificaAutenticacao,
                id: 'Boletins',
              },
              component: () => import('@/views/academico/boletins/Index'),
            },
          ],
        },
        /*
                    Acadêmico: contratos
                */

        {
          path: 'contratos',
          component: () => import('@/layouts/blank-layout/Index'),
          children: [
            {
              name: 'contratos',
              path: '/',
              meta: {
                title: i18n.t('MENU.CONTRATOS'),
                middleware: verificaAutenticacao,
                id: 'Contratos',
              },
              component: () => import('@/views/academico/contratos/Index'),
            },
            {
              name: 'contratos-formulario',
              path: 'form/:id?/:visualizar?/:matriculaId?',
              meta: {
                title: i18n.t('MENU.CONTRATOS'),
                middleware: verificaAutenticacao,
                id: 'Contratos',
                permissoesNecessarias: ['C', 'E'],
              },
              component: () => import('@/views/academico/contratos/Formulario'),
            },
            {
              name: 'aditivos',
              path: 'aditivos',
              meta: {
                title: i18n.t('MENU.ADITIVOS_CONTRATO'),
                middleware: verificaAutenticacao,
                id: 'Contrato',
                permissoesNecessarias: ['C', 'V'],
              },
              component: () =>
                import('@/views/academico/aditivo-contratos/Index'),
            },
            {
              name: 'formulario-aditivo',
              path: 'aditivos/formulario/:id?/',
              meta: {
                title: i18n.t('MENU.ADITIVOS_CONTRATO'),
                middleware: verificaAutenticacao,
                id: 'Contrato',
                permissoesNecessarias: ['C', 'V'],
              },
              component: () =>
                import(
                  '@/views/academico/contratos/formulario-novo-aditivo/Index'
                ),
            },
            {
              name: 'formulario-aditivo-turma',
              path: 'aditivos-turma/formulario',
              meta: {
                title: i18n.t('MENU.ADITIVOS_CONTRATO'),
                middleware: verificaAutenticacao,
                id: 'Contrato',
                permissoesNecessarias: ['C', 'V'],
              },
              component: () =>
                import(
                  '@/views/academico/aditivo-contratos/formulario-novo-aditivo-turma/Index'
                ),
            },
          ],
        },

        /*
                    Acadêmico: relatórios
                */
        {
          path: 'relatorios',
          component: () => import('@/layouts/blank-layout/Index'),
          children: [
            {
              name: 'relatorio-disciplinas',
              path: '/relatorio-disciplinas',
              meta: {
                title: i18n.t('MENU.RELATORIO_DISCIPLINA'),
                middleware: verificaAutenticacao,
                id: 'RelatorioDisciplinas',
              },
              component: () =>
                import(
                  '@/views/academico/relatorios/relatorio-disciplinas/Index'
                ),
            },
            {
              name: 'relatorio-turmas-para-aluno',
              path: '/relatorio-turmas-para-aluno',
              meta: {
                title: i18n.t('MENU.RELATORIO_TURMAS_PARA_ALUNO'),
                middleware: verificaAutenticacao,
                id: 'RelatorioTurmasParaAluno',
              },
              component: () =>
                import(
                  '@/views/academico/relatorios/relatorio-turmas-aluno/Index'
                ),
            },
            {
              name: 'relatorio-descontos-para-contratos',
              path: '/relatorio-descontos-para-contratos',
              meta: {
                title: i18n.t('MENU.RELATORIO_DESCONTOS_PARA_CONTRATOS'),
                middleware: verificaAutenticacao,
                id: 'RelatorioDescontosParaContratos',
              },
              component: () =>
                import(
                  '@/views/academico/relatorios/relatorio-descontos-para-contratos/Index'
                ),
            },
            {
              name: 'relatorio-valores-para-contratos',
              path: '/relatorio-valores-para-contratos',
              meta: {
                title: i18n.t('MENU.RELATORIO_VALORES_PARA_CONTRATOS'),
                middleware: verificaAutenticacao,
                id: 'RelatorioValoresParaContrato',
              },
              component: () =>
                import(
                  '@/views/academico/relatorios/relatorio-valores-contratos/Index'
                ),
            },
            {
              name: 'relatorios-situacao-contrato',
              path: '/relatorios-situacao-contrato',
              meta: {
                title: i18n.t('MENU.RELATORIO_SITUACAO_CONTRATO'),
                middleware: verificaAutenticacao,
                id: 'RelatorioSituacaoContrato',
              },
              component: () =>
                import(
                  '@/views/academico/relatorios/relatorio-situacao-contrato/Index'
                ),
            },
            {
              name: 'relatorios-turmas',
              path: '/relatorios-turmas',
              meta: {
                title: i18n.t('MENU.RELATORIOS_TURMAS'),
                middleware: verificaAutenticacao,
                id: 'RelatoriosTurmas',
              },
              component: () =>
                import('@/views/academico/relatorios/relatorios-turmas/Index'),
            },
            {
              name: 'relatorio-irmaos',
              path: '/relatorio-irmaos',
              meta: {
                title: i18n.t('MENU.RELATORIO_IRMAOS'),
                middleware: verificaAutenticacao,
                id: 'Matriculas',
                permissoesNecessarias: ['V'],
              },
              component: () =>
                import('@/views/academico/relatorios/relatorio-irmaos/Index'),
            },
            {
              name: 'relatorio-totalizadores',
              path: '/relatorio-totalizadores',
              meta: {
                title: i18n.t('MENU.RELATORIO_TOTALIZADORES'),
                middleware: verificaAutenticacao,
                id: 'Turma',
                permissoesNecessarias: ['V'],
              },
              component: () =>
                import(
                  '@/views/academico/relatorios/relatorio-totalizadores/Index'
                ),
            },
            {
              name: 'relatorios-historico-escolar',
              path: '/relatorios-historico-escolar',
              meta: {
                title: i18n.t('MENU.RELATORIOS_HISTORICO_ESCOLAR'),
                middleware: verificaAutenticacao,
                id: 'RelatoriosHistoricoEscolar',
              },
              component: () =>
                import(
                  '@/views/academico/relatorios/relatorio-historico-escolar/Index'
                ),
            },
            {
              name: 'relatorio-ficha-socioeconomica',
              path: '/relatorio-ficha-socioeconomica',
              meta: {
                title: i18n.t('MENU.FICHA_SOCIOECONOMICA'),
                middleware: verificaAutenticacao,
                id: 'RelatorioFichaSocioeconomica',
              },
              component: () =>
                import(
                  '@/views/academico/relatorios/relatorio-ficha-socioeconomica/Index'
                ),
            },
          ],
        },
        /*
          Acadêmico: contratos
      */
        {
          path: 'contratos',
          component: () => import('@/layouts/blank-layout/Index'),
          children: [
            {
              name: 'contratos',
              path: '/',
              meta: {
                title: i18n.t('MENU.CONTRATOS'),
                middleware: verificaAutenticacao,
                id: 'Contratos',
              },
              component: () => import('@/views/academico/contratos/Index'),
            },
            {
              name: 'contratos-formulario',
              path: 'form/:id?/',
              meta: {
                title: i18n.t('MENU.CONTRATOS'),
                middleware: verificaAutenticacao,
                id: 'Contratos',
                permissoesNecessarias: ['C', 'E'],
              },
              component: () => import('@/views/academico/contratos/Formulario'),
            },
          ],
        },
        /*
            Contábil
        */
        {
          path: 'contabil',
          component: () => import('@/layouts/blank-layout/Index'),
          children: [
            {
              name: 'contabil-fechamento',
              path: '/contabil/fechamento',
              meta: {
                title: i18n.t('MENU.FECHAMENTO_CONTABIL'),
                middleware: verificaAutenticacao,
                id: 'ContabilFechamento',
              },
              component: () => import('@/views/contabil/fechamento/Index'),
            },
            {
              name: 'contabil-lancamentos',
              path: '/contabil/lancamentos',
              meta: {
                title: i18n.t('MENU.LANCAMENTOS_NF'),
                middleware: verificaAutenticacao,
                id: 'ContabilLancamentos',
              },
              component: () => import('@/views/contabil/lancamentos/Index'),
            },
            {
              name: 'contabil-periodos',
              path: '/contabil/periodos',
              meta: {
                title: i18n.t('MENU.PERIODOS_CONTABEIS'),
                middleware: verificaAutenticacao,
                id: 'ContabilPeriodos',
              },
              component: () => import('@/views/contabil/periodos/Index'),
            },
            {
              name: 'contabil-plano-contas',
              path: '/contabil/plano-contas',
              meta: {
                title: i18n.t('MENU.PLANO_CONTAS'),
                middleware: verificaAutenticacao,
                id: 'ContabilPlanoContas',
              },
              component: () => import('@/views/contabil/plano-contas/Index'),
            },
            {
              name: 'contabil-lancamento-por-periodo',
              path: '/contabil/lancamento-por-periodo',
              meta: {
                title: i18n.t('MENU.RELATORIO_LANCAMENTOS_POR_PERIODO'),
                middleware: verificaAutenticacao,
                id: 'ContabilPeriodos',
              },
              component: () =>
                import(
                  '@/views/contabil/relatorios/relatorio-lancamentos-por-periodo/Index'
                ),
            },
            {
              name: 'contabil-balancete',
              path: '/contabil/balancete',
              meta: {
                title: i18n.t('MENU.RELATORIO_BALANCETE'),
                middleware: verificaAutenticacao,
                id: 'ContabilBalancete',
              },
              component: () =>
                import('@/views/contabil/relatorios/relatorio-balancete/Index'),
            },
            {
              name: 'contabil-diario',
              path: '/contabil/diario',
              meta: {
                title: i18n.t('MENU.RELATORIO_DIARIO'),
                middleware: verificaAutenticacao,
                id: 'ContabilDiario',
              },
              component: () =>
                import('@/views/contabil/relatorios/relatorio-diario/Index'),
            },
            {
              name: 'contabil-razao',
              path: '/contabil/razao',
              meta: {
                title: i18n.t('MENU.RELATORIO_RAZAO'),
                middleware: verificaAutenticacao,
                id: 'ContabilDiario',
              },
              component: () =>
                import('@/views/contabil/relatorios/relatorio-razao/Index'),
            },
          ],
        },
        // Contas-receber
        {
          path: 'financeiro-contas-a-receber',
          component: () => import('@/layouts/blank-layout/Index'),
          children: [
            {
              name: 'financeiro-contas-a-receber',
              path: '/financeiro/contas-a-receber',
              meta: {
                title: i18n.t('MENU.LANCAMENTO_CONTAS_RECEBER'),
                middleware: verificaAutenticacao,
                id: 'ContasReceber',
              },
              component: () =>
                import('@/views/financeiro/lancamentos/contas-receber/Index'),
            },
            {
              name: 'financeiro-contas-receber-baixar-titulo',
              path: '/financeiro/contas-a-receber/baixar-titulo',
              meta: {
                title: i18n.t('MENU.LANCAMENTO_CONTAS_RECEBER'),
                middleware: verificaAutenticacao,
                id: 'BaixarTitulo',
                permissoesNecessarias: ['C', 'E'],
              },
              component: () =>
                import(
                  '@/views/financeiro/lancamentos/contas-receber/baixar-titulo/Index.vue'
                ),
            },
            {
              name: 'financeiro-contas-receber-baixar-titulos',
              path: '/financeiro/contas-a-receber/baixar-titulos',
              meta: {
                title: i18n.t('MENU.LANCAMENTO_CONTAS_RECEBER'),
                middleware: verificaAutenticacao,
                id: 'BaixarTitulo',
                permissoesNecessarias: ['C', 'E'],
              },
              component: () =>
                import(
                  '@/views/financeiro/lancamentos/contas-receber/baixar-titulos/Index'
                ),
            },
            {
              name: 'contas-pagar-relatorio-analise-retroativa',
              path: '/financeiro/relatorio/contas-a-pagar/analise-retroativa',
              meta: {
                title: i18n.t('MENU.IMPRIMIR_RELATORIO_ANALISE_RETROATIVA_CONTAS_PAGAR'),
                middleware: verificaAutenticacao,
                id: 'RelatorioAnaliseRetroativaContasPagar',
                permissoesNecessarias: ['V'],
              },
              component: () =>
                import(
                  '@/views/financeiro/relatorios/RelatorioAnaliseRetroativaContasPagar/Index'
                ),
            },
            {
              name: 'contas-receber-relatorio-analise-retroativa',
              path: '/financeiro/relatorio/contas-a-receber/analise-retroativa',
              meta: {
                title: i18n.t('MENU.IMPRIMIR_RELATORIO_ANALISE_RETROATIVA_CONTAS_RECEBER'),
                middleware: verificaAutenticacao,
                id: 'ContasReceber',
                permissoesNecessarias: ['V'],
              },
              component: () =>
                import(
                  '@/views/financeiro/relatorios/RelatorioAnaliseRetroativaContasReceber/Index'
                ),
            },
            {
              name: 'relatorio-faturamento-declaracao-imposto-renda',
              path: '/financeiro/relatorio/faturamento/declaracao-imposto-renda',
              meta: {
                title: i18n.t('MENU.RELATORIO_DECLARACAO_IMPOSTO_RENDA'),
                middleware: verificaAutenticacao,
                id: 'LancamentoCaixa',
                permissoesNecessarias: ['V'],
              },
              component: () =>
                import(
                  '@/views/financeiro/relatorios/RelatorioImpostoRenda/Index'
                ),
            },
            {
              name: 'relatorio-faturamento',
              path: '/financeiro/relatorio/faturamento',
              meta: {
                title: i18n.t('MENU.RELATORIO_FATURAMENTO'),
                middleware: verificaAutenticacao,
                id: 'LancamentoCaixa',
                permissoesNecessarias: ['V'],
              },
              component: () =>
                import(
                  '@/views/financeiro/relatorios/RelatorioFaturamento/Index'
                ),
            },
          ],
        },
        /*
                  Home/Dashboard
                */
        {
          name: 'dashboard',
          path: '/dashboard',
          meta: {
            title: i18n.t('MENU.HOME'),
            middleware: verificaAutenticacao,
            id: 'Dashboard',
          },
          component: () => import('@/views/home/Index'),
        },
        // RH
        {
          path: 'rh',
          component: () => import('@/layouts/blank-layout/Index'),
          children: [
            // Acessos
            {
              name: 'acessos',
              path: '/acessos',
              component: () => import('@/layouts/blank-layout/Index'),
              children: [
                {
                  name: 'perfis',
                  path: '/perfis',
                  meta: {
                    title: i18n.t('MENU.PERFIS'),
                    middleware: verificaAutenticacao,
                    id: 'Perfil',
                  },
                  component: () => import('@/views/acessos/perfis/Index'),
                },
                {
                  name: 'perfil-permissao',
                  path: '/perfis/:id?/permissao',
                  meta: {
                    title: i18n.t('MENU.PERFIS'),
                    middleware: verificaAutenticacao,
                    id: 'Permissoes',
                  },
                  component: () => import('@/views/acessos/perfis/Permissao'),
                },
              ],
            },
            // Pessoas
            {
              name: 'pessoas',
              path: '/pessoas',
              component: () => import('@/layouts/blank-layout/Index'),
              children: [
                {
                  name: 'pessoas',
                  path: '/pessoas',
                  meta: {
                    title: i18n.t('MENU.PESSOAS'),
                    middleware: verificaAutenticacao,
                    id: 'Pessoas',
                  },
                  component: () => import('@/views/rh/pessoas/Index'),
                },
                {
                  name: 'nova-pessoa',
                  path: '/pessoas/nova-pessoa',
                  meta: {
                    title: i18n.t('MENU.PESSOAS'),
                    middleware: verificaAutenticacao,
                    id: 'Pessoas',
                    permissoesNecessarias: ['C', 'E'],
                  },
                  component: () => import('@/views/rh/pessoas/Formulario'),
                },
                {
                  name: 'editar-pessoa',
                  path: '/pessoas/:id?/',
                  meta: {
                    title: i18n.t('MENU.PESSOAS'),
                    middleware: verificaAutenticacao,
                    id: 'Pessoas',
                    permissoesNecessarias: ['C', 'E'],
                  },
                  component: () => import('@/views/rh/pessoas/Formulario'),
                },
              ],
            },
            // Funcionários
            {
              name: 'funcionarios',
              path: '/funcionarios',
              meta: {
                title: i18n.t('MENU.FUNCIONARIOS'),
                middleware: verificaAutenticacao,
                id: 'Funcionarios',
              },
              component: () => import('@/views/rh/funcionarios/Index'),
            },
            {
              name: 'protocolos',
              path: '/protocolos',
              meta: {
                title: i18n.t('MENU.PROTOCOLOS'),
                middleware: verificaAutenticacao,
                id: 'Protocolo',
              },
              component: () => import('@/views/rh/protocolos/Index'),
            },
            // Usuários
            {
              name: 'usuarios',
              path: '/usuarios',
              meta: {
                title: i18n.t('MENU.USUARIOS'),
                middleware: verificaAutenticacao,
                id: 'Usuario',
              },
              component: () => import('@/views/acessos/usuarios/Index'),
            },
          ],
        },
        // Período letivo
        {
          path: 'periodo-letivo',
          component: () => import('@/layouts/blank-layout/Index'),
          children: [
            {
              name: 'periodo-letivo',
              path: '/periodo-letivo',
              meta: {
                title: i18n.t('MENU.PERIODO_LETIVO'),
                middleware: verificaAutenticacao,
                id: 'AnoLetivo',
              },
              component: () =>
                import('@/views/periodos-letivos/periodo-letivo/Index'),
            },
            {
              name: 'criar-periodo-letivo',
              path: '/periodo-letivo/criar-periodo-letivo',
              meta: {
                title: i18n.t('MENU.PERIODO_LETIVO'),
                middleware: verificaAutenticacao,
                id: 'AnoLetivo',
              },
              component: () =>
                import('@/views/periodos-letivos/periodo-letivo/Formulario'),
            },
            {
              name: 'editar-periodo-letivo',
              path: '/periodo-letivo/editar-periodo-letivo/:id?/',
              meta: {
                title: i18n.t('MENU.PERIODO_LETIVO'),
                middleware: verificaAutenticacao,
                id: 'AnoLetivo',
              },
              component: () =>
                import('@/views/periodos-letivos/periodo-letivo/Formulario'),
            },
          ],
        },
        // Fechamento
        {
          path: 'fechamento-periodo-letivo',
          component: () => import('@/layouts/blank-layout/Index'),
          children: [
            {
              name: 'fechamento-periodo-letivo',
              path: '/fechamento-periodo-letivo',
              meta: {
                title: i18n.t('MENU.FECHAMENTO_PERIODO_LETIVO'),
                middleware: verificaAutenticacao,
                id: 'AnoLetivo',
              },
              component: () =>
                import('@/views/periodos-letivos/fechamento-periodo/Index'),
            },
          ],
        },
        // Fechamento cursoTurma
        {
          path: 'fechamento-cursos-turmas',
          component: () => import('@/layouts/blank-layout/Index'),
          children: [
            {
              name: 'fechamento-cursos-turmas',
              path: '/fechamento-cursos-turmas',
              meta: {
                title: i18n.t('MENU.FECHAMENTO_CURSOS_TURMAS'),
                middleware: verificaAutenticacao,
                id: 'AnoLetivo',
              },
              component: () =>
                import('@/views/periodos-letivos/fechamento-curso-turma/Index'),
            },
          ],
        },

        /*
          Financeiro Estrutura:
          Contém todos os CRUDS de entidades base que alimentam o projeto
        */
        {
          path: 'financeiro/estrutura',
          component: () => import('@/layouts/blank-layout/Index'),
          children: [
            {
              name: 'financeiro-bancos',
              path: '/financeiro/estrutura/bancos',
              meta: {
                title: i18n.t('MENU.BANCOS'),
                middleware: verificaAutenticacao,
                id: 'FinanceiroBancos',
              },
              component: () =>
                import('@/views/financeiro/estrutura/bancos/Index'),
            },
            {
              name: 'financeiro-contas',
              path: '/financeiro/estrutura/contas',
              meta: {
                title: i18n.t('MENU.CONTAS'),
                middleware: verificaAutenticacao,
                id: 'FinanceiroContas',
              },
              component: () =>
                import('@/views/financeiro/estrutura/contas/Index'),
            },
            {
              name: 'financeiro-fornecedores',
              path: '/financeiro/estrutura/fornecedores',
              meta: {
                title: i18n.t('MENU.FORNECEDORES'),
                middleware: verificaAutenticacao,
                id: 'FinanceiroFornecedores',
              },
              component: () =>
                import('@/views/financeiro/estrutura/fornecedores/Index'),
            },
            {
              name: 'financeiro-descontos',
              path: '/financeiro/estrutura/descontos',
              meta: {
                title: i18n.t('MENU.DESCONTOS'),
                middleware: verificaAutenticacao,
                id: 'FinanceiroDescontos',
              },
              component: () =>
                import('@/views/financeiro/estrutura/descontos/Index'),
            },
            // {
            //   name: 'financeiro-impostos',
            //   path: '/financeiro/estrutura/impostos',
            //   meta: {
            //     middleware: verificaAutenticacao,
            //     id: 'Financeiro',
            //   },
            //   component: () =>
            //     import('@/views/financeiro/estrutura/impostos/Index'),
            // },
            {
              name: 'financeiro-formas-pagamento',
              path: '/financeiro/estrutura/formas-pagamento',
              meta: {
                title: i18n.t('MENU.FORMAS_PAGAMENTO'),
                middleware: verificaAutenticacao,
                id: 'FinanceiroFormasPagamento',
              },
              component: () =>
                import('@/views/financeiro/estrutura/formas-pagamento/Index'),
            },
            {
              name: 'financeiro-precos',
              path: '/financeiro/estrutura/precos',
              meta: {
                title: i18n.t('MENU.PRECOS'),
                middleware: verificaAutenticacao,
                id: 'FinanceiroPrecos',
              },
              component: () =>
                import('@/views/financeiro/estrutura/precos/Index'),
            },
            {
              name: 'financeiro-taxas',
              path: '/financeiro/estrutura/taxas',
              meta: {
                title: i18n.t('FINANCEIRO.TAXAS'),
                middleware: verificaAutenticacao,
                id: 'FinanceiroTaxas',
              },
              component: () =>
                import('@/views/financeiro/estrutura/taxas/Index'),
            },
          ],
        },
        // Financeiro - Cobrança
        {
          path: 'financeiro-cobranca',
          component: () => import('@/layouts/blank-layout/Index'),
          children: [
            {
              name: 'financeiro-cobranca',
              path: '/financeiro/cobranca',
              meta: {
                title: i18n.t('MENU.COBRANCA'),
                middleware: verificaAutenticacao,
                id: 'FinanceiroCobranca',
              },
              component: () => import('@/views/financeiro/cobranca/Index'),
            },
          ],
        },
        // Financeiro - Caixa
        {
          path: 'financeiro-caixa',
          component: () => import('@/layouts/blank-layout/Index'),
          children: [
            {
              name: 'financeiro-caixa-fluxo',
              path: '/financeiro/caixa/fluxo',
              meta: {
                title: i18n.t('MENU.FLUXO_CAIXA'),
                middleware: verificaAutenticacao,
                id: 'FinanceiroCaixaFluxo',
              },
              component: () => import('@/views/financeiro/caixa/FluxoCaixa'),
            },
            {
              name: 'financeiro-caixa-periodo-financeiro',
              path: '/financeiro/caixa/periodo-financeiro',
              meta: {
                title: i18n.t('MENU.PERIODO_FINANCEIRO'),
                middleware: verificaAutenticacao,
                id: 'FinanceiroCaixaPeriodoFinanceiro',
              },
              component: () =>
                import('@/views/financeiro/caixa/PeriodoFinanceiro'),
            },
          ],
        },
        // Financeiro - Lançamentos - Histórico de Lançamentos:
        {
          path: 'financeiro-lancamentos-historico',
          component: () => import('@/layouts/blank-layout/Index'),
          children: [
            {
              name: 'financeiro-lancamentos-historico',
              path: '/financeiro/lancamentos/historico',
              meta: {
                title: i18n.t('MENU.HISTORICO_LANCAMENTOS'),
                middleware: verificaAutenticacao,
                id: 'FinanceiroLancamentosHistorico',
              },
              component: () =>
                import('@/views/financeiro/lancamentos/historico/Index'),
            },
          ],
        },
        // Financeiro - Lançamentos - Integração Financeira/Contábil:
        {
          path: 'financeiro-lancamentos-integracao-contabil',
          component: () => import('@/layouts/blank-layout/Index'),
          children: [
            {
              name: 'financeiro-lancamentos-integracao-contabil',
              path: '/financeiro/lancamentos/integracao-contabil',
              meta: {
                title: i18n.t('MENU.INTEGRACAO_FINANCEIRA_CONTABIL'),
                middleware: verificaAutenticacao,
                id: 'FinanceiroLancamentosIntegracaoContabil',
              },
              component: () =>
                import(
                  '@/views/financeiro/lancamentos/integracao-contabil/Index'
                ),
            },
          ],
        },
        // Painel professor
        {
          path: 'painel-professor',
          component: () => import('@/layouts/blank-layout/Index'),
          children: [
            {
              name: 'painel-professor',
              path: '/painel-professor',
              meta: {
                title: i18n.t('MENU.PAINEL_PROFESSOR'),
                middleware: verificaAutenticacao,
                id: 'PainelProfessor',
              },
              component: () =>
                import('@/views/academico/painel-professor/Index'),
            },
          ],
        },
        // Suprimentos
        {
          path: 'suprimentos',
          component: () => import('@/layouts/blank-layout/Index'),
          children: [
            {
              name: 'suprimentos-depositos',
              path: 'depositos',
              meta: {
                title: i18n.t('MENU.DEPOSITOS'),
                middleware: verificaAutenticacao,
                id: 'SuprimentosDepositos',
              },
              component: () =>
                import('@/views/suprimentos/depositos/Depositos'),
            },
            {
              name: 'suprimentos-estoques',
              path: 'estoques',
              meta: {
                title: i18n.t('MENU.ESTOQUE'),
                middleware: verificaAutenticacao,
                id: 'SuprimentosEstoques',
              },
              component: () => import('@/views/suprimentos/estoques/Estoques'),
            },
            {
              name: 'suprimentos-relatorio-estoque',
              path: 'relatorio-estoque',
              meta: {
                title: i18n.t('MENU.RELATORIO_ESTOQUE'),
                middleware: verificaAutenticacao,
                id: 'SuprimentosRelatorioEstoque',
              },
              component: () => import('@/views/suprimentos/relatorio-estoque/RelatorioEstoque'),
            },
            {
              name: 'suprimentos-lancamentos-nf',
              path: 'lancamentos-nf',
              meta: {
                title: i18n.t('MENU.LANCAMENTOS_NF'),
                middleware: verificaAutenticacao,
                id: 'SuprimentosLancamentosNF',
              },
              component: () =>
                import('@/views/suprimentos/lancamentos-nf/Index.vue'),
            },
            {
              name: 'criar-nf',
              path: 'lancamento-nf/criar-nf',
              meta: {
                title: i18n.t('MENU.LANCAMENTOS_NF'),
                middleware: verificaAutenticacao,
                id: 'SuprimentosLancamentoNF',
              },
              component: () =>
                import('@/views/suprimentos/lancamentos-nf/nota-fiscal/Criar'),
            },
            {
              name: 'suprimentos-lancamento-nf',
              path: 'lancamento-nf/nota/:id?',
              meta: {
                title: i18n.t('MENU.LANCAMENTOS_NF'),
                middleware: verificaAutenticacao,
                id: 'SuprimentosLancamentoNF',
              },
              component: () =>
                import(
                  '@/views/suprimentos/lancamentos-nf/nota-fiscal/Visualizar'
                ),
            },
            {
              name: 'suprimentos-produtos',
              path: 'produtos',
              meta: {
                title: i18n.t('MENU.PRODUTOS'),
                middleware: verificaAutenticacao,
                id: 'SuprimentosProdutos',
              },
              component: () => import('@/views/suprimentos/produtos/Produtos'),
            },
          ],
        },
        {
          path: 'financeiro-gerenciamento',
          component: () => import('@/layouts/blank-layout/Index'),
          children: [
            {
              name: 'financeiro-cobranca',
              path: '/financeiro/cobranca',
              meta: {
                title: i18n.t('MENU.COBRANCA'),
                middleware: verificaAutenticacao,
                id: 'FinanceiroCobranca',
              },
              component: () =>
                import('@/views/financeiro/gerenciamento/Cobranca'),
            },
            {
              name: 'financeiro-lancamentos',
              path: '/financeiro/lancamentos',
              meta: {
                title: i18n.t('MENU.LANCAMENTOS_CONTABEIS'),
                middleware: verificaAutenticacao,
                id: 'FinanceiroLancamentos',
              },
              component: () => import('@/views/financeiro/lancamentos/Index'),
            },
            {
              name: 'financeiro-relatorios',
              path: '/financeiro/relatorios',
              meta: {
                title: i18n.t('ACADEMICO.RELATORIOS'),
                middleware: verificaAutenticacao,
                id: 'FinanceiroRelatorios',
              },
              component: () =>
                import('@/views/financeiro/gerenciamento/Relatorios'),
            },
          ],
        },
        // Financeiro - contas a pagar:
        {
          path: 'financeiro-contas-pagar',
          component: () => import('@/layouts/blank-layout/Index'),
          children: [
            {
              name: 'financeiro-contas-pagar',
              path: '/financeiro/contas-pagar',
              meta: {
                title: i18n.t('FINANCEIRO.LANCAMENTOS_CONTAS_PAGAR'),
                middleware: verificaAutenticacao,
                id: 'FinanceiroContasPagar',
              },
              component: () =>
                import('@/views/financeiro/lancamentos/contas-pagar/Index'),
            },
            {
              name: 'financeiro-contas-pagar-baixar-titulo',
              path: '/financeiro/contas-pagar-baixar-titulo/:id?',
              meta: {
                title: i18n.t('FINANCEIRO.LANCAMENTOS_CONTAS_PAGAR'),
                middleware: verificaAutenticacao,
                id: 'FinanceiroContasPagar',
              },
              component: () =>
                import('@/views/financeiro/lancamentos/contas-pagar/components/BaixarTituloContasPagar'),
            },
            {
              name: 'contas-pagar-relatorio-analise-retroativa',
              path: '/financeiro/relatorio/contas-a-pagar/analise-retroativa',
              meta: {
                title: i18n.t('MENU.IMPRIMIR_RELATORIO_ANALISE_RETROATIVA_CONTAS_PAGAR'),
                middleware: verificaAutenticacao,
                id: 'RelatorioAnaliseRetroativaContasPagar',
                permissoesNecessarias: ['V'],
              },
              component: () =>
                import(
                  '@/views/financeiro/relatorios/RelatorioAnaliseRetroativaContasPagar/Index'
                ),
            },
          ],
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/layouts/blank-layout/Index'),
      children: [
        {
          name: 'Login',
          path: 'login',
          meta: {
            middleware: redirecionaSeAutenticado,
          },
          component: () => import('@/views/autenticacao/Login'),
        },

        {
          name: 'Cadastro',
          path: 'cadastro',
          meta: {
            middleware: redirecionaSeAutenticado,
          },
          component: () => import('@/views/autenticacao/Cadastro'),
        },
        {
          name: 'esqueceu-sua-senha',
          path: 'esqueceu-sua-senha/',
          meta: {
            middleware: redirecionaSeAutenticado,
          },
          component: () => import('@/views/autenticacao/EsqueceuSenha'),
        },
      ],
    },
    {
      path: '*',
      redirect: '/login',
      //component: () => import("@/views/authentication/Error"),
    },
    {
      path: '/',
      redirect: '/login',
      //component: () => import("@/views/authentication/Error"),
    },
  ],
});

import NProgress from 'nprogress';

router.beforeResolve((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : 'Colégio Evangélico Jaraguá'
  if (to.name) {
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  NProgress.done();
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];

  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
