export const InstituicaoEnsino = {
    ANOS_SERIES: "Years / Grades",
    CNPJ: "CNPJ",
    CONTATOS: "Contacts",
    CONTATO_INSTITUICAO: "Institution contact",
    CPF_CNPJ: "CPF / CNPJ",
    ENDERECO_INSTITUICAO: "Institution address",
    ENDERECOS: "Addresses",
    DISCIPLINA: "Subject",
    DADOS_INSTITUICAO_ENSINO: "Educational institution data",
    INSTITUICAO_ENSINO: "Educational institution",
    FORNECEDOR: "Supplier",
    NOME: 'Name',
    RAZÃO_SOCIAL: 'Business name',
}
