export const CamposValidacao = {
    CAMPO_INVALIDO: "Invalid field",
    CAMPO_REQUERIDO: "Required field",
    CELULAR_INVALIDO: "Cell is not valid",
    CEP_INVALIDO: "CEP is not valid",
    CEP_INEXISTENTE: "Could not find zip code",
    COMPRIMENTO_MINIMO: "Minimum field length of {length} characters",
    COMPRIMENTO_MAXIMO: "Maximum field length of {length} characters",
    EMAIL_INVALIDO: "Email is not valid",
    INVALIDO: "The {name} field is invalid",
    FAX_INVALIDO: "Fax is not valid",
    LOGIN_INVALIDO: "The login detail is incorrect",
    NAO_ENCONTRADO: "The requested {name} is not found",
    NUMERO_RESIDENCIAL_INVALIDO: 'Invalid home number',
    NUMERO_COMERCIAL_INVALIDO: 'Invalid commercial number',
    REQUERIDO: "Please enter the field {name}",
    SENHA_E_CONFIRMACAO: "The passwords entered do not match",
    TAMANHO: "The {name} field must be {length} characters",
    TAMANHO_MINIMO: "The {name} field must be at least {min} characters",
    TERMOS_CONDICOES: "Accepting terms & conditions are required",
    VALOR_MAXIMO: "Maximum value of field is {max}",
    VALOR_MINIMO: "Minimum value of field is {min}",
}