export default {
  state: {
    lancamentoSelecionados: [],
  },
  
  getters: {
    lancamentoSelecionados(state) {
      return state.lancamentoSelecionados;
    },
  },

  mutations: {
    alterarDadosLancamento(state, payload) {
      state.lancamentoSelecionados = payload;
    },
  },
};
