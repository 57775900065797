export const Suprimentos = {
  // Letra A:
  ACOES: 'Actions',

  // Letra B:
  BUSCAR_PEDIDO: 'Search order',

  // Letra C:
  CNPJ: 'CNPJ',

  // Letra D:
  DATA: 'Date',
  DATA_CADASTRO: 'Registration date',
  DATA_ENTREGA: 'Delivery date',
  DATA_EMISSAO: 'Issuance date',
  DATA_LANCAMENTO: 'Posting date',
  DADOS_PRODUTO: 'Product information',
  DATA_VENCIMENTO: 'Due date',
  DETALHES_MOVIMENTO: 'Movement details',
  DEPOSITO: 'Deposit',
  DEPOSITOS: 'Deposits',
  DEPOSITO_DESCRICAO:
    "This screen allows you to register a new deposit. Fill in the necessary information, including the description of the deposit and select the corresponding posting history. Click 'Save' to complete the deposit registration. You can also view and manage existing deposits on this screen.",
  DESCRICAO: 'Description',
  DESCRICAO_TELA:
    'On this screen you can remove products from stock, move products between warehouses, and also view a list of product movements per product and warehouse filter.',
  DESCRICAO_MOVIMENTO_RETIRANTE: 'Movement/withdrawer description',
  TRANSFERENCIA_REALIZADA: 'Transfer completed',

  // Letra E:
  ESTOQUES: 'Stocks',
  ESTOQUE: 'Stock',

  // Letra F:
  FAMILIAS: 'Families',
  FORMA_PAGAMENTO: 'Payment method',
  FORNECEDOR: 'Supplier',
  FORNECEDORES: 'Suppliers',

  // Letra G:
  // Letra H:
  HISTORICO: 'History',

  // Letra I:
  ITEM: 'Item',
  ITENS_PEDIDO: 'Order items',
  IMPOSTOS: 'Taxes',
  IMPOSTO_PIS_COFINS_CSLL: 'PIS/CONFINS/CSLL',
  IMPOSTO_INSS: 'INSS',
  IMPOSTO_ISS: 'ISS',
  IMPOSTO_IR: 'IR',

  // Letra J:
  // Letra K:
  // Letra L:
  LANCAMENTOS_NF: 'NF postings',
  LISTAR_ESTOQUE: 'List stock',


  // Letra M:
  // Letra N:
  NOME: 'Name',
  NOME_FANTASIA: 'Trade name',
  NUMERO_NF: 'Invoice number',

  // Letra O:
  OBSERVACOES: 'Observations',

  // Letra P:
  PEDIDO: 'Order',
  PLANO_PARCELAMENTO: 'Installment plan',
  PRODUTO: 'Product',
  PRODUTOS: 'Products',
  PRODUTOS_DESCRICAO:
    'On this screen, you can list, create, edit, and delete products.',
  // Letra Q:
  QUANTIDADE: 'Quantity',
  QUANTIDADE_TOTAL: 'Total quantity',
  QUANTIDADE_MINIMA: 'Minimum quantity',

  // Letra R:
  RAZAO_SOCIAL: 'Company name',

  // Letra S:
  SELECIONAR_DEPOSITO: 'Select deposit',
  SELECIONAR_DEPOSITO_PLACEHOLDER: 'Type or select',
  SELECIONAR_PRODUTO: 'Select product',
  SELECIONAR_PRODUTO_PLACEHOLDER: 'Type or select',
  STATUS: 'Status',
  SUPRIMENTOS: 'Supplies',

  SELECIONE_DEPOSITO_PARA_TRANSFERENCIA: 'Select the transfer destination:',
  SELECIONE_PRODUTO_PARA_RETIRADA: 'Select the product for withdrawal:',
  SELECIONE_PRODUTO_PARA_TRANSFERENCIA: 'Select the product for transfer:',
  SELECIONE_DEPOSITO_PARA_RETIRADA: 'Select the current product warehouse:',
  SELECIONE_DEPOSITO_ANTIGO: 'Current warehouse:',
  SELECIONE_DEPOSITO_NOVO: 'New warehouse:',
  SAIDA_MANUAL_PRODUTO: 'Manual stock withdrawal',

  // Letra T:
  TIPO: 'Type',
  TIPO_MOVIMENTO: 'Movement type',
  TIPO_IMPOSTO: 'Tax type',

  TRANSFERENCIA_DEPOSITOS: 'Depot transfer',

  // Letra U:UNIDADE_MEDIDA
  UNIDADE_MEDIDA: 'Unit of measure',

  // Letra V:
  VALOR: 'Value',
  VALOR_UNITARIO: 'Unit value',
  // Letra X:
  // Letra Y:
  // Letra Z:
};
