export const Admin = {
    // Letra A:
    // Letra B:
    // Letra C:
    CONFIGURACOES: 'Settings',

    // Letra D:
    // Letra E:
    // Letra F:
    // Letra G:
    // Letra H:
    // Letra I:
    // Letra J:
    // Letra K:
    // Letra L:
    // Letra M:
    // Letra N:
    // Letra O:
    // Letra P:
    PERFIS_DESCRICAO: 'Click the checkboxes to enable or disable permissions for each screen.',
    PERFIS: 'Profiles',
    PERMISSOES_PERFIL: 'Profile permissions',

    // Letra Q:
    // Letra R:
    // Letra S:
    // Letra T:
    // Letra U:
    // Letra V:
    // Letra X:
    // Letra Y:
    // Letra Z:
}