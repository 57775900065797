export const Academico = {
  // Letter A:
  ACADEMICO: 'Academic',
  ADICIONAR_DOCENTE: 'Add faculty',
  ANO_LETIVO: 'Academic year',
  AULA: 'Class',
  AVALIACAO: 'Evaluation',
  AVISO_NAO_PODE_DESFAZER_ACAO: 'ATTENTION: This action cannot be undone!',
  AGENCIA_CONTA: 'Account Agency',
  AGENCIA_CONTA_PLACE: 'Enter the account agency number',
  ABRIR_SEGMENTACAO: 'Open segmentation',
  ADITIVO_DE_CONTRATO: 'Contract addendum',
  ALTERACOES: 'Changes',
  ADITIVOS_DE_CONTRATO: 'Contract addenda',
  ADICIONAR_DESCONTO: 'Add discount(s)',
  ADITIVO_DE_CONTRATO_DESCRICAO:
    'On this screen, you can view all contracts with addenda and add addenda to a class',
  ALUNO: 'Student',
  ADICIONAR_PRECO: 'Add price(s)',
  ATIVAR_CONTRATO: 'Activate contract',
  ATIVAR_CONTRATO_SUCESSO: '',

  PREENCHA_OS_CAMPOS_OBRIGATORIOS:
    'Before submitting, make sure the mandatory fields are filled out',
  // Letter B:
  BAIXAR_CONTRATO: 'Download contract',
  BOLETIM: 'Reports',
  BOLETIM_DESCRICAO:
    'This screen provides the functionality to filter and list students, enabling the processing of individual student reports. Use the filter to search.',
  BUSCAR_NOME_CODIGO_MATRICULA: 'Search by name or student code:',
  BANCOS: 'Banks',
  BAIRRO: 'Neighborhood',

  // Letter C:
  NOVO_CONTRATO: 'New Contract',
  CANCELAR_CONTRATO: 'Cancel',
  CARGA_HORARIA: 'Hours of instruction',
  COMPETENCIA: 'Competence',
  CONFIRMAR_ALTERACAO_STATUS:
    'Do you confirm the change of contract status to {status}?',
  CONFIRMAR_EXCLUSAO_DE: 'Confirm deletion of {item}',
  CONFIRMAR_EXCLUIR_AULA: 'Are you sure you want to delete the class?',
  CONFIRMAR_EXCLUIR_AVALIACAO:
    'Are you sure you want to delete the evaluation?',
  CONFIRMAR_CANCELAMENTO_CONTRATO:
    'Are you sure you want to cancel the contract?',
  CONFIRMAR_MOTIVO_CANCELAMENTO:
    'Do you want to create a cancellation reason for the contract?',
  CONFIRMAR_EXCLUIR_DISCIPLINA: 'Are you sure you want to delete the subject?',
  CONFIRMAR_ATIVAR_CONTRATO: 'Are you sure you want to activate this contract?',
  CONTEUDO_PROGRAMATICO: 'Curriculum content',
  CONTRATO: 'Contract',
  CONTRATO_CADASTRADO: 'Contract saved',
  CONTRATO_ADICIONE_PRECO: 'Add at least one price to the contract',
  CONTRATO_MENSAGEM_EXCLUIDO: 'Contract deleted successfully',
  CONTRATOS: 'Contracts',
  CONTRATOS_DESCRICAO:
    'Click on search to select a student and view their respective contracts or register:',
  CURSO: 'Course',
  CURSO_CONFIRMAR_EXCLUSAO_TITULO: 'Confirm deletion of course {course}',
  CURSOS: 'Courses',
  CURSOS_CEJ_DESCRICAO:
    'This is the CEJ courses page. You can register using the button at the top right or edit/delete by clicking on "actions" on the desired row.',
  CURSOS_CADASTRAR: 'Register course',
  CARGOS: 'Position',
  CARTEIRA_PROFISSIONAL: 'Professional ID',
  CARTEIRA_PROFISSIONAL_PLACE: 'Enter the professional ID number',
  CONTA_BANCARIA: 'Bank account',
  CONTA_BANCARIA_PLACE: 'Enter the bank account number',
  CONTRATOS_COM_ADITIVO: 'Contracts with addenda',
  CODIGO: 'Code',
  CONTRATOS_TURMA: 'Contracts by class',
  CONTRATO_ATIVO: 'Status changed successfully',
  CAMPOS_PARA_INCLUIR_NO_RELATORIO: 'Fields to include in the report',
  CELULAR: 'Cell phone',
  CEP: 'ZIP code',
  CIDADE: 'City',
  CPF: 'CPF',
  CONFIG_RELATORIO: 'Report options',
  CONTRATO_DESCRICAO_VISUALIZAR:
    'On this screen, you can view contract details, including the installments generated from it.',
  CONTRATO_DESCRICAO_CRIAR_EDITAR: `In this screen, you can create or edit contracts based on the information provided in the form, including prices and discounts. After filling out the details, you will be able to view the installment plan. Pay close attention to the "Contract Status" field, where you can choose whether you want to activate the contract as soon as it's created.`,
  CADASTRAR_TURMA: 'Register a new class',
  CURSO_DESCRICAO:
    'On this screen you can view, edit and delete all the classes for the selected course.',
  CURRICULO_ESCOLAR: 'School curriculum',

  // Letter D:
  DATA: 'Date',
  DATA_AVALIACAO: 'Evaluation date',
  DATA_RECUPERACAO: 'Recovery date',
  DATA_VENCIMENTO: 'Due date',
  DATA_LANCAMENTO: 'Release date',
  DATA_DE_GERACAO: 'Generation date',
  DATA_GERACAO_INICIO: 'Start generation date',
  DATA_GERACAO_FINAL: 'End generation date',
  DATA_INATIVACAO_INICIO: 'Start deactivation date',
  DATA_INATIVACAO_FINAL: 'End deactivation date',
  DISCIPLINA: 'Subject',
  DISCIPLINA_CURRICULAR: 'Curricular discipline',
  DISCIPLINA_CADASTRAR: 'Register subject',
  DISCIPLINA_EXCLUIDA: 'Subject deleted!',
  DISCIPLINAS: 'Subjects',
  DISCIPLINAS_DESCRICAO:
    'On this screen, you can register, edit, and delete subjects. To do so, click on <strong>Create</strong> or on the <strong>actions</strong> button in the table.',
  DISCIPLINAS_CEJ_DESCRICAO:
    'This is the CEJ subjects page. Click the button below to use the filter:',
  DOCENTES: 'Faculty',
  DOCENTES_PERIODO: 'Faculty of the period',
  DOCENTES_DO_PERIODO_DESCRICAO:
    'This screen allows you to link a professor to a specific class during a determined period.',
  DADOS_GERAIS_DO_ADITIVO: 'General data',
  DESCONTOS: 'Discounts',
  DESCRICAO_FORM_PRECOS:
    'Here you should include the prices of the addendum, select the payment method for each one, and see how the installments are distributed',
  DETALHES_DO_MOTIVO: 'Reason details',
  DESCRICAO_FORM_DESCONTOS: 'Here you can add discounts for this addendum',
  DESCRICAO_FORMULARIO_CONTRATOS_TURMA_ADITIVO:
    'Fill out the filter to bring the class contracts, then select the contracts you want to add the addendum to',
  DOCUMENTO_PADRAO: 'Standard Document',
  DOCUMENTOS_PADRAO_DESCRICAO:
    'On this screen, you can manage standard system documents and also view the terms guide.',
  DATA_EXPEDICAO: 'Expedition date',
  DATA_MATRICULA: 'Enrollment date',
  DATA_NASCIMENTO: 'Date of birth',
  DADOS_ALUNO: 'Student information',
  DADOS_CONTATO: 'Contact information',
  DADOS_ENDERECO: 'Address information',
  DADOS_RESPONSAVEIS: 'Guardian information',
  DATA_CRIACAO: 'Date created',

  // Letter E:
  ESTRUTURA_CEJ: 'CEJ structure',
  ESTRUTURA_CEJ_DESCRICAO:
    'Click on the desired card below to manage the structure, adding, editing, and/or deleting items:',
  ESTRUTURA_OUTRAS_INSTITUICOES: 'Structure of other institutions',
  ESTRUTURA_OUTRAS_INSTITUICOES_DESCRICAO:
    'Click on the desired card below to manage the structure, adding, editing, and/or deleting items:',
  EMENTA: 'Syllabus',
  ENCERRAR_PERIODO_LETIVO: 'Close academic period',
  EDITAR_DOCUMENTO_PADRAO: 'Edit standard document',
  EDITAR_DOCUMENTO_PADRAO_DESCRICAO:
    'On this screen, you can edit a standard document. Use the text editor to modify the document; you can also view the terms guide.',
  EXIBIR_GUIA_TERMOS: 'Show terms guide',
  ESCONDER_GUIA_TERMOS: 'Hide terms guide',
  EMAIL: 'Email',
  ENDERECO: 'Address',
  ESTADO: 'State',
  ESTADO_CIVIL: 'Marital status',
  EXPORTAR_PARA_EXCEL: 'Export to excel',

  GUIA_TERMOS: 'Terms guide',
  // Letter F:
  FILTRAR_POR: 'Filter by',
  FILTRAR_POR_PLACEHOLDER: 'Filter by name',
  FORMA_PAGAMENTO: 'Payment method',
  FECHAR_TURMA: 'Close class',
  FECHAMENTO_PERIODO_LETIVO_DESCRICAO:
    'On this screen, you can process an academic period by academic year, open and close segmentations, and conclude the academic period.',
  FECHAR_SEGMENTACAO: 'Close segmentation',
  FORMULARIO_ADITIVO: 'New addendum',
  FORMULARIO_ADITIVO_DESCRICAO:
    'On this screen, you can include an addendum to the selected contract',
  FORMULARIO_ADITIVO_TURMA_DESCRICAO:
    'On this screen, you can include an addendum to contracts in a class',
  FOTO: 'Photo',
  FORMULARIO_INSTITUICAO_ENSINO:
    'On this screen you can manage information about the educational institution',
  FILTROS: 'Filters',
  FICHA_SOCIOECONOMICA: 'Socioeconomic form',
  FICHA_SOCIOECONOMICA_DESCRICAO:
    'Select the filters to generate the socioeconomic form and labels.',
  // Letter G:
  GERENCIADOR_TURMAS: 'Class manager',
  GERAR: 'To generate',
  GERENCIADOR_TURMAS_DESCRICAO:
    'Use the filter to find a class and manage it by adding or removing students:',
  GERENCIADOR_TURMAS_ALUNO_REMOVIDO:
    'Student removed! You can find them in the table of students without classes.',
  GRUPO_TURMA: 'Class group',
  GRUPOS_TURMA: 'Class groups',
  GRUPOS_TURMA_CEJ_DESCRICAO:
    'This is the CEJ class groups page. You can register using the button at the top right or edit/delete by clicking on "actions" on the desired row.',
  GRAU_INSTRUCAO: 'Educational degree',
  GERAR_FICHA_SOCIOECONOMICA: 'Generate socioeconomic form',
  GERAR_ETIQUETAS: 'Generate labels',
  // Letter H:
  HISTORICO: 'History',
  HISTORICO_ESCOLAR: 'Academic history',
  HISTORICO_ESCOLAR_DESCRICAO:
    'On this screen, you can filter the list of students using the filter, register, edit, and delete existing histories. To do so, click on the <strong>Actions</strong> button in the student listing.',

  // Letter I:
  IDIOMA: 'Language',
  INSTITUICAO_ENSINO: 'Educational institution',
  INSTITUICAO_ENSINO_CEJ_DESCRICAO:
    'This is the external educational institutions page. You can register using the button at the top right or edit/delete by clicking on "actions" on the desired row.',
  IMPRIMIR_BOLETINS: 'Print reports',
  IMPRIMIR_PARECER_DESCRITIVO: 'Print descriptive report',
  IMPRIMIR_RELATORIO_DISCIPLINAS: 'Print disciplines report',
  IMPRIMIR_RELATORIO_DISCIPLINAS_DESCRICAO:
    'On this screen it is possible to generate the subject report, select the options in the filter and click on generate report.',
  IMPRIMIR_RELATORIOS_HISTORICO_ESCOLAR: 'Print transcript-based reports',
  IMPRIMIR_RELATORIOS_HISTORICO_ESCOLAR_DESCRICAO:
    'On this screen it is possible to generate reports based on transcripts for individual students, to do this select the filter fields and click on list students, to generate reports select the desired option on the action button in the table rows.',

  IMPRIMIR_RELATORIO_TURMAS_PARA_ALUNO: 'Print classes report for student',
  IMPRIMIR_RELATORIO_TURMAS_PARA_ALUNO_DESCRICAO:
    'Print class reports for students',
  IMPRIMIR_RELATORIO_VALORES_PARA_CONTRATOS:
    'Print values report for contracts',
  IMPRIMIR_RELATORIO_ESTOQUE:
    'Print inventory report',
  IMPRIMIR_RELATORIO_VALORES_PARA_CONTRATOS_DESCRICAO:
    'Prints value reports for contracts',
  IMPRIMIR_RELATORIO_ESTOQUES:
    'Print inventory reports',
  IMPRIMIR_RELATORIO_DESCONTOS_PARA_CONTRATOS:
    'Print discounts report for contracts',
  IMPRIMIR_RELATORIO_DESCONTOS_PARA_CONTRATOS_DESCRICAO:
    'Prints discount reports for contracts',
  IDENTIFICADOR_CENSO: 'Census identifier',
  IDENTIDADE: 'Identification card',
  INSTITUICAO_ORIGEM: 'Institution of origin',
  // Letter J:
  // Letter K:
  // Letter L:
  LISTAR_ALUNOS: 'List students',
  LAYOUT: 'Layout',
  LIBERAR_BOLETIM: 'Release report in the portal',
  LISTA_DE_PRECOS: 'List of selected prices',
  LISTA_DE_DESCONTOS: 'List of included discounts',
  LISTA_DOCUMENTOS: 'List of documents',
  LISTA_DE_CONTRATOS: 'List of contracts',
  GERAR_REMATRICULA: 'Generate re-enrollment contract(s)',
  ATIVAR_CONTRATOS: 'Activate contract(s)',
  IMPRIMIR_CONTRATOS: 'Print contract(s)',
  IMPRIMIR_FICHA_REMATRICULA: 'Print re-enrollment form',
  IMPRIMIR_ETIQUETAS: 'Print labels',

  LAYOUT_DESCONTO_CONTRATO: 'Contract discount layout',
  // Letter M:
  MATRICULA: 'Enrollment',
  MATRICULAS: 'Enrollments',
  MOTIVO_CANCELAMENTO: 'Cancellation reason',
  MOTIVO_CANCELAMENTO_CADASTRAR:
    'Contract cancellation reason created successfully.',
  MOTIVO_CANCELAMENTO_ATUALIZAR:
    'Contract cancellation reason updated successfully.',
  // Letter N:
  NOME: 'Name',
  NUM_RESERVISTA: 'Reservist number',
  NUM_RESERVISTA_PLACE: 'Enter the reservist number',
  NAO_INFORMADO: 'Not provided',
  NOVO_ADITIVO: 'New addendum',
  NOVO_ADITIVO_TURMAS: 'New addendum in class',

  NOVO_DOCUMENTO_PADRAO: 'New standard document',
  NOVO_DOCUMENTO_PADRAO_DESCRICAO:
    'On this screen, you can create a standard document. Use the text editor; you can also view the terms guide.',

  NACIONALIDADE: 'Nationality',
  NATURALIDADE: 'Place of birth',
  NUMERO: 'Number',
  // Letter O:
  OBSERVACOES: 'Observations',
  ORIENTACAO_RELATORIO: 'Generate report horizontally',
  ORGAO_EXPEDIDOR: 'Issuing authority',

  // Letter P:
  PAINEL_ACADEMICO: 'Academic dashboard',
  PAINEL_ACADEMICO_DESCRICAO: 'Select a card below to use the functionalities:',
  PAINEL_PROFESSOR: 'Teacher dashboard',
  PAINEL_COORDENACAO: 'Coordinator dashboard',
  PARCELA: 'Installment',
  PERIODO_LETIVO: 'Academic period',
  PLANO_ENSINO: 'Teaching plan',
  PROFESSORES: 'Professors',
  PESSOA: 'Person',
  PROCESSAR_BOLETIM: 'Process report',
  PROCESSAR_TURMA: 'Process class',
  PROCESSAR_PERIODO: 'Process academic period',
  PRECOS: 'Prices',
  PAINEL_PROFESSOR_DESCRICAO:
    'On this screen you can filter the subjects in each class and carry out operations such as posting grades, attendance, etc. Click on the action button to see all the options available after filtering.',
  // Letter Q:

  QUANTIDADE_MAIOR_QUANTIDADE_TOTAL: "The quantity cannot be greater than the total quantity in stock",
  QUANTIDADE_TOTAL_SEM_ESTOQUE: "This product does not have any quantity in stock",
  // Letter R:
  RECUPERACAO_BIMESTRE: 'Bimonthly recovery?',
  REGISTRO_PROFISSIONAL: 'Professional registration',
  REGISTRO_PROFISSIONAL_PLACE: 'Enter the professional registration number',
  REABRIR_TURMA: 'Reopen class',
  RELATORIO_FECHAMENTO: 'Closing report',
  REMOVER: 'Remove',
  RELATORIOS: 'Reports',
  RELATORIOS_FECHAMENTO: 'Closing reports',
  RELATORIO_NOTAS_FALTAS: 'Grades and absences',
  RELATORIO_QUADRO_ANALITICO: 'Analytical chart',
  RELATORIO_QUADRO_ANALITICO_SEGMENTACAO: 'Analytical table segmentation',
  RELATORIO_MEDIA_FINAL_RECUPERACAO: 'Final Average and Recovery',
  RELATORIO_COM_DADOS: 'With entered data',
  RELATORIO_SEM_DADOS: 'No entered data',
  RELATORIO_REGISTRO_FREQUENCIA: 'Attendance records',
  RELATORIO_REGISTRO_AVALIACAO: 'Assessment records',
  RELATORIO_FREQUENCIA: 'Attendance',
  RELATORIO_NOTA: 'Grade',
  RELATORIO_PONTO_ADICIONAL: 'Additional point',
  RELATORIO_PARECER_DESCRITIVO: 'Descriptive report',
  REGISTRO_NASCIMENTO: 'Birth certificate',
  RELIGIAO: 'Religion',
  RESPONSAVEL_FILIACAO: 'Parent/Guardian',
  RESPONSAVEL_FINANCEIRO: 'Financial responsible',
  RESPONSAVEL_PEDAGOGICO: 'Pedagogical responsible',
  RESIDENCIAL: 'Home phone',
  RELATORIOS_TURMAS: 'Class reports',
  RECUPERACAO_SEGMENTACAO: 'Recovery by segmentation',
  RELATORIO_TURMAS_DESCRICAO:
    'On this screen, you can generate class reports using the filter below. You can choose which fields will be displayed in the report by selecting the corresponding checkboxes below the filter. PDF and Excel export options are available, as well as label reports.',
  RELATORIO_IRMAOS: 'Sibling report',
  RELATORIO_IRMAOS_DESCRICAO:
    'Select the school term to generate the siblings report.',
  RELATORIO_TOTALIZADORES: 'Totalizers report',
  RELATORIO_TOTALIZADORES_DESCRICAO:
    'Select the filter to generate the pdf report',
  RELATORIOS_SITUACAO_CONTRATO: 'Contract status reports',
  RELATORIOS_SITUACAO_CONTRATO_DESCRICAO:
    'Select the filters to generate the contract status report in PDF or CSV format. The reports can be either summary or detailed, and you can choose whether to separate the classes into sheets.',

  // Letter S:
  SITUACAO: 'Situation',
  SITUACAO_ATIVO: 'Active employee',
  SITUACAO_INATIVO: 'Former employee',
  PESO: 'Weight',
  SIGLA: 'Acronym',
  SEXO: 'Gender',
  SEGMENTACAO: 'Segmentation',
  SEM_SEGMENTACAO_PARA_ANO_SELECIONADO: 'No segmentation for the selected year',
  SEM_CURSOS: 'No courses to list',
  STATUS_MATRICULA: 'Enrollment status',
  SECAO_ELEITORAL: 'Voting section',
  SECAO_ELEITORAL_PLACE: 'Enter the voting section number',
  SEM_DADOS: 'Filter did not yield results',
  SEM_DADOS_PARA_LISTAR: 'No data to list',
  SIMULACAO_PARCELAS: 'Installment simulation',
  SALVAR_ADITIVO: 'Save addendum',
  STATUS: 'Status',
  STATUS_CONTRATO: 'Contract status',
  SELECIONE_CONTRATOS: 'Select contracts',
  SELECIONAR_TODOS: 'Select all',
  SOMENTE_MATRICULAS_ATIVOS: 'Only active enrollments',
  SOMENTE_PAGAMENTOS_EM_DIA: 'Only payments in good standing',
  SEPARAR_TURMAS_FOLHAS: 'Separate classes into sheets',
  SITUACAO_CONTRATO: 'Contract status',
  SOLICITACAO: 'Request',
  SALVAR_E_VOLTAR_PARA_MATRICULA: 'Save and return to enrollment',
  SOLICITANTE: 'Requester',

  // Letter T:
  TABELA_ALUNOS: 'Student list',
  TITULO_ELEITOR: 'Voter ID',
  TITULO_ELEITOR_PLACE: 'Enter the voter ID number',
  TITULACOES: 'Qualifications',
  TIPO_AVALIACAO: 'Assessment type',
  TIPO_AVALIACAO_CEJ_DESCRICAO:
    'This is the CEJ assessment types page. You can register using the button at the top right or edit/delete by clicking on "actions" on the desired row.',
  TIPO_AVALIACOES: 'Assessment types',
  TIPO_CONTRATO: 'Contract type',
  TIPO_ANALISE: 'Analysis type',
  TODOS_PROFESSORES: 'View teachers in employees',
  TURMA: 'Class',
  TURMA_CADASTRAR: 'Create new class',
  TURMA_CADASTRADA: 'Class created successfully!',
  TURMA_CONFIRMAR_EXCLUSAO_TITULO: 'Confirm deletion of class {item}',
  TURMA_CONFIRMAR_EXCLUSAO_DESCRICAO: 'Do you want to delete this class?',
  TURMA_EDITADA: 'Class edited successfully!',
  TURMA_EXCLUIDA: 'Class deleted successfully!',
  TURMAS: 'Classes',
  TURMAS_CEJ_DESCRICAO:
    'This is the CEJ class page. Use the filter below to <strong>enable</strong> the <strong>Create new class</strong> button and display existing classes in the desired filter:',
  TURMAS_DESCRICAO:
    'This is the classes page. Here, you can register a class, edit, and delete classes in the list, to do so, click on <strong>Register</strong> or on the <strong>action</strong> button in the list.',
  TITULACOES_PLACE: 'Enter obtained qualifications',
  TIPO_ADITIVO: 'Addendum type',
  TERMO: 'Term',
  TIPO_HISTORICO: 'History type',
  TIPO_FILTRO: 'Filter type:',
  TIPO_RELATORIO: 'Report type',
  TODOS_OS_PRECOS_FORAM_ADICIONADOS: 'All values have been added',
  // Letter U:
  // Letter V:
  VINCULAR_CURSO: 'Link course',
  VINCULAR_GRUPO_TURMA: 'Link group class',
  VINCULAR_TURMA: 'Link class',
  VAGAS: 'Vacancies',
  VALOR: 'Value',
  VALORES_PLANO_PARCELAMENTO: 'Values and installment plans',
  VISUALIZAR_AULAS_CURSO: 'View classes for the course',
  VISUALIZAR_ADITIVOS: 'View addendums for the contract',
  VALIDAR_FECHAMENTO: 'Validate data for closing',
  // Letter X:
  // Letter Y:
  // Letter Z:
};
