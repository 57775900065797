export const AcademicoHome = {
  // Letra A:
  // Letra B:
  // Letra C:
  // Letra D:
  // Letra E:
  // Letra F:
  FUNCIONALIDADES: "Funcionalidades",
  FECHAMENTO_CURSO_TURMA: "Fechamento de cursos e turmas",
  FECHAMENTO_CURSO_TURMA_DESCRICAO: "Nesta tela, você tem a capacidade de refinar a busca por turmas, executar o encerramento de turmas, disponibilizar boletins no portal do aluno e imprimir relatórios. Essas ações podem ser realizadas ao clicar nos botões correspondentes associados aos itens na lista",

  // Letra G:
  GERENCIAR_ESTRUTURAS: "Gerenciar estruturas",

  // Letra H:
  // Letra I:
  // Letra J:
  // Letra K:
  // Letra L:
  // Letra M:
  // Letra N:
  // Letra O:
  // Letra P:
  PAINEL_ACADEMICO: "Painel acadêmico",
  PAINEL_ACADEMICO_DESCRICAO:
    "Selecione um card abaixo para utilizar as funcionalidades:",

  // Letra Q:
  // Letra R:
  // Letra S:
  // Letra T:
  // Letra U:
  // Letra V:
  // Letra X:
  // Letra Y:
  // Letra Z:
};
