export const Home = {
    // Letra A:
    ACADEMICO: 'Academic',

    // Letra B:
    // Letra C:
    // Letra D:
    DESCRICAO: 'Use the shortcuts to browse the site or access by the side menu.',

    // Letra E:
    // Letra F:
    FINANCEIRO: 'Financial',

    // Letra G:
    // Letra H:
    // Letra I:
    // Letra J:
    // Letra K:
    // Letra L:
    // Letra M:
    // Letra N:
    // Letra O:
    // Letra P:
    // Letra Q:
    // Letra R:
    RH: 'HR',

    // Letra S:
    // Letra T:
    TITULO: 'Welcome to the CEJ administrative portal',

    // Letra U:
    // Letra V:
    // Letra X:
    // Letra Y:
    // Letra Z:
}