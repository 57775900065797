export const ContabilLancamento = {
    // Letra A:
    // Letra B:
    BUSCAR_DOCUMENTO_ORIGEM: 'Buscar documento origem',
    // Letra C:
    CONFIRMAR_EXCLUIR_DOCUMENTO: 'Excluir lançamento contábil',
    CONFIRMAR_EXCLUIR_DOCUMENTO_MENSAGEM: 'Deseja excluir este lançamento contábil?',
    CONFIRMAR_EXCLUIR_DOCUMENTO_ITEM: 'Confirme a exclusão do lançamento vinculado',
    CONFIRMAR_EXCLUIR_DOCUMENTO_ITEM_MENSAGEM: 'Deseja excluir este lançamento vinculado?',
    // Letra D:
    DIGITAR_CODIGO: 'Digitar código do documento',
    // Letra E:
    // Letra F:
    // Letra G:
    // Letra H:
    // Letra I:
    // Letra J:
    // Letra K:
    // Letra L:
    // Letra M:
    // Letra N:
    // Letra O:
    // Letra P:
    PAGE_DESCRIPTION:
        'Um lançamento é um reconhecimento de um ativo, passivo, receita ou despesa em um determinado tempo. Registre aqui os lançamentos ou utilize o filtro para encontrar um lançamento já registrado.',

    // Letra Q:
    // Letra R:
    // Letra S:
    SELECIONAR_DOCUMENTO_ORIGEM: 'Selecionar documento de origem',
    SELECIONAR_TIPO_CONTA: 'Selecionar tipo de documento origem'
    // Letra T:
    // Letra U:
    // Letra V:
    // Letra X:
    // Letra Y:
    // Letra Z:
};
