export const AutentificacaoModalEsqueceuSenha = {
    // Letra A:
    ALTERAR_SENHA: "Alterar sua senha",

    // Letra B:
    // Letra C:
    // Letra D:
    // Letra E:
    EMAIL: 'E-mail',
    ESQUECEU_SENHA: 'Esqueceu sua senha?',
    EXPLICACAO: 'Não se preocupe! Digite seu e-mail no campo abaixo e você receberá por e-mail um link para resgatar sua conta:',

    // Letra F:
    // Letra G:
    // Letra H:
    // Letra I:
    // Letra J:
    // Letra K:
    // Letra L:
    // Letra M:
    // Letra N:
    // Letra O:
    // Letra P:
    // Letra Q:
    // Letra R:
    // Letra S:
    // Letra T:
    // Letra U:
    // Letra V:
    // Letra X:
    // Letra Y:
    // Letra Z:
}