export const AcademicoDiarioClasse = {
  // Letra A:
  ACADEMICO: 'Academic',
  AULAS: 'Classes',
  AULAS_DESCRICAO:
    'On this screen, you can filter the classes using the filters below, as well as edit and delete the classes listed.',
  AVALIACOES: 'Tests',
  AVALIACOES_DESCRICOES:
    'On this screen you can filter the evaluations using the filter below, and you can also create new evaluations, edit and delete existing ones.',

  // Letra B:
  // Letra C:
  CADASTRAR_AULA: 'Register class',
  // Letra D:
  DIARIO_CLASSE: 'Class diary',

  // Letra E:
  // Letra F:
  FREQUENCIAS: 'Frequencies',
  FREQUENCIAS_DESCRICAO:
    'On the student attendance screen, you can manage and view filtered frequencies, as well as edit both the frequencies and their associated descriptions.',
  // Letra G:
  // Letra H:
  // Letra I:
  // Letra J:
  // Letra K:
  // Letra L:
  // Letra M:
  // Letra N:
  NOTAS_DESCRICAO:
    'On the student grades screen, you can manage and view filtered grades, as well as edit both the grades and their associated descriptions.',
  NOTAS: 'Grades',

  // Letra O:
  // Letra P:
  // Letra Q:
  // Letra R:
  // Letra S:
  // Letra T:
  // Letra U:
  // Letra V:
  // Letra X:
  // Letra Y:
  // Letra Z:
};
