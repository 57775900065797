export const SuprimentosLancamentosNF = {
  // Letra A:
  ADICIONAR_IMPOSTO: 'Adicionar imposto',
  ADICIONAR_ITEM: 'Adicionar item',
  ACOES: 'Ações',

  // Letra B:
  // Letra C:
  CANCELAR_ESCONDER_FORM: 'Cancelar e esconder form',
  CNPJ: 'CNPJ',

  // Letra D:
  DADOS_GERAIS: 'Dados gerais',
  DADOS_PAGAMENTO: 'Dados do pagamento',
  DADOS_PARCELAMENTO: 'Dados do parcelamento',
  DATA_EMISSAO: 'Data de emissão',
  DATA_EMISSAO_ATE: 'Data de emissão (final)',
  DATA_EMISSAO_INICIO: 'Data de emissão (início)',
  DATA_VENCIMENTO: 'Data de vencimento',
  DATA_VENCIMENTO_INICIO: 'Data de vencimento (início)',
  DATA_VENCIMENTO_ATE: 'Data de vencimento (fim)',
  DEPOSITO: 'Depósito',
  DESCONTOS: 'Descontos',
  DEPOSITOS: 'Depósitos',

  // Letra E:
  // Letra F:
  FINANCEIRO: 'Financeiro',
  FORNECEDOR: 'Fornecedor',
  FORMA_PAGAMENTO: 'Forma de pagamento',

  // Letra G:
  // Letra H:
  // Letra I:
  IMPOSTO: 'Imposto',
  IMPOSTOS: 'Impostos',
  ITENS_PEDIDO: 'Itens do pedido',

  // Letra J:
  // Letra K:
  // Letra L:
  LANCAR_NF: 'Lançar NF',

  // Letra M:
  MENSAGEM_ALERTA_CALCULAR_PARCELAS:
    'É necessário calcular as parcelas antes de salvar',
  MENSAGEM_VALOR_PARCELAS_DIFERENTE_VALOR_TOTAL: 'O Valor das parcelas deve ser igual ao Valor total da Nota Fiscal',
  MENSAGEM_VALOR_VALOR_TOTAL_NF_INCORRETO: 'Os Valores estão incorretos',
  // Letra N:
  NOTA_FISCAL: 'Nota fiscal',
  NOTA_FISCAL_PAGE_DESCRICAO:
    'Atenção: uma vez gerada, uma nota fiscal não pode ser editada.',
  NOTA_FISCAL_TAB_ITENS_DADOS_DESCRICAO:
    'Preencha aqui as datas de emissão e de vencimento da nota, tal como o fornecedor',
  NOTA_FISCAL_TAB_ITENS_PEDIDO_DESCRICAO:
    'Adicione aqui os itens do pedido. Selecione o ícone ▼ na coluna "ações" na tabela abaixo para editar ou excluir um item.',
  NOTA_FISCAL_TAB_PAGAMENTO_DESCRICAO:
    'Preencha aqui os dados relativos aos valores da nota fiscal. Atente-se que o tipo de nota fiscal modifica as opções de forma de pagamento e histórico de lançamento.',
  NOTA_FISCAL_TAB_OBSERVACOES_DESCRICAO:
    'Preencha aqui com quaisquer observações da nota fiscal.',
  NOTA_FISCAL_TAB_IMPOSTOS_DESCRICAO:
    'Adicione aqui os impostos e seus respectivos valores. Selecione o ícone ▼ na coluna "ações" na tabela abaixo para editar ou excluir um item.',
  NUMERO_NOTA_FISCAL: 'Número nota fiscal',

  // Letra O:
  OBSERVACOES: 'Observações',

  // Letra P:
  PAGAMENTO: 'Pagamento',
  PAGE_TITLE: 'Lançamentos NF',
  PAGE_DESCRIPTION:
    'Notas fiscais são documentos que atestam a realização de uma operação comercial entre duas partes, sendo crucial para o controle fiscal e tributário. Utilize o filtro abaixo para encontrar notas fiscais:',
  PRODUTO: 'Produto',

  // Letra Q:
  QUANTIDADE: 'Quantidade',

  // Letra R:
  // Letra S:
  SEM_OBSERVACOES_CADASTRADAS: 'Sem observações cadastradas',

  // Letra T:
  TIPO_NF: 'Tipo NF',
  TIPO_NOTA_FISCAL: 'Tipo nota fiscal',

  // Letra U:
  // Letra V:
  VALOR_BRUTO: 'Valor bruto',
  VALOR_DESCONTOS: 'Valor dos descontos',
  VALOR_FRETE: 'Valor do frete',
  VALOR_UNITARIO: 'Valor unitário',
  VALOR_IMPOSTOS: 'Valor dos impostos',
  VALOR_TOTAL: 'Valor total',
  VALOR_TOTAL_DA_NOTA_FISCAL: 'Valor total da Nota Fiscal',
  NUMERO_PARCELAS: 'Número de parcelas',
  VISUALIZAR: 'Visualizar',
  VISUALIZAR_NF_DESCRICAO:
    'Nessa tela você pode visualizar os dados da nota fiscal. Utilize os botões abaixo para visualizar as informações por categoria.',

  // Letra X:
  // Letra Y:
  // Letra Z:
};
