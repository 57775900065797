<template>
  <div id="main-wrapper">
    <router-view />
    <loader />
  </div>
</template>

<script>
import Loader from '@/components/loader/Loader';

export default {
  name: 'App',
  components: {
    Loader,
  },
};
</script>

<style>
/* para evitar o bug de esconder dropdown */
.table-responsive {
  min-height: 200px;
}
/* para escurecer o dropdown do InputSelectSearch no disabled para facilitar leitura */
.ui.dropdown.disabled {
  opacity: 1 !important;
  background-color: #f0f2f4 !important;
}
</style>
