export const AcademicoMatriculas = {
  // Letra A:
  ACADEMICO: 'Acadêmico',
  ACOES: 'Ações',
  ADICIONAR_DESCONTO: 'Adicionar desconto',
  ADICIONAR_PESSOA_TABELA: 'Adicionar pessoa a tabela',
  ALUNO: 'Aluno',
  ATENCAO: 'Atenção!',

  // Letra B:
  BAIXAR_CERTIFICADO_ENSINO_FUNDAMENTAL:
    'Baixar certificado ensino fundamental',
  BAIXAR_CERTIFICADO_ENSINO_MEDIO: 'Baixar certificado ensino médio',

  // Letra C:
  CODIGO_MATRICULA: 'Código da matrícula',
  CONFIRMAR_ATIVACAO:
    'Você tem certeza que deseja ativar a matrícula de {pessoa}?',
  CONFIRMAR_INATIVACAO:
    'Você tem certeza que deseja desativar a matrícula de {pessoa}?',
  CONTRATOS: 'Contratos',
  CURSO_TURMA: 'Curso/Turma',
  // Letra D:
  DADOS_ALUNO: 'Dados do aluno',
  DADOS_CONTATO: 'Dados para contato',
  DADOS_ENDERECO: 'Dados de endereço',
  DADOS_RESPONSAVEIS: 'Dados dos responsáveis',
  DESCONTO: 'Desconto',
  DESCONTOS: 'Descontos',
  DIGITE_OU_SELECIONE: 'Digite ou selecione',
  DIGITE_PARA_FILTRAR: 'Digite para filtrar',
  DIGITE_MATRICULA_ALUNO: 'Digite a matrícula do aluno',

  // Letra E:
  ENDERECOS: 'Endereços',
  EDITAR_MATRICULA: 'Editar matrícula',
  EDITAR_MATRICULA_PAGE_DESCRICAO:
    'Nessa tela você pode atualizar os dados da matrícula',
  // Letra F:
  FILTRO_LABEL: 'Filtrar por',
  FILTRO_PLACEHOLDER: 'Digite código da matrícula ou nome do aluno',
  STATUS_CONTRATO: 'Situação do contrato',

  // Letra G:
  GERAR_HISTORICO_ESCOLAR: 'Gerar histórico escolar',
  GERAR_BOLETIM: 'Gerar boletim',

  // Letra H:
  // Letra I:
  IDIOMA: 'Idioma',
  IMPRIMIR_ATESTADO_FREQUENCIA: 'Imprimir atestado de frequência',
  IMPRIMIR_BOLETIM_NOTAS_PARCIAIS: 'Imprimir boletim e notas parciais',
  IMPRIMIR_ATESTATO_DE_FREQUENCIA_E_MATRICULA:
    'Imprimir atestado de frequência e matricula',
  IMPRIMIR_HISTORICO_ESCOLAR: 'Imprimir histórico escolar',
  IMPRIMIR_CERTIFICADO: 'Imprimir certificado',

  // Letra J:
  // Letra K:
  // Letra L:
  LISTAR_CONTRATOS: 'Listar contratos',
  // Letra M:
  MATRICULA: 'Matrícula',
  MATRICULA_ACADEMICO_EXPLICACAO:
    'Caso algum botão esteja desabilitado, isto indica que é necessário selecionar um contrato na tabela antes. Por exemplo: para imprimir boletim e notas parciais, é necessário clicar no contrato desejado antes.',
  MENSAGEM_CADASTRADO: 'Matrícula cadastrada com sucesso',
  MATRICULA_PAGE_DESCRICAO_NOVO:
    "Criar uma matrícula é simples! Preencha o formulário abaixo e depois clique no botão 'salvar' ao final:",
  MATRICULA_SEM_VINCULO:
    'Não é possível cadastrar uma matrícula sem um vínculo',
  MATRICULAS: 'Matrículas',
  MATRICULA_ATIVADA: 'Matrícula ativada com sucesso!',
  MATRICULAS_DESCRICAO_PAGE:
    'Utilize o filtro abaixo para obter melhores resultados.  Selecione o ícone ▼ na coluna "ações" na tabela gerada para ativar, desativar ou editar uma matrícula.',
  MATRICULA_INATIVADA: 'Matrícula inativada com sucesso!',
  MATRICULA_CONTRATO_EXPLICACAO:
    'Nessa tela você pode visualizar os contratos do aluno e gerar relatórios clicando em "ações" na linha desejada.',
  // Letra N:
  NENHUM_MATRICULA_REGISTRADA: 'Nenhuma matrícula registrada.',
  NENHUM_CONTRATO_REGISTRADO: 'Nenhum contrato registrado',
  NOME: 'Nome',
  NOVO_ENDERECO: 'Novo endereço',
  NOVA_MATRICULA: 'Nova matrícula',
  NOVA_PESSOA: 'Nova pessoa',

  // Letra O:
  OBSERVACAO: 'Observação',
  OBSERVACOES: 'Observações',
  OBSERVACOES_LABEL: 'Digite abaixo as observações em relação a este aluno:',

  // Letra P:
  PERCENTUAL: 'Percentual',
  PERIODO_LETIVO: 'Período letivo',
  PESSOA: 'Pessoa',

  // Letra Q:
  // Letra R:
  REMOVER_DESCONTO: 'Remover desconto',
  RESPONSAVEL_POR: 'Responsável por',
  REMATRICULAS: 'Rematrículas',
  REMATRICULAS_DESCRICAO_PAGE:
    'Nessa tela é possível realizar rematrícula para um aluno ou para uma turma, use o filtro para listar os contratos e realizar a rematrícula. Use os botões para imprimir contratos, fichas de rematrícula e ativar contratos que foram listados.',

  // Letra S:
  SELECIONAR_DESCONTO: 'Selecione um desconto',
  SELECIONE_PESSOA: 'Selecione uma pessoa',
  STATUS: 'Status',

  // Letra T:
  TURMAS: 'Turmas',

  // Letra U:
  // Letra V:
  VINCULO_COM_ALUNO: 'Vínculo com o aluno',
  VINCULOS_COMPONENTE_DESCRICAO:
    'Adicione pelo menos um responsável pedagógico e financeiro (que pode ou não ter um vínculo de filiação com o aluno) para que a matrícula seja válida.',
  VALOR: 'Valor',

  // Letra X:
  // Letra Y:
  // Letra Z:
};
