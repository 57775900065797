export const Admin = {
    // Letra A:
    // Letra B:
    // Letra C:
    CONFIGURACOES: 'Configurações',

    // Letra D:
    // Letra E:
    // Letra F:
    // Letra G:
    // Letra H:
    // Letra I:
    // Letra J:
    // Letra K:
    // Letra L:
    // Letra M:
    // Letra N:
    // Letra O:
    // Letra P:
    PERFIS_DESCRICAO: 'Clique nos checkboxes para habilitar ou desabilitar as permissões de cada tela.',
    PERFIS: 'Perfis',
    PERMISSOES_PERFIL: 'Permissões do perfil',

    // Letra Q:
    // Letra R:
    // Letra S:
    // Letra T:
    // Letra U:
    // Letra V:
    // Letra X:
    // Letra Y:
    // Letra Z:
}