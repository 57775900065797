export const ShortcutCards = {
    // Letra A:
    ACADEMICO: 'Academic',
    AULAS: 'classes',
    AVALIACOES: 'tests',
    ADITIVOS_CONTRATO: 'Contract Addendums',
    // Letra B:
    BANCOS: 'banks',
    BOLETINS: 'report cards',

    // Letra C:
    CAIXA: 'cash',
    COBRANCAS: 'charges',
    CONFIGURACOES_SISTEMA: 'system settings',
    CONTABIL: 'accounting',
    CONTAS: 'accounts',
    CONTRATOS: 'contracts',
    CONVENIADOS: 'members',
    COORDENACAO: 'coordination',
    CURSOS: 'courses',
    CURSOS_TURMAS: 'courses and classes',

    // Letra D:
    DIARIO_CLASSE: 'class diary',
    DISCIPLINAS: 'subjects',
    DESCONTOS: 'discounts',
    DEPOSITOS: 'deposits',
    DO_CEJ: 'of CEJ',
    DOCENTES_DO_PERIODO: 'Teachers of the Period',
    DOCUMENTO_PADRAO: 'Standard document',

    // Letra E:
    ESTOQUE: 'stock',
    ESTUDANTES: 'students',

    // Letra F:
    FATURAMENTO: 'invoicing',
    FECHAMENTO_CONTABIL: 'accounting closing',
    FREQUENCIAS: 'frequencies',
    FINANCEIRO: 'financial',
    FORMA_PAGAMENTO: 'payment methods',
    FORNECEDORES: 'suppliers',
    FUNCIONALIDADES_ALUNOS: 'Features related to students',
    FUNCIONALIDADES_GERAIS: 'General features',
    FUNCIONALIDADES_TURMAS: 'Features related to classes',
    FUNCIONARIOS: 'employees',

    // Letra G:
    GERENCIAR: 'to manage',
    GERENCIAR_ESTRUTURAS: 'manage structures',
    GERENCIAR_ESTRUTURAS_DE: 'manage structures of',
    GERENCIADOR_TURMAS: 'manage classes',
    GRUPOS_TURMA: 'class groups',

    // Letra H:
    HISTORICO_ESCOLAR: 'school records',
    HISTORICOS: 'records',

    // Letra I:
    INSTITUICOES_DE_ENSINO: 'educational institutions',
    IR_PARA: 'go to',
    IR_PARA_FECHAMENTO: 'go to',
    IR_PARA_TODAS: 'go to',

    // Letra J:
    // Letra K:
    // Letra L:
    LANCAMENTOS: 'entries',
    LANCAMENTOS_CONTABEIS: 'accounting entries',
    LANCAMENTOS_NF: 'NF entries',

    // Letra M:
    MATRICULAS: 'enrollments',

    // Letra N:
    NOTAS: 'grades',

    // Letra O:
    OUTRAS_INSTITUICOES: 'other institutions',

    // Letra P:
    PAINEL_PROFESSOR: 'teacher pannel',
    PERFIS: 'Profiles',
    PESSOAS: 'people',
    PERIODOS_CONTABEIS: 'accounting periods',
    PERIODO_LETIVO: 'school year',
    PLANO_CONTAS: 'plano de contaschart of accounts',
    PRECOS: 'prices',
    PRODUTOS: 'products',
    PROFESSORES: 'teachers',

    // Letra Q:
    // Letra R:
    RELATORIOS: 'reports',
    RH: 'HR',
    REMATRICULAS: 'Reenrollments',

    // Letra S:
    SUPRIMENTOS: 'supplies',

    // Letra T:
    TAXAS: 'taxes',
    TIPOS_AVALIACAO: 'test types',
    TURMAS: 'classes',

    // Letra U:
    USUARIOS: 'Users',

    // Letra V:
    // Letra X:
    // Letra Y:
    // Letra Z:
};