export const FichaSaude = {
  ACEITAR_TERMOS: 'Accept terms',
  ALERGIAS: 'Allergies',
  ALERGIA_CONFIRMACAO: 'Do you have allergies?',
  ALERGIA_QUAIS: 'If yes, what type(s) of allergy?',
  ATUALIZAR_FICHA: 'Update Health Form',
  DECLARAR_VERDADEIRO:
    'I declare the above information to be true, and in case of emergency, I authorize my child to be taken to the Emergency Room',
  DEFICIENCIAS: 'Disabilities',
  DEFICIENCIAS_CONFIRMACAO: 'Do you have any disabilities?',
  DEFICIENCIAS_DESCRICAO_CID: 'Description or ICD',
  DEFICIENCIAS_ESPECIFIQUE:
    'If yes, specify the type of disability or provide the ICD code:',
  DEFICIENCIAS_SELECIONE: 'If yes, select the type(s):',
  DEFICIENCIA_AUDITIVA: 'Hearing',
  DEFICIENCIA_FISICA: 'Physical',
  DEFICIENCIA_INTELECTUAL: 'Intellectual',
  DEFICIENCIA_MULTIPLA: 'Multiple',
  DEFICIENCIA_PSICOSSOCIAL: 'Psychosocial',
  DEFICIENCIA_VISUAL: 'Visual',
  FICHA_SAUDE: 'Health Form',
  INTOLERANCIAS: 'Intolerances',
  INTOLERANCIAS_GLUTEN: 'Do you have gluten intolerance?',
  INTOLERANCIAS_LACTOSE: 'Do you have lactose intolerance?',
  MEDICACOES: 'Medications',
  MEDICACOES_CONFIRMACAO: 'Do you take continuous medication?',
  MEDICACOES_QUAIS: 'If yes, which one(s)?',
  MEDICACOES_MOTIVO: 'Why do you take this medicine?',
  OBSERVACOES: 'Observations',
  OBSERVACOES_OUTRAS:
    'Other observations about the health and/or development of the student:',
  PLANO_SAUDE: 'Health Plan',
  PLANO_SAUDE_NUMERO_CARTEIRINHA: 'Card number',
  PLANO_SAUDE_PLACEHOLDER: 'Enter the name of your health plan',
  PLANO_SAUDE_NUMERO_CARTEIRINHA_PLACEHOLDER: 'Enter the card number',
  REFLUXO: 'Do you have reflux?',
  SOBRE:
    "In this questionnaire, we request information about the student's health and development. This form will be part of each student's school records, and we emphasize the importance of providing all requested information for more efficient assistance by the school or, if needed, by emergency responders/hospital.",

  VISUALIZAR_FICHA: 'View health form',
};
