export const ContabilFechamento = {
    // Letra A:
    ACOES: 'Actions',
    ANO_CONTABIL: 'Accounting year',

    // Letra B:
    // Letra C:
    CONTABIL: 'Accounting',

    // Letra D:
    DATA_INICIAL: 'Initial date',
    DATA_FIM: 'Ending date',

    // Letra E:
    // Letra F:
    // Letra G:
    // Letra H:
    // Letra I:
    // Letra J:
    // Letra K:
    // Letra L:
    LISTAR_PERIODOS_CONTABEIS: 'List accounting periods',

    // Letra M:
    MES_REFERENCIA: 'Reference month',

    // Letra N:
    // Letra O:
    // Letra P:
    PAGE_TITLE: 'Accounting closing',

    // Letra Q:
    // Letra R:
    // Letra S:
    STATUS: 'Status',

    // Letra T:
    // Letra U:
    // Letra V:
    // Letra X:
    // Letra Y:
    // Letra Z:
}