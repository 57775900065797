export const Home = {
    // Letra A:
    ACADEMICO: 'Acadêmico',

    // Letra B:
    // Letra C:
    // Letra D:
    DESCRICAO: 'Use os atalhos para navegar pelo site ou acesse pelo menu lateral.',

    // Letra E:
    // Letra F:
    FINANCEIRO: 'Financeiro',

    // Letra G:
    // Letra H:
    // Letra I:
    // Letra J:
    // Letra K:
    // Letra L:
    // Letra M:
    // Letra N:
    // Letra O:
    // Letra P:
    // Letra Q:
    // Letra R:
    RH: 'RH',

    // Letra S:
    // Letra T:
    TITULO: 'Bem-vindo ao portal administrativo CEJ',

    // Letra U:
    // Letra V:
    // Letra X:
    // Letra Y:
    // Letra Z:
}