export const Precos = {
  ADICIONAR_PRECO: "Add price",
  CALCULO_JUROS_MANUAL: "Ignore interest calculation, I will inform manually.",
  DESCONTO: "Discount",
  DESCONTO_CONTRATO: "Contract discount:",
  DESCONTO_SUCESSO: "Discount updated successfully",
  DESCRICAO: "Description",
  JUROS: "Interest (R$)",
  PRECO: "Price",
  REMOVER_PRECO: "Remove price",
  TAXAS: "Taxes",
  TIPO_PAGAMENTO: "Payment type",
  TOTAL: "Total",
  VALOR: "Value",
  VALOR_EFETIVO: "Effective value",
  VALOR_RECEBIDO: "Received value",
  VALOR_DA_PARCELA: "Amount of the installment",
  VALOR_TOTAL: "Total value",
  VALOR_DESCONTO: "Discount value (R$)",
  ORIGEM: 'Origin',
};
