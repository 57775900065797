import ApiService from '@/common/api/api.service';
import helpers from '@/common/utils/helpers';

const PessoaService = {
  async obterAlunosPorFiltro(form) {
    let { data } = await ApiService.get(`Pessoa/obter-alunos`, {
      params: {
        SegmentacaoId: form.segmentacaoId,
        TurmaId: form.turmaId,
        CursoId: form.cursoId,
        AnoLetivoId: form.anoLetivoId,
      },
    });
    return data;
  },
  async getFoto(id) {
    let { data } = await ApiService.get(`Pessoa/recuperar-foto`, {
      params: {
        id,
      },
    });
    return data;
  },
  async listar(paginacao) {
    let { data } = await ApiService.get(`Pessoa`, { params: paginacao });
    data.data.itens = data.data.itens.map((row) => ({
      ...row,
      text: row.nome,
      value: row.id,
    }));
    return data;
  },
  async listarSemPaginacao() {
    let { data } = await ApiService.get('Pessoa/obter-todas');
    data.data = data.data.map((row) => ({
      ...row,
      text: row.nome,
      value: row.id,
    }));
    return data;
  },

  async listarPessoasFiltradas(paginacao, filtro) {
    let { data } = await ApiService.get(`Pessoa/obter-por-filtro`, {
      params: { ...paginacao, Nome: filtro },
    });
    data.data.itens = data.data.itens.map((row) => ({
      ...row,
      text: row.nome,
      value: row.id,
    }));
    return data;
  },

  async buscarPessoasSemUsuario() {
    let { data } = await ApiService.get(`Pessoa/sem-usuario`);
    data.data = data.data.map((row) => ({
      text: row.nome,
      value: row.id,
    }));
    return data;
  },
  async buscarPessoasSemUsuarioPesquisaNome(nome) {
    let { data } = await ApiService.get(`Pessoa/sem-usuario-nome`, {
      params: {
        Nome: nome,
        TamanhoDaPagina: 50
      },
    });
    data.data.itens = data.data.itens.map((row) => ({
      text: row.nome,
      value: row.id,
    }));
    return data.data;
  },
  async buscarTodosSemMatricula() {
    let { data } = await ApiService.get(`Pessoa/sem-matricula`);
    data.data = data.data.map((row) => ({
      ...row,
      text: row.nome,
      value: row.id,
      codigo: row.codigo,
    }));
    return data;
  },
  async buscarTodosSemMatriculaPorNome(nome) {
    let { data } = await ApiService.get(`Pessoa/sem-matricula-nome`, {
      params: {
        Nome: nome,
        TamanhoDaPagina: 50
      },
    });
    data.data.itens = data.data.itens.map((row) => ({
      ...row,
      text: row.nome,
      value: row.id,
      codigo: row.codigo,
    }));
    return data.data;
  },
  async buscarPorId(id) {
    let { data } = await ApiService.get(`Pessoa/${id}`);
    return data;
  },
  async salvar(form) {
    form.enderecos.forEach(({ cep }, index) => {
      form.enderecos[index].cep = helpers.removerMascara(cep);
    });
    if (form.foto.src && form.foto.tipo) {
      const url = form.foto.src.result;
      const tipo = form.foto.tipo;

      const urlFoto = helpers.formatacaoDeStringBase64(url, tipo);

      form.fotoUpload = urlFoto;
      form.foto = form.foto.nome;
    }

    form.cpf = helpers.removerMascara(form.cpf);
    let result = [];
    if (!form.id) result = await ApiService.post(`Pessoa`, form);
    else result = await ApiService.put(`Pessoa`, form);
    return result;
  },

  async excluir(id) {
    return await ApiService.delete(`Pessoa/${id}`);
  },

  async removerContato(id) {
    return await ApiService.delete(`Pessoa/remover-contato/${id}`);
  },

  async removerFiliacao(id) {
    return await ApiService.delete(`Pessoa/remover-filiacao/${id}`);
  },

  async removerEndereco(id) {
    return await ApiService.delete(`Pessoa/remover-endereco/${id}`);
  },

  async criarContato(contato) {
    let result = await ApiService.post(`Pessoa/criar-contato`, contato);
    return result;
  },

  async criarFiliacao(filiacao) {
    let result = await ApiService.post(`Pessoa/criar-filiacao`, filiacao);
    return result;
  },
  async atualizarEndereco(endereco) {
    endereco.cep = helpers.removerMascara(endereco.cep);
    let result = await ApiService.put(`Pessoa/Atualizar-endereco`, endereco);
    return result;
  },
  async atualizarContato(contato) {
    let result = await ApiService.put(`Pessoa/atualizar-contato`, contato);
    return result;
  },
  async criarEndereco(endereco) {
    endereco.cep = helpers.removerMascara(endereco.cep);
    let result = await ApiService.post(`Pessoa/adicionar-endereco`, endereco);
    return result;
  },

  // Docentes
  async listarDocentes() {
    let { data } = await ApiService.get(`Pessoa/docentes`);
    return data;
  },

  async listarDocentesPorFiltro(filtro) {
    let { data } = await ApiService.get(`Pessoa/listar-ano-docentes`, {
      params: { ...filtro },
    });
    return data;
  },
};

export default PessoaService;
