export const Menu = {
  // Letra A:
  ACADEMICO: 'Acadêmico',
  ADITIVOS_CONTRATO: 'Aditivos de contrato',
  AULAS: 'Aulas',
  AVALIACOES: 'Avaliações',
  // Letra B:
  BANCOS: 'Bancos',
  BOLETINS: 'Boletins',
  // Letra C:
  CONFIGURACOES: 'Configurações',
  CONTABIL: 'Contábil',
  CONTRATOS: 'Contratos',
  CURSOS: 'Cursos',
  CURRICULO_ESCOLAR: 'Currículo escolar',
  CONTAS: 'Contas',
  CONVENIADOS: 'Conveniados',
  COBRANCA: 'Cobrança',

  // Letra D:
  DESCONTOS: 'Descontos',
  DEPOSITOS: 'Depósitos',
  DISCIPLINAS: 'Disciplinas',
  DOCENTES_PERIODO: 'Docentes do período',
  DOCUMENTO_PADRAO: 'Documento padrão',
  // Letra E:
  ESTOQUE: 'Estoque',
  // Letra F:
  FINANCEIRO: 'Financeiro',
  FECHAR: 'Fechar',
  FECHAMENTO_PERIODO_LETIVO: 'Fechamento de período letivo',
  FECHAMENTO_CURSOS_TURMAS: 'Fechamento de cursos e turmas',
  FREQUENCIAS: 'Frequências',
  FORMAS_PAGAMENTO: 'Formas de pagamento',
  FLUXO_CAIXA: 'Fluxo de caixa',
  FECHAMENTO_CONTABIL: 'Fechamentos contábil',
  FICHA_SOCIOECONOMICA: 'Relatórios ficha socioeconômica',
  FUNCIONARIOS: 'Funcionários',
  FORNECEDORES: 'Fornecedores',
  // Letra G:
  GERENCIADOR_TURMAS: 'Gerenciador de turmas',
  GRUPOS_TURMA: 'Grupos de turma',
  // Letra H:
  HOME: 'Home',
  HISTORICO_ESCOLAR: 'Histórico escolar',
  HISTORICO_LANCAMENTOS: 'Histórico de lançamentos',
  // Letra I:
  INSTITUICAO_ENSINO: 'Instituição de ensino',
  INSTITUICOES_ENSINO: 'Instituições de ensino',
  INTEGRACAO_FINANCEIRA_CONTABIL: 'Integ financeira / contábil',
  IMPOSTOS: 'Impostos',
  // Letra J:
  // Letra K:
  // Letra L:
  LANCAMENTO_CONTAS_PAGAR: 'Lanç. de contas a pagar',
  LANCAMENTO_CONTAS_RECEBER: 'Lanç. de contas a receber',
  LANCAMENTOS_CONTABEIS: 'Lançamentos contábeis',
  LANCAMENTOS_NF: 'Lançamentos NF',
  // Letra M:
  MATRICULAS: 'Matrículas',
  // Letra N:
  NOTAS: 'Notas',
  // Letra O:
  // Letra P:
  PAINEL_PROFESSOR: 'Painel do professor',
  PAINEL_COORDENACAO: 'Painel da coordenação',
  PESSOAS: 'Pessoas',
  PERFIS: 'Perfis',
  PERIODO_LETIVO: 'Período letivo',
  PRECOS: 'Preços',
  PERIODO_FINANCEIRO: 'Período financeiro',
  PERIODOS_CONTABEIS: 'Períodos contábeis',
  PLANO_CONTAS: 'Plano de contas',
  PRODUTOS: 'Produtos',
  PROTOCOLOS: 'Protocolos',
  // Letra Q:
  // Letra R:
  RELATORIO_DESCONTOS_PARA_CONTRATOS: 'Relatório descontos para contratos',
  RELATORIO_BALANCETE: 'Relatório balancete',
  RELATORIO_DIARIO: 'Relatório diário',
  RELATORIO_ESTOQUE: 'Relatório estoque',
  RELATORIO_RAZAO: 'Relatório razão',
  RELATORIO_DISCIPLINA: 'Relatório disciplina',
  RELATORIO_TURMAS_PARA_ALUNO: 'Relatório turmas para aluno',
  RELATORIO_VALORES_PARA_CONTRATOS: 'Relatório valores para contratos',
  RELATORIOS_HISTORICO_ESCOLAR: 'Relatórios baseados em histórico escolar',
  RELATORIO_DECLARACAO_IMPOSTO_RENDA: 'Relatório declaração imposto de renda',
  RELATORIO_FATURAMENTO: 'Relatório de faturamento',
  RECURSOS_HUMANOS: 'Recursos humanos',
  REMATRICULAS: 'Rematrículas',
  RELATORIOS_TURMAS: 'Relatórios de turmas',
  RELATORIO_IRMAOS: 'Relatório de irmãos',
  RELATORIO_TOTALIZADORES: 'Relatório totalizadores',
  RELATORIO_SITUACAO_CONTRATO: 'Relatórios situação do contrato',
  RELATORIO_LANCAMENTOS_POR_PERIODO: 'Relatório lançamentos por período',
  IMPRIMIR_RELATORIO_ANALISE_RETROATIVA_CONTAS_RECEBER:
    'Rel. CR. análises retroativas contas a receber',
  IMPRIMIR_RELATORIO_ANALISE_RETROATIVA_CONTAS_PAGAR:
    'Rel. CP. análises retroativas contas a pagar',
  // Letra S:
  SUPRIMENTOS: 'Suprimentos',

  // Letra T:
  TURMAS: 'Turmas',
  TIPO_DE_AVALIACAO: 'Tipo de avaliações',
  // Letra U:
  USUARIOS: 'Usuários',

  // Letra V:
  // Letra X:
  // Letra Y:
  // Letra Z:
};
