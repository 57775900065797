export const Precos = {
  ADICIONAR_PRECO: "Adicionar preço",
  CALCULO_JUROS_MANUAL: "Ignorar cálculo dos juros, informarei manualmente.",
  DESCONTO: "Desconto",
  DESCONTO_CONTRATO: "Desconto contrato:",
  DESCONTO_SUCESSO: "Desconto atualizado com sucesso",
  DESCRICAO: "Descrição",
  JUROS: "Juros (R$)",
  PRECO: "Preço",
  REMOVER_PRECO: "Remover preço",
  TAXAS: "Taxas",
  TIPO_PAGAMENTO: "Tipo pagamento",
  TOTAL: "Total",
  VALOR: "Valor",
  VALOR_EFETIVO: "Valor efetivo",
  VALOR_RECEBIDO: "Valor recebido",
  VALOR_DA_PARCELA: "Valor da parcela",
  VALOR_TOTAL: "Total",
  VALOR_DESCONTO: 'Desconto (R$)',
  ORIGEM: 'Origem',
};
